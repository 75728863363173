<template>
  <FilterPanel heading="GENERAL.FILTER" :enabled="enabled" @close="close">
    <div v-if="partnerType === 'BOTH' && !hasScope">
      <h4>{{ $t('DESIGNS.POINTS_OF_SALE') }}</h4>
      <RadioButtonGroup
        :items="pointsOfSale"
        :itemKey="(item) => item.value"
        :itemToString="(item) => $t(item.name)"
        v-model="currentPosFilter"
      />
    </div>
    <div>
      <h4>
        {{ $t('DESIGNS.LIST.STATUS_GLOSSARY') }}
      </h4>
      <RadioButtonGroup
        :items="lifeStates"
        :itemKey="(item) => item.value"
        :itemToString="(item) => $t(item.name)"
        v-model="currentLifeState"
      />
    </div>
    <button @click="apply" class="btn btn-primary btn-block apply-button">
      {{ $t('GENERAL.FILTER_RESULTS') }}
    </button>
    <button @click="reset" class="btn btn-light btn-block">
      {{ $t('GENERAL.RESET') }}
    </button>
  </FilterPanel>
</template>

<script>
import RadioButtonGroup from '@/RadioButtonGroup/RadioButtonGroup.vue';
import { mapGetters } from 'vuex';
import FilterPanel from './FilterPanel.vue';
import analytics from '@/tracking/analytics';

export default {
  name: 'IdeaListFilter',
  components: {
    RadioButtonGroup,
    FilterPanel,
  },
  props: ['enabled'],
  data() {
    return {
      pointsOfSale: [
        {
          name: 'GENERAL.ALL',
          value: null,
        },
        {
          name: 'GENERAL.MARKETPLACE',
          value: 'MARKETPLACE',
        },
        {
          name: 'GENERAL.SHOP',
          value: 'SHOP',
        },
      ],
      currentPosFilter: null,
      lifeStates: [
        {
          name: 'GENERAL.ALL',
          value: null,
        },
        {
          name: 'DESIGNS.STATUS.UNPUBLISHED',
          value: 'NEW',
        },
        {
          name: 'DESIGNS.STATUS.REVIEW',
          value: 'PENDING',
        },
        {
          name: 'DESIGNS.STATUS.PUBLISHED',
          value: 'INVOLVED',
        },
        {
          name: 'DESIGNS.STATUS.REJECTED',
          value: 'REJECTED',
        },
      ],
      currentLifeState: null,
      lastAppliedFilters: {
        pos: null,
        lifeState: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      partnerType: 'user/partnerType',
      totalIdeaCount: 'ideas/totalIdeaCount',
      hasScope: 'publishing/hasScope',
    }),
  },
  created() {
    this.currentPosFilter =
      this.pointsOfSale.find(
        (pos) => pos.value === this.$store.state.ideas.filter.posFilter
      ) || this.pointsOfSale[0];
    this.currentLifeState =
      this.lifeStates.find(
        (lifeState) =>
          lifeState.value === this.$store.state.ideas.filter.lifeState
      ) || this.lifeStates[0];
    this.lastAppliedFilters.posFilter = this.currentPosFilter;
    this.lastAppliedFilters.lifeState = this.currentLifeState;
  },
  methods: {
    reset() {
      this.currentPosFilter = this.pointsOfSale[0];
      this.currentLifeState = this.lifeStates[0];

      this.apply();
    },
    revert() {
      this.currentPosFilter = this.lastAppliedFilters.posFilter;
      this.currentLifeState = this.lastAppliedFilters.lifeState;
    },
    close() {
      this.revert();
      this.$emit('close');
    },
    apply() {
      this.lastAppliedFilters.posFilter = this.currentPosFilter;
      this.lastAppliedFilters.lifeState = this.currentLifeState;

      this.$emit('apply', {
        posFilter: this.currentPosFilter.value,
        lifeState: this.currentLifeState.value,
        page: 0,
      });

      analytics.logEvent('idea_list_filter', {
        posFilter: this.currentPosFilter.value,
        lifeState: this.currentLifeState.value,
        ideaCount: this.totalIdeaCount,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

:deep(.radiobutton-group) {
  flex-direction: column;

  label {
    padding-bottom: 10px;
  }
}

h4 {
  margin: 12px 0;
}

.apply-button {
  margin: 10px 0;
}

.backdrop {
  flex: 1;
}
</style>

import {
  NavigationFailureType,
  createRouter,
  createWebHistory,
  isNavigationFailure,
} from 'vue-router';
import routes from './routes';

import dialogService from '@/dialogs/wrapper/dialogService';

import store from 'src/app/commons/store/Store';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import analytics from '@/tracking/analytics';

export async function guardRoute(to, from) {
  // reset scope, scope is set in route config for specific routes
  if (!to.meta?.journey) {
    store.commit('publishing/setCurrentScope', null);
  }

  if (!to.meta) {
    to.meta = {};
  }

  to.meta.previousState = {
    name: from ? from.name : '',
    params: from.params,
  };

  if (from && !from.meta?.hasUnsavedComponents) {
    dialogService.dismissAllDialogs();
  }

  if (to.meta?.redirectOnDirectLink && !from.name) {
    return { name: to.meta.redirectOnDirectLink };
  } else if (!to.meta.accessibleInApp && store.state.application.isMobileApp) {
    return {
      name: 'partnerarea.loginmobile',
    };
  } else if (to.meta?.loginRequired && !store.state.user.data.id) {
    try {
      await store.dispatch('user/initializeUser');

      if (!store.state.settings.loaded) {
        await store.state.settings.onDataLoaded;
      }
      return true;
    } catch (e) {
      return {
        name: store.state.application.isMobileApp
          ? 'partnerarea.loginmobile'
          : 'partnerarea.login',
        params: { redirectAfterLogin: to },
      };
    }
  } else if (
    to.meta?.redirectIfLoggedIn &&
    !from.name &&
    !store.state.user.data.id
  ) {
    try {
      await store.dispatch('user/initializeUser');
      return {
        name: to.meta.redirectIfLoggedIn,
        params: to.params,
        query: to.query,
      };
    } catch (e) {
      return true;
    }
  } else {
    if (!store.state.settings.loaded && to.meta?.loginRequired) {
      await store.state.settings.onDataLoaded;
    }

    return unsavedChangesService.onRouteUpdate(to, from);
  }
}

// if (!process || process.env.NODE_ENV !== 'test') {
//   Vue.use(Router);
// }

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(guardRoute);

router.afterEach((to, from) => {
  if (to?.name && to?.name !== from?.name) {
    analytics.logScreenView(to?.name);

    if (window.analytics && to?.name) {
      window.analytics.page = {
        name: to.name,
      };
    }
  }
});

const originalReplace = router.replace;
router.replace = function replace(location) {
  // if (onResolve || onReject) {
  //   return originalReplace.call(this, location, onResolve, onReject);
  // }

  return originalReplace.call(this, location).catch((err) => {
    if (isNavigationFailure(err, NavigationFailureType.duplicated)) {
      return err;
    }
    return Promise.reject(err);
  });
};

export default router;

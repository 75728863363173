<template>
  <div class="dialog-size-lg nps-dialog">
    <div class="modal-body">
      <div v-if="!scoreSubmitted">
        <h2>{{ $t('NPS.HEADING') }}</h2>
        <ul class="score">
          <li v-for="index in 11" :key="index">
            <button @click="submitScore(index - 1)" class="btn btn-ghost">
              {{ index - 1 }}
            </button>
          </li>
        </ul>
        <div class="explanation">
          <p>{{ $t('NPS.SCORE_0') }}</p>
          <p>{{ $t('NPS.SCORE_10') }}</p>
        </div>
      </div>
      <div v-else>
        <h2>{{ $t('NPS.THANK_YOU.HEADING') }}</h2>
        <p>
          {{ $t('NPS.THANK_YOU.SUBHEADING') }}
        </p>
        <button class="btn btn-primary close-btn" @click="modalInstance.close">
          {{ $t('GENERAL.CLOSE') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NpsDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
      scoreSubmitted: false,
    };
  },
  methods: {
    submitScore(score) {
      this.data.submitScore(score);
      this.scoreSubmitted = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';

.nps-dialog {
  .modal-body {
    text-align: center;
  }

  h2 {
    margin: 0 0 12px 0;
  }

  p {
    margin: 0;
  }
  .score {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 12px 0 16px 0;

    li {
      flex-grow: 1;
      max-width: 60px;
    }

    button {
      font-weight: normal;
      border-width: 1px;
      border-radius: $border-radius-medium;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media (min-width: 960px) {
        padding: 12px 24px;
      }
    }
  }

  .explanation {
    display: flex;
    justify-content: space-between;
  }

  .close-btn {
    margin: 16px 0 0 0;
    padding: 12px 24px;
    border-radius: $border-radius-medium;
  }
}
</style>

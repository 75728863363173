<template>
  <div class="timeline-chart">
    <div class="chart" :class="{ 'two-years': timeFilter.isTwoYears }">
      <Chart
        :type="type"
        :data="soldItemsTimeChart"
        :options="chartOptions"
      ></Chart>
    </div>
    <div class="footer">
      <div class="chart-unit">{{ $t(timeFilter.metaGroupUnit) }}</div>
      <div
        class="legend"
        v-if="timeFilter.isTwoYears && totals.currentTimeframe > 0"
      >
        <div class="legend-item">
          <div class="legend-color"></div>
          <div class="legend-name">{{ timeFilter.year1 }}</div>
        </div>
        <div class="legend-item">
          <div class="legend-color main"></div>
          <div class="legend-name">{{ timeFilter.year2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parse from 'date-fns/parseISO';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import Chartist from 'chartist';
import { chunk } from '@/utils';
import { mapGetters } from 'vuex';
import Chart from '@/Chart/Chart.vue';
import currencyFormatService from '@/currency/service/currencyFormatService';
import sprdNumber from '@/number/numberFilter';

function createChartTooltip(meta, value, currencyId) {
  const metaValues = Chartist.deserialize(meta);

  const wrapper = document.createElement('div');
  wrapper.classList.add('tooltip-wrapper');

  const tooltipWrapper = document.createElement('div');
  tooltipWrapper.classList.add('chart-tooltip-wrapper');
  wrapper.appendChild(tooltipWrapper);

  if (metaValues.previousYear && metaValues.previousYear.value > 0) {
    const element = createTooltipElement(
      metaValues.previousYear.value,
      metaValues.previousYear.label,
      currencyId
    );
    element.classList.add('previous-year');
    tooltipWrapper.appendChild(element);

    const difference = value - metaValues.previousYear.value;

    const diffWrapper = document.createElement('h4');
    diffWrapper.classList.add('growth');
    diffWrapper.classList.add(difference > 0 ? 'positive' : 'negative');
    diffWrapper.innerText = `${
      Math.round((difference / metaValues.previousYear.value) * 100 * 100) / 100
    }%`;
    wrapper.appendChild(diffWrapper);
  }

  const tooltipElement = createTooltipElement(
    value,
    metaValues.metaLabel,
    currencyId
  );
  if (metaValues.isPreviousYear) {
    tooltipElement.classList.add('previous-year');
  }
  tooltipWrapper.appendChild(tooltipElement);

  if (metaValues.currentYear && value > 0) {
    tooltipWrapper.appendChild(
      createTooltipElement(
        metaValues.currentYear.value,
        metaValues.currentYear.label,
        currencyId
      )
    );

    if (metaValues.currentYear.value > 0) {
      const difference = metaValues.currentYear.value - value;

      const diffWrapper = document.createElement('h4');
      diffWrapper.classList.add('growth');
      diffWrapper.classList.add(difference > 0 ? 'positive' : 'negative');
      diffWrapper.innerText = `${
        Math.round((difference / value) * 100 * 100) / 100
      }%`;
      wrapper.appendChild(diffWrapper);
    }
  }

  return wrapper.outerHTML;
}

function createTooltipElement(value, label, currencyId) {
  const wrapper = document.createElement('div');
  wrapper.classList.add('chart-tooltip-item');

  const labelElement = document.createElement('div');
  labelElement.classList.add('tooltip-label');
  labelElement.innerText = label;
  wrapper.appendChild(labelElement);

  const valueElement = document.createElement('h4');
  valueElement.classList.add('tooltip-value');
  if (currencyId) {
    valueElement.innerText = currencyFormatService.formatValue(
      value,
      currencyId
    );
  } else {
    valueElement.innerText = sprdNumber(value);
  }
  wrapper.appendChild(valueElement);

  return wrapper;
}

export default {
  name: 'TimelineChart',
  components: {
    Chart,
  },
  props: {
    timelineData: {
      type: Array,
      required: true,
    },
    timeFilter: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: 500,
    },
    currencyId: {
      type: Number,
    },
    type: {
      type: String,
      default: 'Bar',
      validator(value) {
        return ['Bar', 'Line'].includes(value);
      },
    },
  },
  data() {
    return {
      totals: {
        currentTimeframe: 0,
        previousTimeframe: 0,
      },
      soldItemsTimeChart: {},
      legend: {
        previousYear: getYear(Date.now()) - 1,
        currentYear: getYear(Date.now()),
      },
      chartOptions: {
        height: `${this.height}px`,
        responsive: true,
        fullWidth: true,
        low: 0,
        axisY: {
          onlyInteger: true,
        },
        axisX: {
          labelInterpolationFnc: (value, index) => {
            if (this.timeFilter.skipEverySecondLabel) {
              return index % 2 === 1 ? value : '';
            } else {
              return value;
            }
          },
        },
        seriesBarDistance: 14,
        plugins: [
          Chartist.plugins.tooltip({
            anchorToPoint: true,
            class: 'chart-tooltip',
            tooltipFnc: (meta, value) =>
              createChartTooltip(meta, value, this.currencyId),
          }),
        ],
      },
    };
  },
  async created() {
    this.update();
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
      hasMarketplace: 'user/hasMarketplace',
    }),
  },
  watch: {
    timelineData: function (newValue) {
      if (newValue) {
        this.update();
      }
    },
  },
  methods: {
    async update() {
      let data = this.timelineData.reduce((result, current) => {
        const group = this.timeFilter.group(current);
        if (!result[group]) {
          result[group] = {
            metaLabel: this.timeFilter.metaLabel(current),
            label: this.timeFilter.label(current),
            value: 0,
            year: getYear(parse(current.date)),
            month: getMonth(parse(current.date)),
          };
        }
        result[group].value += current.value;
        return result;
      }, {});
      data = Object.keys(data).map((key) => data[key]);

      this.calculateSoldItemsCharts(data);
    },
    calculateSoldItemsCharts(data) {
      if (this.timeFilter.isTwoYears) {
        const chunkedData = this.getChunkedTwoYearData(data);

        this.calculateTotals(chunkedData, true);

        this.soldItemsTimeChart = {
          series: chunkedData.map((c) =>
            c.map((s) => {
              return {
                value: s.value,
                meta: Chartist.serialize(s),
              };
            })
          ),
          labels: chunkedData[0].map((entry) => entry.label),
        };
      } else {
        this.calculateTotals(data);
        this.soldItemsTimeChart = {
          series: [
            data.map((entry) => {
              return {
                value: entry.value,
                meta: Chartist.serialize(entry),
              };
            }),
          ],
          labels: data.map((entry) => entry.label),
        };
      }
    },
    calculateTotals(data, chunked) {
      if (chunked) {
        this.totals.currentTimeframe = data[1].reduce(
          (prev, cur) => prev + cur.value,
          0
        );
        this.totals.previousTimeframe = data[0].reduce(
          (prev, cur) => prev + cur.value,
          0
        );
      } else {
        this.totals.currentTimeframe = data.reduce(
          (prev, cur) => prev + cur.value,
          0
        );
        this.totals.previousTimeframe = 0;
      }

      this.$emit('totalCounts', {
        currentTimeframe: this.totals.currentTimeframe,
        previousTimeframe: this.totals.previousTimeframe,
      });
    },
    getChunkedTwoYearData(data) {
      const chunkedData = chunk(data, 12);
      const [prevYearData, currentYearData] = chunkedData;

      prevYearData.forEach((item) => {
        item.isPreviousYear = true;
        const currentYearItem = currentYearData.find(
          (cur) => cur.month === item.month && cur.year === item.year + 1
        );
        if (currentYearItem) {
          item.currentYear = {
            label: currentYearItem.metaLabel,
            value: currentYearItem.value,
          };
        }
      });

      currentYearData.forEach((item) => {
        const prevYearItem = prevYearData.find(
          (cur) => cur.month === item.month && cur.year === item.year - 1
        );
        if (prevYearItem) {
          item.previousYear = {
            label: prevYearItem.metaLabel,
            value: prevYearItem.value,
          };
        }
      });

      return chunkedData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';

.chart {
  position: relative;

  .chart-container {
    margin: 0 0 -24px -24px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  margin: 4px 0 0 8px;

  & > div {
    align-self: flex-start;
  }
}

.chart-unit {
  font-size: 12px;
  color: #00000066;

  @include small-desktop {
    font-size: 10.5px;
  }
}

.footer,
.legend,
.legend-item {
  display: flex;
  align-items: center;
}

.legend {
  width: 45%;
  margin-top: 12px;
}

.legend-color {
  width: 16px;
  height: 16px;
  background-color: $grey20;
  margin-right: 8px;

  &.main {
    background-color: $pa-color-main;
  }
}

.legend-name {
  margin-right: 32px;
}

.no-data-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
}

.chart-tooltip {
  background-color: $grey0;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 4px;

  &:before {
    display: none;
  }

  .tooltip-wrapper {
    display: flex;
    align-items: center;
  }

  .growth {
    margin: 0 0 0 16px;

    &.positive {
      color: $pa-color-green;
    }

    &.negative {
      color: $pa-color-red;
    }
  }

  .tooltip-item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      padding-top: 5px;
    }

    .tooltip-label {
      padding-right: 10px;
      font-size: 14px;
      font-weight: normal;
    }

    .tooltip-value {
      margin: 0;
    }

    &.previous-year {
      .tooltip-label {
        color: $grey40;
      }
    }
  }
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';

.chart-tooltip {
  background-color: $grey0;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 4px;

  &:before {
    display: none;
  }

  .tooltip-wrapper {
    display: flex;
    align-items: center;
  }

  .growth {
    margin: 0 0 0 16px;

    &.positive {
      color: $pa-color-green;
    }

    &.negative {
      color: $pa-color-red;
    }
  }

  .chart-tooltip-item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      padding-top: 5px;
    }

    .tooltip-label {
      padding-right: 10px;
      font-size: 14px;
      font-weight: normal;
    }

    .tooltip-value {
      margin: 0;
    }

    &.previous-year {
      .tooltip-label {
        color: $grey40;
      }
    }
  }
}

.timeline-chart {
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: $pa-color-main;
  }

  .ct-series-a .ct-area {
    fill: $pa-color-main;
  }

  .ct-series-a .ct-line {
    stroke-width: 2px;
  }

  .ct-series-a .ct-point {
    stroke-width: 6px;
  }

  .ct-grids .ct-grid {
    stroke-dasharray: 0;
    stroke: rgba(0, 0, 0, 0.1);
  }

  .ct-grid.ct-horizontal {
    display: none;
  }

  .ct-bar {
    stroke: $pa-color-main;
    stroke-width: 20px;

    &:hover {
      stroke: $pa-color-main-dark;
    }
  }

  .chart {
    &.two-years {
      .ct-series-a .ct-bar {
        stroke: $grey20;

        &:hover {
          stroke: $grey30;
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="designlist page-wrapper"
    :class="{ dragover: dragging }"
    @dragover.prevent="dragging = true"
    @drop.prevent="onFileDrop"
  >
    <input
      type="file"
      multiple
      id="listFileInput"
      ref="listFileInput"
      @change="newIdea($event.target.files)"
      class="hidden-file-input"
    />

    <div class="page-content" :class="{ 'full-width-page': userHasNoIdeas }">
      <IdeaListWelcome
        v-if="initialIdeasLoaded && userHasNoIdeas"
        @upload="newIdea"
        @refresh="clearFilter"
      />

      <label
        for="listFileInput"
        class="upload-drag-page"
        @dragleave.prevent="dragging = false"
        @dragend.prevent="dragging = false"
      >
        <Icon icon="upload" />
        <h2>{{ $t('DESIGNS.UPLOAD.HEADER.FIND_DESIGN') }}</h2>
      </label>

      <div
        class="overview design-overview overflow-y"
        :class="{ 'bulk-active': displayBulkToolbar }"
        v-if="initialIdeasLoaded && !userHasNoIdeas"
        style="width: 100%"
        ref="list"
      >
        <div>
          <IdeaListHeader @change="getIdeas" />

          <IdeaListEmptyResult
            v-if="currentSearchTerm && ideas && !ideas.length"
            @clearSearchTerm="clearFilter"
          />

          <IdeaCardView
            v-if="
              viewMode === 'TILES' &&
              (!currentSearchTerm ||
                (currentSearchTerm && ideas && ideas.length))
            "
            @upload="newIdea"
            @edit="editIdea"
          />

          <IdeaListView
            v-if="
              viewMode === 'LIST' &&
              (!currentSearchTerm ||
                (currentSearchTerm && ideas && ideas.length))
            "
            @upload="newIdea"
            @edit="editIdea"
          />

          <div
            class="text-center clearfix pagination-wrapper"
            v-if="!(currentSearchTerm && ideas && !ideas.length)"
          >
            <Pagination
              id="design-pagination"
              :total="totalIdeaCount"
              :pageSize="pageSize"
              :currentPage="$store.state.ideas.filter.page"
              @pageChange="onPageChange"
            />

            <div class="page-size">
              <p>{{ $t('DESIGNS.LIST.TOOLBAR.ITEMS_PER_PAGE') }}</p>
              <Dropdown
                :items="pageSizes"
                @change="onPageSizeChange"
                :value="pageSize"
                :direction="'up'"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="loader" v-if="!initialIdeasLoaded">
        <img src="/images/loader.svg" class="sprd-loader" />
      </div>
    </div>

    <transition name="slideintop">
      <IdeaBulkToolbar v-if="displayBulkToolbar" @delete="deleteIdeas" />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import uploadsService from '@/api/uploadsService/uploadsService';
import IdeaListWelcome from './IdeaListWelcome.vue';
import IdeaListHeader from './IdeaListHeader.vue';
import IdeaListEmptyResult from './IdeaListEmptyResult.vue';
import IdeaCardView from './cardView/IdeaCardView.vue';
import IdeaListView from './listView/IdeaListView.vue';
import Pagination from '@/pagination/Pagination.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import IdeaBulkToolbar from '../bulk/toolbar/IdeaBulkToolbar.vue';
import eventBus from '@/eventBus/eventBus';
import ideaHelper from '@/ideaHelper/ideaHelper';
import IdeaUploadDialog from 'src/app/partnerarea/idea/upload/IdeaUploadDialog.vue';
import ErrorDialog from '@/dialogs/error/ErrorDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import IdeaDeletionDialog from 'src/app/partnerarea/idea/delete/IdeaDeleteDialog.vue';
import { removeNullValues } from 'src/app/commons/utils';

export default {
  name: 'IdeaList',
  components: {
    IdeaListWelcome,
    IdeaListHeader,
    IdeaListEmptyResult,
    IdeaCardView,
    IdeaListView,
    Pagination,
    Dropdown,
    IdeaBulkToolbar,
  },
  data() {
    return {
      initialIdeasLoaded: false,
      userHasNoIdeas: false,
      pageSizes: [11, 23, 47],
      dragging: false,
      pageSize: 23,
      initialized: false,
    };
  },
  computed: {
    ...mapGetters({
      getSetting: 'settings/getSetting',
      selectedIdeas: 'ideas/selected',
      processingIdeas: 'ideas/processing',
      totalIdeaCount: 'ideas/totalIdeaCount',
    }),
    ...mapState({
      ideas: (s) => s.ideas.list,
      currentSearchTerm: (s) => s.ideas.filter.q,
    }),
    options() {
      return {
        q: this.$store.state.ideas.filter.q,
        lifeState: this.$store.state.ideas.filter.lifeState,
        posFilter: this.$store.state.ideas.filter.posFilter,
        limit: this.pageSize,
        offset: this.$store.state.ideas.filter.page * this.pageSize,
      };
    },
    viewMode() {
      return this.getSetting('DESIGNS_VIEW_MODE');
    },
    displayBulkToolbar() {
      return this.initialized && this.selectedIdeas.length;
    },
  },
  async created() {
    await this.$store.state.settings.onDataLoaded;

    this.pageSize = this.getSetting('DESIGNS_PAGE_SIZE') || 23;
    const restoreState = this.$route.query.restoreState && this.ideas.length;

    if (restoreState) {
      this.updateUrlParams();
      this.processFetchedIdeas();
    } else {
      this.setFilter({
        q: this.$route.query.q,
        lifeState: this.$route.query.lifeState,
        posFilter: this.$route.query.posFilter,
      });
      await this.getIdeas();
    }

    if (this.$route.query.upload) {
      this.newIdea();
    }

    eventBus.$on('ideas:changes', this.onIdeasChanged);

    this.initialized = true;
  },
  beforeUnmount() {
    eventBus.$off('ideas:changes', this.onIdeasChanged);
  },
  methods: {
    ...mapMutations({
      resetFilter: 'ideas/resetFilter',
      setFilter: 'ideas/setFilter',
      replaceIdea: 'ideas/replaceIdea',
    }),
    ...mapActions({
      setSetting: 'settings/setSetting',
      fetchIdeas: 'ideas/fetchIdeas',
    }),
    async getIdeas() {
      this.updateUrlParams();
      this.loading = true;

      try {
        await this.fetchIdeas(this.options);

        if (
          !this.totalIdeaCount &&
          !this.options.q &&
          !this.options.lifeState &&
          !this.options.posFilter
        ) {
          this.userHasNoIdeas = true;
        } else {
          this.userHasNoIdeas = false;
        }

        this.processFetchedIdeas();

        if (this.$refs.list) {
          this.$refs.list.scrollTop = 0;
        }
      } catch (error) {
        this.resetFilter();
        this.updateUrlParams();
        dialogService.openDialog(ErrorDialog, null, {
          dialogBaseClass: 'dialog-inline',
          displayClose: false,
          staticBackdrop: true,
          preventEsc: true,
        });
        return Promise.reject();
      }
    },
    processFetchedIdeas() {
      this.initialIdeasLoaded = true;
      if (this.processingIdeas.length > 0) {
        uploadsService.startPolling(this.options);
      }
    },
    onPageChange(page) {
      this.setFilter({ page });
      this.getIdeas();
    },
    onPageSizeChange(newSize) {
      this.setFilter({ page: 0 });
      this.pageSize = newSize;
      this.setSetting({
        key: 'DESIGNS_PAGE_SIZE',
        value: this.pageSize,
      });
      this.getIdeas();
    },
    deleteIdeas(ideas) {
      dialogService.openDialog(IdeaDeletionDialog, ideas).then(() => {
        if (!this.ideas.length) {
          this.setFilter({ page: 0 });
        }
        this.getIdeas();
      });
    },
    editIdea(idea) {
      if (ideaHelper.getState(idea).isAttention()) {
        this.$router.push({
          name: 'partnerarea.ideaAdjustment',
          params: { ideaId: idea.id },
        });
      } else {
        this.$router.push({
          name: 'partnerarea.idea',
          params: { ideaId: idea.id },
        });
      }
    },
    newIdea(files, uploadFlags = []) {
      if (files && !files.length) {
        return;
      }

      dialogService
        .openDialog(IdeaUploadDialog, { files, uploadFlags })
        .then(() => this.clearFilter());
    },
    clearFilter() {
      this.resetFilter();
      this.getIdeas();
    },
    onIdeasChanged(ideas) {
      this.ideas
        .filter((idea) => ideaHelper.getState(idea).isProcessing())
        .forEach((idea) => {
          const updatedIdea = ideas.find((i) => i.id === idea.id);
          if (updatedIdea) {
            this.replaceIdea(updatedIdea);
          }
        });

      if (!this.processingIdeas.length) {
        uploadsService.stopPolling();
      }
    },
    onFileDrop(e) {
      const files = e.dataTransfer ? e.dataTransfer.files : e.srcElement.files;
      this.newIdea([...files]);
      this.dragging = false;
    },
    updateUrlParams() {
      this.$router
        .replace({
          query: removeNullValues({
            q: this.options.q,
            lifeState: this.options.lifeState,
            posFilter: this.options.posFilter,
          }),
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.designlist {
  position: relative;
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 120px;
      height: 120px;
    }
  }

  .card-container-dummy {
    padding: 0;
  }

  .design-overview {
    & > div {
      width: 100%;
      transition: margin-top 0.15s linear;
    }

    &.bulk-active > div {
      margin-top: 66px;
    }
  }

  .full-width-page {
    .design-overview,
    .design-overview > div {
      padding: 0;
    }

    h1 {
      margin: 0;
    }

    .upload-button {
      margin: 15px 0 30px 0;
    }
  }

  .design-detail-view {
    z-index: 2;
  }

  .welcome-page,
  .upload-drag-page {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px;
    height: calc(100vh - 48px);
    position: relative;
    width: 100%;
    border-radius: $border-radius-medium;
    border: 2px solid $pa-color-main;

    h1 {
      text-align: center;
      padding: 0 25px;
      margin: 15px 0;
    }

    .upload-button {
      padding: 15px 50px;
      position: relative;
    }
  }

  .upload-drag-page {
    display: none;

    .icon {
      width: 32px;
      height: 32px;
      color: $pa-color-main;
    }
  }

  &.dragover {
    .welcome-page,
    .design-card-view,
    .design-overview,
    .page-header,
    sprd-uploads-widget {
      display: none;
    }
    .upload-drag-page {
      display: flex;
    }
  }

  .pagination-wrapper {
    border-top: 1px solid $sprd-color-light-grey;
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    margin: 0 25px 10px 25px;

    @media (max-width: 1399px) {
      margin: 0 20px 10px 20px;
    }

    .page-size {
      display: flex;
      align-items: center;
      position: absolute;

      p {
        margin: 0 10px 0 0;
      }
    }
  }
}
</style>

<template>
  <div
    class="volume-commission"
    v-if="shops && shops.length && volumeCommission"
  >
    <div class="volume-commission-box" id="volumeCommission">
      <div class="credit-label">
        <h3>
          {{ $t('STATISTICS.CREDIT_DETAILS.ENTRY_TYPE.VOLUME_COMMISSION') }}
        </h3>
        <div class="subheader">
          <p class="hint text-grey text-sm">
            {{
              $t(
                volumeCommission.currentBonusLevel
                  ? 'DASHBOARD.VOLUME_SHARE_HINT'
                  : 'DASHBOARD.VOLUME_SHARE_HINT_0',
                {
                  items: this.sprdNumber(volumeCommission.orderItemCount),
                  itemsToGo: volumeCommission.itemsToGo,
                }
              )
            }}
          </p>
          <a class="link link-main" @click="openPerformanceSummary">
            <p class="text-sm">
              {{ $t('STATISTICS.VOLUME_COMMISSION.SUMMARY') }}
            </p>
          </a>
        </div>
      </div>
      <div class="credit-amount">
        <h1>
          {{ `${this.$t('DASHBOARD.VOLUME_SHARE_TIER')}: ${bonusLevel}` }}
        </h1>
        <SmartFaqLink
          :link="volumeCommissionHelpLink"
          iconOnly
          class="help-link"
        />
      </div>
      <SpreadshopLogo class="logo" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import addMonths from 'date-fns/addMonths';
import commissionerService from 'src/app/commons/api/commissionerService/commissionerService.js';
import date from '@/date/date';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import dialogService from '@/dialogs/wrapper/dialogService';
import CreditDetailsVolumeSummary from 'src/app/partnerarea/statistics/credit/details/CreditDetailsVolumeSummary.vue';

export default {
  name: 'VolumeCommissionModule',
  components: {
    SpreadshopLogo,
    SmartFaqLink,
  },
  data() {
    return {
      credit: null,
      iso: null,
      volumeCommission: null,
      showVolumeCommissionOverlay: false,
      nextPayoutMonth: null,
      volumeCommissionHelpLink: smartFaqLinks.VOLUME_COMMISSION_HELP,
    };
  },
  methods: {
    async openPerformanceSummary() {
      dialogService.openDialog(CreditDetailsVolumeSummary);
    },
  },
  async created() {
    if (new Date().getDate() > 15) {
      this.nextPayoutMonth = date(addMonths(Date.now(), 1), 'MMMM');
    } else {
      this.nextPayoutMonth = date(new Date(), 'MMMM');
    }

    this.volumeCommission = await commissionerService.fetchVolumeCommission();
  },
  computed: {
    ...mapGetters({
      shops: 'user/shops',
    }),
    bonusLevel() {
      return this.volumeCommission.currentBonusLevel > 0
        ? this.volumeCommission.currentBonusLevel
        : this.$t('STATISTICS.VOLUME_COMMISSION.BASE_TIER');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';

.volume-commission {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  padding: 0 !important;
}

.volume-commission-box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  background-color: $white;
  border-radius: $border-radius-medium;
  position: relative;

  .credit-label {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 0;
    flex-grow: 1;
    position: relative;

    & > h3 {
      margin: 0;
    }

    & > a {
      font-size: 12px;
      margin-top: 4px;
    }
  }

  .credit-amount {
    margin: 0 0 24px 0;
    display: flex;
    align-items: flex-end;

    & > h1 {
      margin: 0;
      white-space: nowrap;
      padding-right: 8px;
    }

    .help-link {
      margin-left: auto;
    }
  }

  .subheader {
    display: flex;
    width: 100%;

    .link,
    .hint {
      margin: 8px 0 0;
    }

    .hint {
      display: inline-block;
      word-wrap: normal;
      margin-right: 16px;
      min-width: 200px;
    }
  }

  .logo {
    position: absolute;
    top: 24px;
    right: 24px;
    height: 12px;
  }
}
</style>

<template>
  <div class="login">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
    />
    <CentralAuth mode="login" additionalContext="minimal" />
  </div>
</template>

<script>
import CentralAuth from 'src/app/components/centralAuth/CentralAuth.vue';

export default {
  name: 'LoginMobile',
  components: {
    CentralAuth,
  },
};
</script>

<style lang="scss">
#application.state-partnerarea-loginmobile {
  min-width: 0;
  background-color: #fff;
  overflow-y: initial;

  #main-content {
    overflow: initial;
  }

  .help-panel-wrapper {
    display: none;
  }
}
</style>

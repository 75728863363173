<template>
  <div class="upload-area" :class="{ dragover: dragging, condensed, error }">
    <template v-if="!loading">
      <div class="wrapper">
        <input
          :id="fileInputId"
          type="file"
          ref="fileInput"
          class="hidden-file-input"
          multiple
          @change="uploadFiles"
        />
      </div>

      <div v-if="condensed" class="condensed-content">
        <label
          v-if="condensed"
          :for="fileInputId"
          class="upload-btn btn btn-ghost"
        >
          Upload
        </label>
        <p v-if="fileTypes">File type: {{ fileTypes }}</p>
      </div>
      <label
        v-else
        :for="fileInputId"
        class="drop-area"
        @dragover.prevent="dragging = true"
        @dragleave.prevent="dragging = false"
        @dragend.prevent="dragging = false"
        @drop.prevent="onFileDrop"
      >
        <Icon icon="upload"></Icon>
        <h3>Upload File</h3>
        <p v-if="fileTypes">File type: {{ fileTypes }}</p>
      </label>
    </template>
    <div v-else>
      <LoadingHeart />
    </div>
  </div>
</template>

<script>
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import { genId } from 'src/app/commons/utils';

export default {
  name: 'DigitalProductDetail',
  components: {
    LoadingHeart,
  },
  props: {
    uploadFn: {
      type: Function,
      required: true,
    },
    condensed: {
      type: Boolean,
    },
    fileTypes: {
      type: String,
    },
    error: {
      type: Object,
    },
  },
  data() {
    return {
      fileInputId: genId('fileInput'),
      dragging: false,
      loading: false,
    };
  },
  methods: {
    uploadFiles(event) {
      const files = event.target.files;
      this.upload(files);
    },
    onFileDrop(evt) {
      if (!(evt instanceof Event) || evt.isTrusted === false) {
        return;
      }

      const files = evt.dataTransfer
        ? evt.dataTransfer.files
        : evt.srcElement.files;
      this.upload([...files]);
      this.dragging = false;
    },
    async upload(files) {
      this.loading = true;
      try {
        await this.uploadFn(files);
      } catch (error) {
        console.error('upload failed');
        // todo: error handling
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.wrapper {
  position: relative;
}

.upload-area:not(.condensed) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grey5;
  border: 1px solid $grey10;
  border-radius: $border-radius-medium;

  &:hover,
  &.dragover {
    border-color: $pa-color-main;
  }
}

.drop-area {
  flex: 1;
  padding: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
  }

  h3 {
    margin: 8px 0;
  }

  p {
    color: $grey65;
  }
}

p {
  margin: 0;
}

.loader {
  padding: 24px;
}

.condensed-content {
  display: flex;
  align-items: center;
}

.upload-btn {
  margin-right: 16px;
}

.upload-area.error {
  border-color: $pa-color-red;
}
</style>

<template>
  <div class="central-auth">
    <div id="central-auth-iframe-wrapper"></div>
    <div class="logos">
      <img src="/images/powered-by-spreadgroup.svg" />
      <img v-if="mode === 'shop'" src="/images/mbp-badge.png" />
    </div>
    <div class="disclaimer">
      <div class="legal-links">
        <a :href="privacyPolicyLink" class="link-underlined" target="_blank">{{
          $t('GENERAL.PRIVACY_POLICY')
        }}</a>
        <a :href="imprintLink" class="link-underlined" target="_blank">{{
          $t('GENERAL.IMPRINT')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import localeService from '@/localeService/localeService';
import { getExternalLinks } from '@/externalLinks/externalLinks';
import OnboardingDialog from 'src/app/components/onboarding/OnboardingDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import { shopAPI } from '@/api/shop/shopAPI';
import analytics from '@/tracking/analytics';
import aiDesignGeneratorService from '@/designGenerator/aiDesignGeneratorService';

export default {
  name: 'CentralAuth',
  props: {
    mode: {
      required: true,
      type: String,
    },
    template: {
      required: false,
      type: String,
    },
    additionalContext: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      privacyPolicyLink: getExternalLinks().PRIVACY_POLICY,
      imprintLink: getExternalLinks().IMPRINT,
      shopRegistration: !!this.$route.params.shopId,
      renderMode: {
        shop: this.renderShopRegistration,
        mp: this.renderMpRegistration,
        login: this.renderLogin,
        forgotPassword: this.renderForgotPassword,
      },
      handleEvent: {
        RENDERING_COMPLETE: this.onRenderComplete,
        LINK_LOGIN_CLICKED: this.onLoginLinkClicked,
        REGISTER_SHOP_OWNER_SUCCESS: this.onRegisterShopOwnerSuccess,
        REGISTER_DESIGNER_SUCCESS: this.onRegisterDesignerSuccess,
        LOGIN_SUCCESS: this.onLoginSuccess,
        LINK_REGISTER_CLICKED: this.onRegisterLinkClicked,
        LINK_FORGOT_PASSWORD_CLICKED: this.onForgotPasswordLinkClicked,
      },
    };
  },
  mounted() {
    if (!window.sprdAccount) {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://accounts.' +
          location.hostname.split('.').slice(1).join('.') +
          '/js/client.js'
      );
      script.addEventListener('load', () => {
        this.renderMode[this.mode]();
      });
      document.body.appendChild(script);
    } else {
      this.renderMode[this.mode]();
    }
  },
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    context() {
      return `${window.location.hostname.split('.')[0]}${
        this.additionalContext ? '-' + this.additionalContext : ''
      }`;
    },
    element() {
      return document.getElementById('central-auth-iframe-wrapper');
    },
    lang() {
      return localeService.getLanguage();
    },
    renderOptions() {
      return {
        element: this.element,
        context: this.context,
        callback: this.handleEvents,
        lang: this.lang,
      };
    },
  },
  methods: {
    ...mapActions({
      setSetting: 'settings/setSetting',
      fetchShops: 'user/fetchShops',
      fetchPointsOfSale: 'user/fetchPointsOfSale',
      initializeUser: 'user/initializeUser',
    }),
    async handleEvents(event, params) {
      if (this.handleEvent[event]) {
        this.handleEvent[event](params);
      }
    },
    renderShopRegistration() {
      window.sprdAccount.cleanUp();
      window.sprdAccount.renderRegisterShopOwner(this.renderOptions);
    },
    renderMpRegistration() {
      window.sprdAccount.cleanUp();
      window.sprdAccount.renderRegisterDesigner(this.renderOptions);
    },
    renderLogin() {
      window.sprdAccount.cleanUp();
      window.sprdAccount.renderLogin(this.renderOptions);
    },
    renderForgotPassword() {
      window.sprdAccount.cleanUp();
      window.sprdAccount.renderForgotPassword(this.renderOptions);
    },
    onRenderComplete() {
      this.$emit('initialized');
    },
    onLoginLinkClicked() {
      if (this.$route.name === 'partnerarea.login') {
        this.renderLogin();
      } else {
        if (this.$route.query?.isApp) {
          this.$router.push({
            name: 'partnerarea.appmobile',
            query: this.$route.query,
          });
        } else {
          this.$router.push({ name: 'partnerarea.login' });
        }
      }
    },
    onForgotPasswordLinkClicked() {
      this.renderForgotPassword();
    },
    onRegisterLinkClicked() {
      if (
        ['partnerarea.register', 'partnerarea.shopRegister'].includes(
          this.$route.meta.previousState.name
        )
      ) {
        this.$router.push({
          name: this.$route.meta.previousState.name,
          params: this.$route.meta.previousState.params,
        });
      } else if (document?.referrer?.includes('spreadshop.com')) {
        this.$router.push({
          name: 'partnerarea.shopRegister',
        });
      } else {
        // .net is not supported, so we use .ie instead (DEV-212863)
        window.location.href = getExternalLinks().START_SELLING.replace(
          '.net',
          '.ie'
        );
      }
    },
    async onRegisterShopOwnerSuccess(params) {
      await this.initializeUser();
      await this.$store.state.settings.onDataLoaded;

      if (params.shopName) {
        await shopAPI.createShop(
          this.$store.state.user.data.id,
          params.shopName,
          params.shopName,
          this.template
        );
      }

      this.fetchShops();
      this.fetchPointsOfSale();

      this.setSetting({
        key: 'MP_ENABLED',
        value: false,
      });

      analytics.events.register('SHOP');

      // enable AI feature for a certain percentage of new registered shop owners
      aiDesignGeneratorService.enableFeatureWithProbability(50);

      if (this.$route.query?.isApp) {
        this.$router.push({
          name: 'partnerarea.appmobile',
          query: { ...this.$route.query, instantConnect: true },
        });
      } else {
        this.$router.push({ name: 'partnerarea.dashboard' }).then(() => {
          dialogService.openDialog(OnboardingDialog, { welcome: true });
        });
      }
    },
    async onRegisterDesignerSuccess() {
      await this.initializeUser();

      analytics.events.register('MP');

      if (this.$route.query?.isApp) {
        this.$router.push({
          name: 'partnerarea.appmobile',
          query: { ...this.$route.query, instantConnect: true },
        });
      } else {
        this.$router.push({ name: 'partnerarea.ideas' }).then(() => {
          dialogService.openDialog(OnboardingDialog, { welcome: true });
        });
      }
    },
    onLoginSuccess() {
      analytics.events.login();

      if (this.$route.redirectedFrom) {
        this.$router.push(this.$route.redirectedFrom);
      } else {
        this.$router.push({ name: 'partnerarea.dashboard' }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';

.central-auth {
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  max-width: 560px;

  #central-auth-iframe-wrapper {
    border-radius: $border-radius-medium;
    overflow: hidden;
  }

  .disclaimer,
  .logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    img {
      height: 32px;
      margin: 16px;
    }

    .legal-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    a {
      margin: 16px;
    }
  }

  .logos {
    padding-top: 16px;
  }
}
</style>

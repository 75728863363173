<template>
  <div class="dialog-size-md addChannelDialog">
    <div class="hack" />
    <div class="modal-body">
      <div class="headingContainer">
        <h2 class="heading">
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.SELECT.TITLE') }}
        </h2>
      </div>
    </div>
    <div class="channelList">
      <div v-if="data.channels.length === 0">
        {{ $t('POS.SHOP_SETTINGS.SOCIAL_ICONS.SELECT.NONE_AVAILABLE') }}
      </div>
      <div
        v-else
        class="channelListItem"
        v-for="channel in this.data.channels"
        :key="channel.id"
      >
        <button
          :class="{ channelBtn: true, active: selectedChannels[channel.id] }"
          @click="toggleChannel(channel)"
        >
          <Icon class="channelIcon" :icon="channel.icon" />
          <span>{{ channel.name }}</span>
        </button>
      </div>
    </div>
    <div class="modal-footer btnGroup">
      <button class="btn btn-primary btn-lg" @click="confirm">
        {{ $t('SA.social.channelSelection.button') }}
      </button>
      <a class="link-main" @click="dismiss">
        {{ $t('GENERAL.CANCEL') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialMediaProfileAddChannelDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
      selectedChannels: {},
    };
  },
  methods: {
    toggleChannel(channel) {
      // trigger an update
      const result = { ...this.selectedChannels };
      result[channel.id] = !this.selectedChannels[channel.id];

      this.selectedChannels = result;
    },
    confirm() {
      this.modalInstance.close(
        Object.entries(this.selectedChannels)
          .filter(([, value]) => value)
          .map(([key]) => key)
      );
    },
    dismiss() {
      this.modalInstance.dismiss(true);
    },
    cancel() {
      this.modalInstance.dismiss();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
$itemsPerRow: 6;
$itemGap: 0.5em;

/*
  get a max-width modal
  we need this because our tiles are based on modal width
  having a min-width modal (e.g. when only 1 tile is displayed), makes them look bad
  feel free to change if you know something better
*/
.hack {
  width: 100vw;
}

.channelList {
  display: flex;
  gap: $itemGap;
  flex-flow: row wrap;
}

.channelListItem {
  width: calc((100% - #{$itemGap} * (#{$itemsPerRow}-1)) / #{$itemsPerRow});
  aspect-ratio: 1;
}

.channelBtn {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
  text-decoration: none;
  text-align: center;
  border: 2px solid transparent;
}

.active {
  border-radius: $border-radius-medium;
  border-color: $pa-color-main;
}

.channelIcon {
  font-size: 2em;
}

.channelSelection {
  display: flex;
  align-items: center;
  flex-direction: column;

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 33.333333%;
      padding: 5px;

      .icon {
        font-size: 2em;
      }
    }
  }

  &__channel {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
    text-decoration: none;
    text-align: center;
    border: 2px solid transparent;

    &.active {
      border-radius: $border-radius-medium;
      border-color: $pa-color-main;
    }
  }
}

.btnGroup {
  margin-top: 2.25em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1em;
}

.headingContainer {
  margin-bottom: 2em;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
}
</style>

<template>
  <Dropdown
    :items="posStates"
    :itemKey="(state) => state.key"
    :itemToString="(state) => state.name"
    :value="dropDownValue"
    @change="onPosStateChange"
  >
    <template #items></template>
    <template #bottom-content>
      <button
        class="text-btn link-main icon-link status-guide-btn"
        @click="openStatusGlossary"
      >
        <Icon icon="info-circle" />
        {{ $t('DESIGNS.LIST.STATUS_GLOSSARY_CTA') }}
      </button>
    </template>
  </Dropdown>
</template>

<script setup>
import Dropdown from '@/Dropdown/Dropdown.vue';
import { i18n } from '@/translate/i18n';
import dialogService from '@/dialogs/wrapper/dialogService';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { COMPOSITION_POS_STATES } from '@/api/models/publishingCore/compositionPosStates';
import PublishingStatusDialog from './PublishingStatusDialog.vue';

const { t } = i18n.global;

const store = useStore();
const emits = defineEmits(['posStateChanged']);

const posStates = computed(() => {
  const { total, stateCounts } = store.state.compositions.posStateCounts;
  const states = Object.values(COMPOSITION_POS_STATES).map((it) => {
    const stateCount = stateCounts[it.key];
    return { ...it, value: it.key, name: `${t(it.name)} (${stateCount})` };
  });

  states.push({
    key: 'ALL',
    value: null,
    name: `${t('GENERAL.ALL')} (${total})`,
  });

  return states;
});

const dropDownValue = computed(() => {
  const state = store.state.compositions.filter.posState;
  return posStates.value.find((it) =>
    state ? it.key === state : it.key === 'ALL'
  );
});

const onPosStateChange = (selectedState) => {
  store.commit('compositions/setFilter', {
    posState: selectedState.value,
    page: 0,
  });
  emits('posStateChanged');
};

const openStatusGlossary = () => {
  dialogService.openDialog(PublishingStatusDialog);
};
</script>

<template>
  <div class="design-card-view">
    <div class="card-container col-xs-1 upload-tile">
      <button
        class="upload-card-button"
        @click="$emit('upload')"
        id="upload-btn"
      >
        <div class="upload-content">
          <Icon icon="plus"></Icon>
          <h4>{{ $t('DESIGNS.LIST.UPLOAD_DESIGN') }}</h4>
          <h4>{{ $t('DESIGNS.UPLOAD.DROP_FILES') }}</h4>
        </div>
        <small
          class="text-xs"
          v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"
        ></small>
        <div class="ai-enabled" v-if="aiEnabled">
          <div class="divider"></div>
          <div class="ai-button-wrapper" @click.stop>
            <AiButton @aiButtonClicked="openAiGenerator">
              {{ $t('AI.GENERATE_DESIGN') }}
            </AiButton>
          </div>
        </div>
      </button>
    </div>

    <div class="card-container col-xs-1" v-for="idea in ideas" :key="idea.id">
      <IdeaCard
        :idea="idea"
        @toggleSelection="toggleIdeaSelection(idea)"
        @edit="$emit('edit', idea)"
      />
    </div>
    <div
      v-for="index in placeholders"
      :key="`placeholder-${index}`"
      class="card-container col-xs-1 card-placeholder"
    >
      <div class="card">
        <div class="preview-image"></div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div
      v-for="(x, index) in 5"
      :key="index"
      class="card-container col-xs-1 card-container-dummy no-margin"
      style="height: 1px; visibility: hidden"
    ></div>
  </div>
</template>

<script>
import IdeaCard from './IdeaCard.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';
import analytics from '@/tracking/analytics';
import AiButton from 'src/app/components/ai/AiButton.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import AiDesignGeneratorDialog from '@/designGenerator/AiDesignGeneratorDialog.vue';

export default {
  name: 'IdeaCardView',
  components: {
    AiButton,
    IdeaCard,
  },
  data() {
    return {
      placeholders: 0,
    };
  },
  mounted() {
    this.calcPlaceholders();
    window.addEventListener('resize', this.calcPlaceholders);
  },
  computed: {
    ...mapGetters({
      getSetting: 'settings/getSetting',
      hasMarketplace: 'user/hasMarketplace',
      aiEnabled: 'settings/aiEnabled',
    }),
    ...mapState({
      ideas: (state) => state.ideas.list,
    }),
    pageSize() {
      return this.getSetting('DESIGNS_PAGE_SIZE');
    },
    ideaCount() {
      return this.ideas.length;
    },
  },
  watch: {
    ideaCount() {
      this.calcPlaceholders();
    },
  },
  methods: {
    ...mapActions({
      toggleIdeaSelection: 'ideas/toggleIdeaSelection',
    }),
    calcPlaceholders: debounce(function () {
      if (!(this.pageSize - this.ideaCount > 0)) {
        this.placeholders = 0;
        return;
      }

      let tilesLeftOnPage = this.pageSize - this.ideaCount;

      let tilesPerPage;
      if (this.$el.clientWidth < 1060) {
        tilesPerPage = 3;
      } else {
        tilesPerPage = 4;
      }

      this.placeholders = Math.min(
        tilesLeftOnPage,
        (tilesLeftOnPage % tilesPerPage) + tilesPerPage
      );
    }, 200),
    async openAiGenerator() {
      try {
        await dialogService.openDialog(AiDesignGeneratorDialog, null, {
          staticBackdrop: true,
          preventEsc: true,
        });
      } catch (error) {
        analytics.logEvent('ai_generator_skip');
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calcPlaceholders);
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

.design-card-view {
  padding: 0 12px;
}
.card-container {
  flex: 1 1 300px;
  min-width: 300px;

  @include small-desktop {
    flex: 1 1 260px;
    min-width: 260px;
  }
}

.card-placeholder {
  display: flex;

  & > div {
    flex-grow: 1;
    border: 2px dashed #c5c5c5;
    border-radius: $border-radius-medium;

    * {
      visibility: hidden !important;
    }
  }

  .preview-image:before {
    content: '';
    display: block;
    padding-top: math.div(100, math.div(300, 350)) * 1%;
  }
}

.upload-tile {
  display: flex;
  flex-direction: column;

  .upload-card-button {
    padding: 24px;
    background-color: #fff;
    border-radius: $border-radius-medium;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border: 2px solid $pa-color-main;

    &:hover {
      border-color: $pa-color-main-dark;
    }

    .upload-content {
      margin: auto 0;

      .icon {
        color: $pa-color-main-dark;
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }

      h4 {
        margin: 4px 0 0 0;
      }
    }

    small {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      color: $grey60;

      :deep(a) {
        @extend .link-main;
      }
    }
  }
}

.divider {
  height: 2px;
  margin: 24px 0;
  background-color: $grey5;
}

.ai-button-wrapper {
  display: flex;
  justify-content: center;
}

.ai-enabled {
  width: 100%;
}
</style>

<template>
  <div
    class="digital-product-update-badge"
    :class="{ rejected: badgeContent.rejected }"
    v-if="badgeContent"
  >
    <p>{{ $t(badgeContent.title) }}</p>
  </div>
</template>

<script>
import {
  getDigitalProductUpdateState,
  DIGITAL_PRODUCTS_UPDATE_STATES,
} from '@/api/models/digitalProducts/digitalProductsStates';

export default {
  name: 'DigitalProductUpdateBadge',
  props: {
    digitalProduct: {
      type: Object,
    },
    state: {
      type: Object,
    },
    label: {
      type: Boolean,
    },
  },
  computed: {
    badgeContent() {
      if (
        getDigitalProductUpdateState(this.digitalProduct) ===
        DIGITAL_PRODUCTS_UPDATE_STATES.REVIEW
      ) {
        return {
          title: 'DIGITAL_PRODUCTS.BADGE.UPDATE_REVIEW',
        };
      } else if (
        getDigitalProductUpdateState(this.digitalProduct) ===
        DIGITAL_PRODUCTS_UPDATE_STATES.REJECTED
      ) {
        return {
          title: 'DIGITAL_PRODUCTS.BADGE.UPDATE_REJECTED',
          rejected: true,
        };
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/constants';

.digital-product-update-badge {
  background-color: $grey10;
  padding: 8px;
  border-radius: $border-radius-medium 0 0 $border-radius-medium;

  p {
    margin: 0;
    @extend .text-xs;
  }

  &.rejected {
    background-color: $pa-color-red;
    color: $grey0;
  }
}
</style>

export const PUBLISHING_SCOPE = {
  SHOP: {
    key: 'SHOP',
    routes: {
      listPage: 'partnerarea.shop.publishing',
      detailPage: 'partnerarea.shop.publishingDetail',
    },
  },
  MP: {
    key: 'MP',
    routes: {
      listPage: 'partnerarea.mp.publishing',
      detailPage: 'partnerarea.mp.publishingDetail',
    },
  },
};

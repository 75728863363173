<template>
  <div class="loading-bar" :class="{ isSuccess, isError }">
    <div class="loading-slider"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    executeFn: { type: Function, required: false },
    success: { type: Boolean, required: false },
    error: { type: Boolean, required: false },
  },
  data() {
    return {
      executeFnSuccess: false,
      executeFnError: false,
    };
  },
  async created() {
    if (this.executeFn) {
      try {
        await this.executeFn();
        this.executeFnSuccess = true;
      } catch (error) {
        this.executeFnError = true;
      }
    }
  },
  computed: {
    isSuccess() {
      return this.success || this.executeFnSuccess;
    },
    isError() {
      return this.error || this.executeFnError;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.loading-bar {
  background-color: $grey15;
  border-radius: $border-radius-medium;
  height: 16px;
  position: relative;

  &.isSuccess,
  &.isError {
    .loading-slider {
      animation: loading-slider-finished 1s linear forwards;
    }
  }

  &.isSuccess .loading-slider {
    background-color: $pa-color-green;
  }

  &.isError .loading-slider {
    background-color: $pa-color-red;
  }
}

@keyframes loading-slider-move {
  0% {
    left: 0;
  }
  100% {
    left: 80%;
  }
}

@keyframes loading-slider-finished {
  0% {
    width: 20%;
  }
  100% {
    width: 100%;
  }
}

.loading-slider {
  height: 100%;
  background-color: $pa-color-main;
  border-radius: $border-radius-medium;
  width: 20%;
  position: absolute;
  top: 0;
  animation: loading-slider-move 1s linear alternate infinite;
}
</style>

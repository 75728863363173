<template>
  <div class="sidepanelpage bulkpage bulkpos">
    <div class="sidebar">
      <h2 class="sidebar-heading">
        {{ $t('DESIGNS.BULK.POS.HEADING') }}
      </h2>
      <p class="sidebar-subheading">
        {{ $t('DESIGNS.BULK.POS.SUBHEADING') }}
      </p>
      <Dropdown
        :items="publishingDetails"
        :value="bulkPos"
        :item-key="(pos) => pos.id"
        :item-to-string="posName"
        @change="(pos) => (bulkPos = pos)"
      >
      </Dropdown>
      <div class="actions">
        <a
          href="#"
          class="link icon-link remove-link"
          :disabled="actionsDisabled"
          @click.prevent="removePosFromSelected(bulkPos)"
        >
          <Icon icon="bin" />
          {{ $t('GENERAL.REMOVE') }}
        </a>
        <a
          href="#"
          class="link icon-link add-link"
          :disabled="actionsDisabled"
          @click.prevent="addPosToSelected(bulkPos)"
        >
          <Icon icon="plus" />
          {{ $t('GENERAL.ADD') }}
        </a>
      </div>
      <IdeaBulkDmsHint v-if="!this.isShopScope" :highlight="highlightDmsHint" />
    </div>
    <div class="sidepanelpage-content bulkpagecontent">
      <div class="ideas overflow-y">
        <div v-if="selectedEmbroidery" class="language-warning">
          <div class="color-box red">
            {{ $t('DESIGNS.BULK.POS.EMBROIDERY_WARNING') }}
          </div>
        </div>

        <ul>
          <li v-for="idea in selectedIdeas" :key="idea.id">
            <IdeaPreviewImage
              class="preview-image"
              :idea="idea"
              :checkedBackground="true"
              :width="150"
              :height="150"
            />
            <div class="data">
              <div class="data-heading">
                <strong>{{ getCurrentTranslation(idea).name }}</strong>
              </div>
              <div class="pos" v-if="wasConfigured(idea)">
                <small>{{ $t('DESIGNS.DETAILS.PUBLISHED_HEADING') }}</small>
                <ul class="pos-list" v-if="getPublishingDetails(idea).length">
                  <li
                    v-for="publishingDetail in getPublishingDetails(idea)"
                    :key="publishingDetail.id"
                    :data-pos-id="publishingDetail.id"
                  >
                    <strong class="name text-truncate">{{
                      posName(publishingDetail)
                    }}</strong>
                  </li>
                </ul>
                <div
                  class="placeholder"
                  v-if="!getPublishingDetails(idea).length"
                >
                  {{ $t('DESIGNS.BULK.NO_POS') }}
                </div>
              </div>
              <div v-else class="warning">
                <small>{{ $t('DESIGNS.BULK.POS.NOT_YET_CONFIGURED') }}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ul class="toolbar-bottom nav navbar-nav nav-bottom">
        <li>
          <button type="button" class="btn btn-light" @click="back">
            {{ $t('GENERAL.CANCEL') }}
          </button>
        </li>
        <li>
          <ProgressButton
            class="btn btn-primary icon-btn"
            label="GENERAL.APPLY"
            :disabled="selectedEmbroidery"
            :onClick="save"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import IdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/IdeaPreviewImage.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';

import ideaHelper from '@/ideaHelper/ideaHelper';
import IdeaBulkDmsHint from './IdeaBulkPosDmsHint.vue';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import dialogService from '@/dialogs/wrapper/dialogService';
import PdvDialog from '@/dialogs/address/PdvDialog.vue';
import analytics from '@/tracking/analytics';

export default {
  name: 'IdeaBulkPos',
  components: {
    IdeaPreviewImage,
    ProgressButton,
    Dropdown,
    IdeaBulkDmsHint,
  },
  data() {
    return {
      noPosEntry: {
        id: null,
        pointOfSale: {
          name: this.$t('DESIGNS.DETAILS.PUBLISH_UNPUBLISH'),
        },
      },
      bulkPos: {},
      highlightDmsHint: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedIdeas: 'ideas/selected',
      selectedIdeasDirty: 'ideas/selectedIdeasDirty',
      marketplace: 'user/marketplace',
      mpEnabled: 'settings/mpEnabled',
      shops: 'user/shops',
      publishingLimitExceeded: 'dms/publishingLimitExceeded',
      hasPublishRequirements: 'user/hasPublishRequirements',
      isMpScope: 'publishing/isMpScope',
      isShopScope: 'publishing/isShopScope',
    }),
    publishingDetails() {
      const pos = [this.noPosEntry];

      if (this.mpEnabled && this.marketplace && !this.isShopScope) {
        pos.push(this.marketplace);
      }

      if (!this.isMpScope) {
        return [...pos, ...this.shops];
      } else {
        return [...pos];
      }
    },
    actionsDisabled() {
      return !this.bulkPos.id;
    },
    selectedEmbroidery() {
      return this.selectedIdeas.some((idea) =>
        ideaHelper.isEmbroideryIdea(idea)
      );
    },
  },
  created() {
    this.bulkPos = this.noPosEntry;

    unsavedChangesService.registerComponent({
      check: () => this.selectedIdeasDirty,
      save: () => {
        return this.save();
      },
      discard: () => {
        this.resetToOriginal();
      },
    });
  },
  methods: {
    ...mapMutations({
      updateOriginal: 'ideas/updateOriginal',
      resetToOriginal: 'ideas/resetToOriginal',
    }),
    ...mapActions({
      addPosToSelected: 'ideas/addPosToSelected',
      removePosFromSelected: 'ideas/removePosFromSelected',
      saveSelected: 'ideas/saveSelected',
    }),
    removePos() {
      this.removeTagsFromSelected(this.bulkTags);
      this.bulkTags = [];
    },
    goBack() {
      if (this.$route.meta.previousState?.name) {
        this.$router.push({
          ...this.$route.meta.previousState,
          query: {
            ...(this.$route.meta.previousState.query || {}),
            restoreState: true,
          },
        });
      } else {
        this.$router.push({
          name: 'partnerarea.ideas',
          query: { restoreState: true },
        });
      }
    },
    back() {
      this.resetToOriginal();
      this.goBack();
    },
    async save() {
      const mpIncluded = this.selectedIdeas.some((idea) =>
        idea.publishingDetails.some(
          (detail) => detail.pointOfSale.type === 'MARKETPLACE'
        )
      );

      if (mpIncluded && this.publishingLimitExceeded) {
        this.highlightDmsHint = true;
        setTimeout(() => {
          this.highlightDmsHint = false;
        }, 1000);
        return Promise.reject();
      }

      if (mpIncluded && !this.hasPublishRequirements) {
        try {
          await dialogService.openDialog(PdvDialog);
        } catch (error) {
          return Promise.reject(error);
        }
      }

      analytics.logEvent('idea-bulk-pos', {
        selectedCount: this.selectedIdeas.length,
      });

      await this.saveSelected({ updatePublishing: true });
      this.goBack();
    },
    posName(pos) {
      if (pos.pointOfSale.type === 'MARKETPLACE') {
        return this.$t('GENERAL.MARKETPLACE');
      } else {
        return pos.pointOfSale.name;
      }
    },
    getCurrentTranslation: ideaHelper.getCurrentTranslation,
    wasConfigured: ideaHelper.wasConfigured,
    getPublishingDetails: ideaHelper.getPublishingDetails,
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/utils';
@import 'src/scss/animations';

.actions {
  margin-top: 16px;
}

.pos-list {
  list-style: none;
  margin: 0;
  padding: 0;

  strong {
    display: block;
    margin: 4px 0 0 0;
  }
}

.warning {
  margin-top: auto;
}

.language-warning {
  max-width: 1400px;
  margin: 24px auto 0;
  padding: 0 16px;

  .color-box {
    text-align: center;
  }
}
</style>

<template>
  <div class="news">
    <h3>
      {{ $t('DASHBOARD.NEWS') }}
    </h3>
    <div class="feed" v-if="feedItems && feedItems.length">
      <ul>
        <li v-for="item in feedItems" :key="item.id">
          <a
            class="feed-item"
            :href="item.url"
            target="_blank"
            v-trackClickEvent="{
              name: 'blog-post-clicked',
              details: { pos: item.pos },
            }"
          >
            <div class="feed-item-data">
              <div class="top">
                <div class="preview-image">
                  <div class="preview-image-wrapper">
                    <img
                      v-if="item.previewImage"
                      :src="item.previewImage"
                      alt="Blog Post Thumbnail"
                    />
                    <Icon icon="blog_placeholder" />
                  </div>
                </div>
                <div class="feed-content">
                  <div class="meta">
                    <span class="date text-sm">{{ item.date }}</span>
                    <span class="text-grey text-sm" v-if="item.author">
                      {{ ` - ${item.author.name}` }}</span
                    >
                  </div>
                  <h4 class="text-truncate" v-html="item.title"></h4>
                  <p
                    class="feed-summary text-grey text-sm"
                    v-html="item.description"
                  ></p>
                </div>
              </div>
              <div class="bottom">
                <MarketplaceLogo
                  v-if="partnerType !== 'SHOP' && item.pos === 'MP'"
                />
                <SpreadshopLogo
                  v-if="partnerType !== 'MP' && item.pos === 'SHOP'"
                />
              </div>
            </div>
            <div class="more-link">
              <Icon icon="chevron"></Icon>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="loader" v-else-if="!feedItems">
      <img src="/images/loader.svg" class="sprd-loader" />
    </div>
    <p class="text-sm error" v-html="$t('DASHBOARD.NEWS_ERROR')" v-else></p>
  </div>
</template>

<script>
import apiClient from '@/api/client/apiClient';
import date from '@/date/date';
import localeService from '@/localeService/localeService';
import { mapGetters } from 'vuex';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';

// type, platform, ui language
const blogLinks = {
  MP: {
    EU: {
      de: 'https://www.spreadshirt.de/blog/feed/json/',
      fr: 'https://www.spreadshirt.fr/blog/feed/json/',
      default: 'https://www.spreadshirt.co.uk/blog/feed/json/',
    },
    NA: {
      default: 'https://www.spreadshirt.com/blog/feed/json/',
    },
  },
  SHOP: {
    EU: {
      de: 'https://www.spreadshop.com/de/blog/feed/json/',
      fr: 'https://www.spreadshop.com/fr/blog/feed/json/',
      default: 'https://www.spreadshop.com/blog/feed/json/',
    },
    NA: {
      fr: 'https://www.spreadshop.com/fr/blog/feed/json/',
      default: 'https://www.spreadshop.com/blog/feed/json/',
    },
  },
};

const hideTag = {
  EU: 'pa-hidden-eu',
  NA: 'pa-hidden-na',
};

export default {
  name: 'News',
  components: {
    MarketplaceLogo,
    SpreadshopLogo,
  },
  props: {
    count: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      feedItems: null,
    };
  },
  computed: {
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
    blogSources() {
      if (this.partnerType === 'BOTH') {
        const linkShop = blogLinks.SHOP[SPRD.PLATFORM];
        const linkMp = blogLinks.MP[SPRD.PLATFORM];

        return [
          linkMp[localeService.getLanguage()] || linkMp.default,
          linkShop[localeService.getLanguage()] || linkShop.default,
        ];
      } else {
        const links = blogLinks[this.partnerType][SPRD.PLATFORM];
        return [links[localeService.getLanguage()] || links.default];
      }
    },
  },
  async created() {
    const responses = await Promise.allSettled(
      this.blogSources.map((blogSource) =>
        apiClient.request({
          method: 'GET',
          url: blogSource,
          withCredentials: false,
          params: {
            locale: null,
          },
        })
      )
    );

    this.feedItems = responses
      .filter((response) => {
        return response.status === `fulfilled`;
      })
      .reduce((items, response) => {
        return items.concat(response.value.data.items);
      }, [])
      .sort((a, b) => {
        return new Date(b.date_published) - new Date(a.date_published);
      })
      .filter((item) => !item.tags || !item.tags.includes('pa-hidden'))
      .filter(
        (item) => !item.tags || !item.tags.includes(hideTag[SPRD.PLATFORM])
      )
      .slice(0, this.count)
      .map((item) => {
        const tags = [
          ...(item.tags || []),
          item.url.includes('spreadshop.com') ? 'pa-mds' : 'pa-mp',
        ];
        const pos = tags.includes('pa-mds') ? 'SHOP' : 'MP';

        return {
          ...item,
          tags,
          pos,
          description: `${item.content_text.substring(0, 120)}...`,
          previewImage: item.image.replace(
            '/image/upload/',
            '/image/upload/h_90,q_auto/'
          ),
          date: date(item.date_published, 'longDate'),
          author:
            item.authors && item.authors.length && !item.author
              ? item.authors[0]
              : item.author,
        };
      });
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/utils';

.news {
  padding: 24px;
  border-radius: $border-radius-medium;
  background-color: $white;
  overflow: hidden;

  & > h3 {
    margin: 0 0 10px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 220px 0;

  img {
    height: 100px;
    width: 100px;
  }
}

.error {
  text-align: center;
  margin: 16px 0 0;
}

ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px -24px -24px;
}

li {
  list-style: none;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  min-width: 460px;
}

.feed-item {
  display: flex;
  align-items: center;
  padding: 24px;
  color: $grey80;

  .feed-item-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .top {
      display: flex;
    }

    .bottom {
      margin-left: 106px;
      margin-top: 8px;

      img {
        height: 12px;
        margin-right: 16px;
      }
    }
  }

  .preview-image {
    flex-shrink: 0;
    .preview-image-wrapper {
      display: flex;
      height: 90px;
      width: 90px;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      border-radius: $border-radius-medium;
      background-color: $grey5;

      img {
        position: absolute;
        height: 100%;
        top: 0;
        left: -40%;
        z-index: 1;
      }

      svg.icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  .feed-content {
    padding-left: 16px;
    flex-grow: 1;
    flex: 1;
    overflow: hidden;

    .meta {
      .date {
        font-weight: bold;
      }
    }

    h4 {
      margin: 8px 0;
    }

    .feed-summary {
      display: flex;
      align-items: center;

      // TODO: can this be removed or is can the v-html contain p tags?
      p {
        margin: 0;

        a {
          display: none;
        }
      }

      p:not(:first-child) {
        display: none;
      }
    }
  }

  .more-link {
    padding-left: 5px;
    margin-left: auto;

    .icon {
      height: 16px;
      width: 16px;
      color: $grey20;
    }
  }

  &:hover {
    .more-link .icon,
    h4 {
      color: $pa-color-main;
    }
  }
}
</style>

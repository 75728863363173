<template>
  <div
    class="idea-bulk-pos-dms-hint"
    v-if="hasMarketplace"
    :class="{ highlight, 'above-limit': publishingLimitExceeded }"
  >
    <MarketplaceLogo class="logo" />
    <h4>{{ $t('DESIGNS.CONFIGURATION.POINTS_OF_SALE.MP_SELECT') }}</h4>
    <MpPublishingLimitReachedHint v-if="publishingLimitExceeded" />
    <div class="counter">
      <DmsPublishingCounter />
      <span>{{ $t('DMS.PUBLISHES') }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MpPublishingLimitReachedHint from 'src/app/partnerarea/idea/configuration/pointsOfSale/MpPublishingLimitReachedHint.vue';
import DmsPublishingCounter from 'src/app/components/dms/DmsPublishingCounter.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';

export default {
  name: 'IdeaBulkPosDmsHint',
  components: {
    MpPublishingLimitReachedHint,
    DmsPublishingCounter,
    MarketplaceLogo,
  },
  props: {
    highlight: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      ideas: (s) => s.ideas.list,
    }),
    ...mapGetters({
      hasMarketplace: 'user/hasMarketplace',
      getProperty: 'dms/getProperty',
      publishingCount: 'dms/publishingCount',
      publishingLimitExceeded: 'dms/publishingLimitExceeded',
    }),
    publishingLimit() {
      return this.getProperty(DMS_PROPERTIES.PUBLISHING_LIMIT).value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';
@import 'src/scss/animations';

.idea-bulk-pos-dms-hint {
  padding: 16px;
  border: 1px solid $grey20;
  border-radius: $border-radius-medium;
  margin-top: 24px;

  .logo {
    height: 16px;
  }

  h4 {
    margin: 12px 0;
  }

  .counter {
    margin: 12px 0 0 0;
  }

  &.above-limit {
    border-color: $pa-color-red;
  }

  &.highlight {
    animation: shakeUpDown 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
}
</style>

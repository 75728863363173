<template>
  <LabelInput :model="tags.join(',')" :class="{ 'has-error': hasError }">
    <label v-if="!hideLabel" for="design-detail-tags" class="text-medium-grey">
      {{ $t(labelText) }}
    </label>

    <div
      class="tag-input-container clearfix"
      :class="{ focused: focused, disabled }"
      @keydown.delete="removeLastTag"
      @click="focusInput"
    >
      <div class="disabled-overlay"></div>
      <div
        class="tag-input-item pull-left"
        @click="handleClick ? handleClick(tag) : removeTag(tag)"
        v-for="tag in tags"
        :key="`tag${tag}`"
        :class="{
          invalid: blacklistResult && isBlacklisted(tag),
          duplicate: currentTag === tag,
          highlight: highlightTags && highlightTags.split(',').includes(tag),
        }"
      >
        <div class="tag-text text-truncate">{{ tag }}</div>
        <Icon
          class="close"
          icon="close"
          @click.stop="handleClick ? removeTag(tag) : removeTag(tag)"
        />
      </div>

      <span class="autocomplete-container" v-show="tags.length < max">
        <AutoComplete
          class="tag-auto-complete"
          :items="getTagSuggestions"
          :clearOnChange="true"
          @change="selectTag"
          @focusChanged="inputFocusChanged"
          ref="autocomplete"
          v-selectOnComma="'.dropdown-button'"
          v-selectOnBlur="'.dropdown-button'"
          v-selectOnWhitespace="'.dropdown-button'"
          v-maxInputLength="maxLength"
        />
      </span>
    </div>

    <div class="field-info">
      <small
        class="info"
        v-if="
          displayHint &&
          (!displayErrorText || isValid) &&
          !blacklistedTags.length
        "
        >{{ $t(hintText || 'DESIGNS.DETAILS.TAG_HINT') }}</small
      >
      <small
        class="error-info error-info-tags pull-left"
        v-if="displayErrorText && !isValid"
      >
        {{
          $t('DESIGNS.VALIDATION.TAGS.LENGTH', {
            min,
            max,
          })
        }}
      </small>
      <small
        class="error-info error-info-tags"
        v-if="isValid && blacklistedTags.length"
      >
        {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
        {{ blacklistedTags.join(', ') }}
      </small>
      <small
        v-if="
          !hideLanguage && !displayHint && isValid && !blacklistedTags.length
        "
        class="info"
      >
        {{ languageName }}
      </small>

      <small v-if="displayTagsLeft" class="char-info design-tags-left"
        >{{ tags.length || 0 }} / {{ max }}</small
      >
    </div>

    <div
      v-if="displayRelatedTags"
      class="form-group tag-input-related-tags"
      v-show="relatedTags.length > 0"
    >
      <p class="label-description">{{ $t('DESIGNS.RELATED_TAGS') }}:</p>
      <ul class="list-inline related-tags-list">
        <li v-for="tag in relatedTags" :key="`relatedtag-${tag}`">
          <button
            type="button"
            @click="addRelatedTag(tag)"
            class="btn btn-sm btn-dark"
          >
            {{ tag }}
          </button>
        </li>
      </ul>
    </div>
  </LabelInput>
</template>

<script>
import { mapGetters } from 'vuex';
import AutoComplete from '@/Autocomplete/Autocomplete.vue';
import tagService from '@/api/tagService/tagService';
import { checkTerm } from '@/api/blacklistService/blacklistService';
import LabelInput from '@/labelInput/LabelInput.vue';
import { chunk } from '@/utils';
import localeService from '@/localeService/localeService';
import analytics from '@/tracking/analytics';

export default {
  name: 'TagInput',
  components: {
    AutoComplete,
    LabelInput,
  },
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    maxLength: {
      type: Number,
      required: true,
    },
    tags: {
      type: Array,
    },
    handleClick: {
      type: Function,
    },
    highlightTags: {
      type: String,
    },
    hideLabel: {
      type: Boolean,
    },
    hideLanguage: {
      type: Boolean,
    },
    displayHint: {
      type: Boolean,
    },
    hintText: {
      type: String,
    },
    displayTagsLeft: {
      type: Boolean,
    },
    displayErrorText: {
      type: Boolean,
    },
    displayRelatedTags: {
      type: Boolean,
    },
    locale: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    isValid: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    labelText: {
      type: String,
      default: 'DESIGNS.TAGS',
    },
  },
  data() {
    return {
      focused: false,
      blacklistResult: [],
      currentTag: '',
      relatedTags: [],
    };
  },
  created() {
    this.refreshRelatedTags();
    this.checkBlacklist(this.tags, this.locale);
  },
  computed: {
    ...mapGetters({
      languageByIso: 'platform/languageByIso',
    }),
    languageName() {
      return this.languageByIso(
        localeService.getLanguageFromLocale(this.locale)
      )?.name;
    },
    blacklistedTags() {
      if (!this.blacklistResult?.length) {
        return [];
      }
      return this.blacklistResult[0].blacklisted.map((entry) => entry.text);
    },
    hasError() {
      return !this.isValid || this.blacklistedTags.length;
    },
  },
  watch: {
    tags: {
      handler(newTags) {
        this.refreshRelatedTags();
        this.checkBlacklist(newTags, this.locale);
      },
      deep: true,
    },
  },
  methods: {
    selectTag(tag) {
      if (!tag || !tag.trim().length) {
        return;
      }

      let tags = [];
      if (tag.length) {
        tags = tag.split(',').map((t) => t.trim());
      } else {
        tags.push(tag.trim());
      }

      tags = [].concat.apply(
        [],
        tags.map((t) => chunk(t.split(' '), 3).map((c) => c.join(' ')))
      );

      tags.forEach((singleTag) => {
        singleTag = singleTag.substring(0, this.maxLength);
        if (!this.tags.includes(singleTag)) {
          this.$emit('addTag', singleTag);
        } else {
          this.currentTag = tag;
          setTimeout(() => {
            this.currentTag = '';
          }, 750);
        }
      });
    },
    removeTag(tag) {
      if (!this.tags.includes(tag)) {
        return;
      }

      this.$emit('removeTag', tag);

      this.refreshRelatedTags();
    },
    removeLastTag() {
      if (!this.tags.length || this.$refs.autocomplete.filterValue) {
        return;
      }

      this.removeTag(this.tags[this.tags.length - 1]);
    },
    addRelatedTag(tag) {
      this.selectTag(tag);
      const index = this.relatedTags.indexOf(tag);
      if (index >= 0) {
        this.relatedTags.splice(index, 1);
      }

      analytics.logEvent('idea-related-tag-selected');
    },
    inputFocusChanged(focused) {
      this.focused = focused;
    },
    getTagSuggestions(prefix) {
      if (prefix) {
        return tagService.getTagsByPrefix(prefix, { locale: this.locale }).then(
          (tags) => {
            // check if prefix exists in array w/o case sensitivity
            const prefixIndex = tags
              .toString()
              .toLowerCase()
              .split(',')
              .indexOf(prefix.toLowerCase());
            if (prefixIndex > -1) {
              tags.splice(prefixIndex, 1);
            }

            tags = tags.filter((tag) => {
              return this.tags.indexOf(tag) === -1;
            });

            return tags;
          },
          () => {
            return [];
          }
        );
      } else {
        return Promise.resolve([]);
      }
    },
    isBlacklisted(tag) {
      return (
        this.blacklistResult &&
        this.blacklistResult.length &&
        !!this.blacklistResult[0].blacklisted.find((blackListItem) => {
          return blackListItem.text === tag;
        })
      );
    },
    async checkBlacklist(tags, locale) {
      let result;
      try {
        await checkTerm({ field: 'tags', terms: tags, locale });
        result = [];
      } catch (error) {
        result = error?.data?.list;
      } finally {
        this.$emit('blacklistUpdate', {
          blacklistResult: result,
          locale: locale,
        });

        // check that locale has not changed in between
        if (this.locale === locale) {
          this.blacklistResult = result;
        }
      }
    },
    refreshRelatedTags() {
      if (
        !this.displayRelatedTags
        // ||
        // !ideaHelper.getTranslation(this.idea, this.locale)
      ) {
        return;
      }

      tagService
        .calculateRelatedTags(
          {
            name: this.name,
            tags: this.tags,
          },
          { locale: this.locale }
        )
        .then((relatedTags) => {
          if (relatedTags.length) {
            this.relatedTags = relatedTags.slice(0, 5);
          } else {
            this.relatedTags = [];
          }
        });
    },
    focusInput(evt) {
      const input = evt.target.querySelectorAll('input')[0];
      if (input) {
        input.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/inputs';

$sprd-tag-input-color: $grey80 !default;
$sprd-tag-input-invalid-color: #c94042 !default;
$sprd-tag-input-bg-color: $grey5 !default;
$sprd-tag-input-hover-bg-color: $grey10 !default;
$sprd-tag-input-margin: 2px 5px 2px 2px !default;

.tag-input-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 16px;
  box-shadow: none;
  border: 1px solid $sprd-color-medium-grey;
  border-radius: $border-radius-medium;
  position: relative;

  @include input-states();

  &.focused {
    @include focus-style();
  }

  .tag-auto-complete {
    :deep(.dropdown-button) {
      border: none;
      height: 32px;

      .dropdown-icon-container {
        display: none;
      }
    }

    :deep(.dropdown-items) {
      border: 1px solid $sprd-color-medium-grey;
    }
  }

  .autocomplete-container {
    flex-grow: 1;
  }

  .tag-input-item {
    color: $sprd-tag-input-color;
    background-color: $sprd-tag-input-bg-color;
    margin: $sprd-tag-input-margin;
    padding: 8px 12px;
    line-height: 1.2em;
    align-items: center;
    display: flex;
    height: 28px;
    max-width: calc(100% - 4px);
    border-radius: $border-radius-medium;
    @extend .text-sm;

    .tag-text {
      padding-bottom: 2px;
    }

    &:hover {
      background-color: $sprd-tag-input-hover-bg-color;
      cursor: pointer;
    }

    &.invalid {
      background-color: $sprd-tag-input-invalid-color;
      color: $grey0;
    }

    &.duplicate {
      animation: shakeit 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }

    &.highlight {
      background-color: $grey15;
    }

    .close {
      float: right;
      padding-left: 5px;
      font-size: 1.3em;
    }
  }

  input {
    flex-grow: 1;
    min-width: 100px;
    height: 2.4em;
    width: auto;
    &,
    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .disabled-overlay {
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: $grey5;
    opacity: 0.5;
  }

  &.disabled {
    border-color: $grey20;
    .disabled-overlay {
      display: block;
    }
  }
}

.related-tags-list {
  margin-top: 0;
  margin-bottom: -5px;

  li {
    padding-bottom: 5px;

    button {
      font-weight: 400;
      background-color: $sprd-tag-input-bg-color;
      border: none;
      color: $sprd-tag-input-color;

      &:hover,
      &:focus {
        background-color: $sprd-tag-input-hover-bg-color;
      }
    }
  }

  a {
    margin-bottom: 10px;
  }
}

.tag-input-related-tags {
  margin-top: 20px;
  margin-bottom: 0;
}

@keyframes shakeit {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.info {
  display: block;
}

.label-description {
  color: $grey80;
  font-weight: bold;
  margin-bottom: 8px;
}
</style>

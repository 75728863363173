<template>
  <div class="dialog-base" :class="dialogBaseClass">
    <div class="dialog-backdrop" :class="{ 'dialog-full-size': fullSize }">
      <div
        class="dialog-content"
        :class="dialogContentClass"
        v-click-outside="{
          isActive: !staticBackdrop,
          handler: onOutsideClick,
        }"
      >
        <a
          class="dialog-close"
          v-if="displayClose"
          @click="modalInstance.dismiss"
        >
          <Icon icon="close" />
        </a>
        <slot v-if="!hasError"></slot>
        <DialogBaseError v-else />
      </div>
    </div>
  </div>
</template>

<script>
import DialogBaseError from './DialogBaseError.vue';

export default {
  name: 'DialogBase',
  components: { DialogBaseError },
  props: {
    modalInstance: {
      type: Object,
    },
    displayClose: {
      type: Boolean,
      default: true,
    },
    staticBackdrop: {
      type: Boolean,
      default: false,
    },
    dialogBaseClass: {
      type: String,
    },
    dialogContentClass: {
      type: String,
    },
    fullSize: {
      type: Boolean,
    },
  },
  data() {
    return {
      hasError: false,
    };
  },
  errorCaptured() {
    this.hasError = true;
  },
  methods: {
    onOutsideClick(evt) {
      if (evt?.type !== 'blur') {
        this.modalInstance.dismiss();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/links';
@import 'src/scss/page-layout.scss';

.dialog-base {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
}

@keyframes backdrop-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dialog-fadein {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.dialog-backdrop {
  @extend .overflow-y;

  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  opacity: 0;
  animation: backdrop-fadein 0.3s forwards;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;

  &.dialog-full-size {
    padding: 0;

    .dialog-content {
      max-width: none;
      width: 100%;
      margin: 0;
      padding: 0;
      height: 100vh;
      border-radius: 0;
      animation: none;
    }
  }
}

.dialog-content {
  position: relative;
  width: auto;
  min-width: 500px;
  background-color: #fff;
  border-radius: $border-radius-medium;
  padding: 40px;
  top: auto;
  left: auto;
  box-shadow: none;
  animation: dialog-fadein 0.6s forwards;
  margin-top: auto;
  margin-bottom: auto;
}

.dialog-close {
  z-index: 1;
  position: absolute;
  right: -33px;
  top: -33px;
  font-size: 25px;
  line-height: 25px;
  color: #fff !important;
  @extend .link;

  .icon {
    width: 1em;
    height: 1em;
  }
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/page-layout.scss';

.dialog-size-md {
  max-width: 700px;
}

.dialog-size-lg {
  max-width: 900px;
}

.dialog-size-full {
  // some legacy stuff
  display: flex;
  flex-direction: column;
  height: 100vh;

  .modal-header,
  .modal-body {
    & > div {
      @extend .fluid-fullscreen-content;

      h4 {
        margin: 0 0 4px 0;
      }

      p {
        margin: 0;
      }
    }
  }

  .modal-header {
    background-color: $grey0;
    padding: 14px 0;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.16);
    z-index: 2;
  }

  .modal-body {
    @extend .overflow-y;
    text-align: left;
    flex-grow: 1;
    padding: 24px 0;
    background-color: $grey5;
  }
}

.dialog-base.dialog-inline {
  transition: none;
  left: $navbar-main-width;

  @include small-desktop {
    left: $navbar-main-small-width;
  }

  .dialog-backdrop {
    transition: none;
    opacity: 1;
    background-color: transparent;

    left: $navbar-main-width;

    @include small-desktop {
      left: $navbar-main-small-width;
    }
  }
}

.dialog-base .dialog-content.dialog-content-no-min-width {
  min-width: unset;
}

.dialog-base.dialog-borderless {
  .dialog-content {
    min-width: initial;
    padding: 0;
  }

  .modal-body {
    border-radius: $border-radius-medium;
    box-shadow: 2px 4px 8px #00000029;
  }

  .dialog-backdrop {
    opacity: 1;
    backdrop-filter: blur(10px);
    background-color: rgba(#f2f2f2e6, 0.5);
  }

  .dialog-close .icon {
    color: $grey80 !important;
  }
}
</style>

<template>
  <div class="page-wrapper designer-config-wrapper">
    <nav class="page-header nav navbar-secondary navbar-default">
      <ul>
        <li>
          <h3>{{ config.name }}</h3>
        </li>
        <li>
          <ProgressButton
            class="btn btn-primary"
            :onClick="save"
            label="GENERAL.DONE"
          />
        </li>
        <li>
          <ProgressButton
            class="btn btn-ghost"
            :onClick="saveAndEditNext"
            label="DESIGNS.CONFIGURATION.SAVE_AND_NEXT"
          />
        </li>
        <li>
          <a href="#" class="link-main" @click.prevent="cancel">
            {{ $t('GENERAL.CANCEL') }}</a
          >
        </li>
      </ul>
    </nav>
    <div class="designer-config-content">
      <div class="design-configuration" v-if="loaded">
        <div class="designer-side">
          <DesignConfigControl
            class="design-config-control overflow-y"
            :config="config"
            @scale="scale"
            @rotate="rotate"
            @move="move"
            @selectAppearance="selectAppearance"
            @setAppearanceExclusion="setAppearanceExclusion"
            @changePrintArea="changePrintArea"
            @setPrintArea="setPrintAreaById"
          />
        </div>
        <div class="designer-content">
          <div class="heading">
            <div v-if="validation.error" class="error-hint color-box red">
              {{ $t(validation.reason) }}
            </div>
          </div>
          <div class="designer">
            <Designer
              :config="config"
              @scale="scale"
              @rotate="rotate"
              @move="move"
              @setRefs="setRefs"
              @setDesignSelection="setDesignSelection"
            />
          </div>
          <div class="printareas">
            <ul>
              <li
                v-for="printArea in printAreas"
                :key="printArea.id"
                @click="setPrintArea(printArea)"
                :class="{
                  active: config.printArea.id === printArea.id,
                  invalid: config.validation[printArea.id].error,
                }"
              >
                <div class="preview">
                  <Designer
                    :config="getConfigForPrintAreaPreview(printArea)"
                    :passive="true"
                  />
                  <div class="prevent-interaction"></div>
                </div>
                <small>{{ printArea.viewData.name }}</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="load-wrapper" v-if="!loaded">
        <img class="sprd-loader loader" src="/images/loader.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import productIdeaService from 'src/app/commons/api/productIdeaService/productIdeaService';
import Designer from './designer.vue';
import DesignConfigControl from './designConfigControl.vue';
import ProgressButton from 'src/app/commons/btnProgress/ProgressButton.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';

export default {
  name: 'DesignerConfiguration',
  components: {
    Designer,
    DesignConfigControl,
    ProgressButton,
  },
  props: ['idea', 'group'],
  data() {
    return {
      config: {},
      productType: null,
      loaded: false,
      designerRefs: null,
      printArea: null,
    };
  },
  async created() {
    let config = {
      validation: {
        error: false,
        reason: null,
      },
      designSelected: false,
    };

    config.template = await productIdeaService.getProductForSellable(
      this.idea,
      this.group.primarySellable.id
    );

    // add missing appearance hints using color info in assortment payload
    // required until DEV-174171 is resolved
    config.template.availableAppearances.map((appearance) => {
      const assortmentGroupColor = this.group.primarySellable.colors.find(
        (color) => color.appearanceId === appearance.appearanceId
      );

      if (!assortmentGroupColor) {
        return appearance;
      }

      appearance.hints = assortmentGroupColor.hints;
    });

    config.template.configurations.map((c) => {
      if (!c.rotate) {
        c.rotate = 0;
      }
    });
    config.template.appearanceId = this.group.primarySellable.appearanceId;
    config.name = this.group.name;
    config.designImages = this.getImagesPerDesign(this.idea, config.template);
    config.defaultImageUrl = this.group.primarySellable.defaultImageUrl;
    config.configuration = config.template.configurations[0];
    config.validation = {};
    Object.keys(config.template.productType.printAreas).forEach(
      (printAreaId) => {
        config.validation[printAreaId] = {};
      }
    );

    Object.keys(config.template.productType.printAreas).forEach((paId) => {
      const printArea = config.template.productType.printAreas[paId];
      printArea.viewData = config.template.productType.views.find(
        (view) => view.id === printArea.viewId
      );
    });

    this.config = config;
    this.setPrintArea(
      config.template.productType.printAreas[
        this.config.configuration.printAreaId
      ]
    );

    this.loaded = true;
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    async save({ continueSequentialEdit } = {}) {
      if (
        Object.values(this.config.validation).some(
          (printAreaValidation) => printAreaValidation.error
        )
      ) {
        throw new Error('invalid');
      }
      await productIdeaService.updateProductForSellable(
        this.idea,
        this.group.primarySellable.id,
        this.config.template
      );
      this.$emit('save', { config: this.config, continueSequentialEdit });
    },
    async saveAndEditNext() {
      return this.save({ continueSequentialEdit: true });
    },
    scale(delta) {
      this.setDesignSelection(true);

      const aspectRatio = this.svgImageHeight / this.svgImageWidth;
      this.svgImageWidth += delta;
      this.svgImageHeight += aspectRatio * delta;
      this.config.configuration.x -= delta / 2;
      this.config.configuration.y -= (aspectRatio * delta) / 2;

      this.validate();
    },
    rotate(angle, { absolute = false } = {}) {
      this.setDesignSelection(true);

      let rotAngle = absolute
        ? angle
        : this.config.configuration.rotate + angle;
      if (rotAngle < 0) {
        rotAngle += 360;
      } else if (rotAngle > 360) {
        rotAngle -= 360;
      }

      this.config.configuration.rotate = rotAngle;

      this.validate();
    },
    move({ x, y }) {
      this.setDesignSelection(true);

      this.config.configuration.x = x;
      this.config.configuration.y = y;

      this.validate();
    },
    selectAppearance(appearance) {
      this.config.template.appearanceId = appearance.appearanceId;
    },
    setAppearanceExclusion(appearance, status) {
      const existingAppearance = this.config.template.availableAppearances.find(
        (a) => a.appearanceId === appearance.appearanceId
      );
      if (existingAppearance) {
        existingAppearance.excluded = status;
      }
    },
    async adjustDesignToPrintArea(printAreaId) {
      await this.setPrintAreaById(printAreaId);

      if (this.svgImageWidth > this.maxScale) {
        this.scale(this.maxScale - this.svgImageWidth);
      }

      this.move({
        x: (this.config.printArea.boundary.size.width - this.svgImageWidth) / 2,
        y:
          (this.config.printArea.boundary.size.height -
            this.config.configuration.height) /
          2,
      });

      if (this.config.configuration.rotate) {
        let angle = this.config.configuration.rotate;
        this.rotate(-angle);
      }
    },
    async changePrintArea({ printArea, previousPrintArea }) {
      const configForPreviousPrintArea =
        this.config.template.configurations.find(
          (c) => c.printAreaId === previousPrintArea.id
        );
      const configForTargetPrintArea = this.config.template.configurations.find(
        (configuration) => configuration.printAreaId === printArea.id
      );

      configForPreviousPrintArea.printAreaId = printArea.id;

      if (configForTargetPrintArea) {
        configForTargetPrintArea.printAreaId = previousPrintArea.id;
        // reinitialize current Print Area with switched design, scale and validate
        await this.adjustDesignToPrintArea(
          configForTargetPrintArea.printAreaId
        );
      } else {
        // reinitialize old, now empty, Print Area so its validated correctly
        await this.setPrintAreaById(previousPrintArea.id);
      }

      await this.$nextTick();

      // switch to new print Area and scale design appropriately
      await this.adjustDesignToPrintArea(
        configForPreviousPrintArea.printAreaId
      );
    },
    async setPrintArea(printArea) {
      this.config.printArea = printArea;
      this.config.configuration = this.config.template.configurations.find(
        (c) => c.printAreaId === this.config.printArea.id
      );
      await this.$nextTick();
      this.validate();
    },
    async setPrintAreaById(printAreaId) {
      await this.setPrintArea(
        this.config.template.productType.printAreas[printAreaId]
      );
    },
    setRefs(refs) {
      this.designerRefs = refs;
    },
    async validate() {
      await this.$nextTick();

      if (!this.config.configuration) {
        this.config.validation[this.config.printArea.id] = {
          error: false,
        };
        return;
      }

      if (
        !this.designerRefs ||
        !this.designerRefs.design ||
        !this.designerRefs.printArea
      ) {
        return;
      }

      const designBBox = this.designerRefs.design.getBoundingClientRect();
      const printAreaBBox = this.designerRefs.printArea.getBoundingClientRect();

      if (!printAreaBBox.height || !printAreaBBox.width) {
        return;
      }

      const errors = [
        {
          error:
            !this.hasSoftboundary &&
            (printAreaBBox.top >= designBBox.top ||
              printAreaBBox.right <= designBBox.right ||
              printAreaBBox.bottom <= designBBox.bottom ||
              printAreaBBox.left >= designBBox.left),
          reason: 'DESIGNS.CONFIGURATION.EDITOR.VALIDATION.OUT_OF_BOUNDS',
        },
        {
          error: Math.ceil(this.ratio.maxWidth) < this.svgImageWidth,
          reason: 'DESIGNS.CONFIGURATION.EDITOR.VALIDATION.TOO_LARGE',
        },
        {
          error: Math.floor(this.ratio.minWidth) > this.svgImageWidth,
          reason: 'DESIGNS.CONFIGURATION.EDITOR.VALIDATION.TOO_SMALL',
        },
      ];

      const error = errors.find((err) => err.error);
      if (error) {
        this.config.validation[this.config.printArea.id] = error;
      } else {
        this.config.validation[this.config.printArea.id] = {
          error: false,
        };
      }
    },
    setDesignSelection(val) {
      this.config.designSelected = val;
    },
    getConfigForPrintAreaPreview(printArea) {
      const configuration = this.config.template.configurations.find(
        (c) => c.printAreaId === printArea.id
      );
      if (configuration && !configuration.rotate) {
        configuration.rotate = 0;
      }
      return Object.assign({}, this.config, {
        printArea,
        configuration,
      });
    },
    getImagesPerDesign(idea, template) {
      return template.configurations.reduce((result, current) => {
        const parentMotive =
          idea.parents &&
          idea.parents.find((parent) => parent.designId === current.designId);

        result[current.designId] = {
          href: template.resources[current.designId],
          backgroundColor: parentMotive
            ? parentMotive.backgroundColor
            : this.idea.backgroundColor,
          name: parentMotive
            ? parentMotive.name
            : ideaHelper.getCurrentTranslation(idea).name,
        };
        return result;
      }, {});
    },
  },
  computed: {
    printAreas() {
      return Object.keys(this.config.template.productType.printAreas).map(
        (paId) => this.config.template.productType.printAreas[paId]
      );
    },
    validation() {
      return this.config.validation[this.config.printArea.id];
    },
    hasSoftboundary() {
      return (
        this.config.printArea &&
        this.config.printArea.boundary.soft &&
        this.config.printArea.boundary.soft.content
      );
    },
    svgImageWidth: {
      get() {
        return this.config.configuration.width;
      },
      set(value) {
        this.config.configuration.width = Math.abs(value);
      },
    },
    svgImageHeight: {
      get() {
        return this.config.configuration.height;
      },
      set(value) {
        this.config.configuration.height = Math.abs(value);
      },
    },
    ratio() {
      return this.config.printArea.ratios[this.config.configuration.designId];
    },
    maxScale() {
      return Math.floor(this.ratio.maxWidth);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/utils';
@import 'src/scss/animations';

.page-header {
  height: 56px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 12px;

    li {
      margin-left: 16px;

      &:first-child {
        margin-right: auto;
        margin-left: 0;
        display: flex;
        align-items: center;

        h3 {
          margin: 0 16px 0 0;
          flex-shrink: 0;
        }
      }

      button {
        height: 40px;
      }
    }
  }
}

.designer-config-wrapper {
  z-index: 1020;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $grey5;
}

.designer-config-content {
  height: 100%;
  width: 100%;
}

.design-configuration {
  display: flex;
  flex-grow: 1;
  display: flex;
  height: 100%;
}

.designer-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  opacity: 1;
  animation: fadein 0.5s linear both;
  animation-delay: 1s;
  position: relative;
}

.designer {
  position: relative;
  flex-grow: 1;
  margin-top: 60px;
}

.heading {
  display: flex;
  justify-content: center;
  padding: 0 24px;
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;

  h2 {
    margin: 0;
  }

  .error-hint {
    z-index: 1;
  }
}

.designer-side {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 100%;
  background-color: $grey0;
  border-right: 2px solid $grey5;
  animation: slidein 0.25s linear both;
  animation-delay: 0.5s;

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 85px;
    padding: 0 40px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .save-btn {
    padding: 10px 25px;
    margin: 15px 0 30px 0;
  }
}

.load-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 616px;

  img {
    width: 150px;
  }
}

.printareas {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;

  ul {
    margin: 0;
    padding: 6px 12px;
    list-style: none;
    background-color: $grey0;
    border-radius: $border-radius-medium 0 0 $border-radius-medium;
    width: 100%;

    li {
      padding: 6px 0;
      cursor: pointer;

      &.active {
        .preview {
          box-shadow: 0 0 0 2px $pa-color-main;
        }

        small {
          color: $pa-color-main;
          font-weight: bold;
        }
      }

      &.invalid {
        .preview {
          box-shadow: 0 0 0 1px $pa-color-red;
        }

        small {
          color: $pa-color-red;
          font-weight: bold;
        }
      }

      &.active.invalid {
        .preview {
          box-shadow: 0 0 0 2px $pa-color-red;
        }
      }

      small {
        display: block;
        @extend .text-truncate;
        text-align: center;
        margin-top: 4px;
      }

      .preview {
        position: relative;
        height: 70px;
        background-color: $grey5;
        border-radius: $border-radius-medium;
        overflow: hidden;
      }

      .prevent-interaction {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }
}
</style>

<template>
  <div class="onboarding-help-cards" :class="{ light }">
    <router-link to="/tipsAndTools" class="link-main">
      <img src="/images/faq.svg" alt="tips and tools" />
      <strong>{{ $t('NAV.TIPS_AND_TOOLS') }}</strong>
    </router-link>
    <a href="#" class="link-main" @click.prevent="openContact">
      <img src="/images/customer-service.svg" alt="contact" />
      <strong>{{ $t('GENERAL.HELP') }}</strong>
    </a>
  </div>
</template>

<script>
import HelpDialog from 'src/app/components/helpDialog/HelpDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'OnboardingHelpCards',
  props: {
    light: {
      type: Boolean,
    },
  },
  methods: {
    goToTipsAndTools() {
      this.$router.push({ name: 'partnerarea.tipsAndTools' });
    },
    openContact() {
      dialogService.openDialog(HelpDialog);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.onboarding-help-cards {
  display: flex;
  margin: 0 0 24px 0;
  width: 100%;

  a {
    background-color: $grey5;
    border-radius: $border-radius-medium;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0px;
    width: 0;
    margin: 0 12px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      max-height: 120px;
      margin-bottom: 8px;
    }

    strong {
      margin-top: auto;
      text-align: center;
    }
  }

  &.light a {
    background-color: $white;
  }
}
</style>

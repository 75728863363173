<template>
  <div class="publishing-products">
    <div class="publishing-image-wrapper">
      <div class="publishing-image">
        <div class="random-image-wrapper" :key="updateTimestamp">
          <RandomImage
            class="random-image"
            :imageUrls="sellableImages"
            width="400"
          />
        </div>
        <div
          class="design-image-wrapper design-checked-background"
          :class="{ dark: composition.properties.darkBackground }"
        >
          <SprdImage
            class="design-image"
            :src="`/image-server/v1/designs/${composition.mainDesign.id},width=300,height=300.png`"
          />
          <button
            class="dark-background-toggle"
            @click.prevent="toggleBackgroundColor"
          >
            <Icon
              icon="background-color"
              class="bg-toggle"
              :class="{
                'rotate-icon-180': composition.properties.darkBackground,
              }"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="publishing-properties">
      <ConfigList class="data">
        <ul>
          <li>
            <div>
              <p>
                {{ $t('PUBLISHING.DETAIL.PRODUCTS.PRODUCTS_CREATED.HEADING') }}
              </p>
              <p class="text-sm text-grey">
                {{
                  $t('PUBLISHING.DETAIL.PRODUCTS.PRODUCTS_CREATED.SUBHEADING')
                }}
              </p>
            </div>
            <div>{{ composition.sellables.length }}</div>
          </li>
          <li>
            <div>
              <p>
                {{ $t('PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.HEADING') }}
              </p>
              <p class="text-sm text-grey">
                {{ $t('PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.SUBHEADING') }}
              </p>
            </div>
            <div v-if="defaultAppearanceGroup">
              {{ defaultAppearanceGroup.name }}
            </div>
            <div v-else>
              {{ $t('PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.AUTOMATIC') }}
            </div>
          </li>
          <li>
            <div>
              <p>
                {{ $t('PUBLISHING.DETAIL.PRODUCTS.DEFAULT_POSITION.HEADING') }}
              </p>
              <p class="text-sm text-grey">
                {{
                  $t('PUBLISHING.DETAIL.PRODUCTS.DEFAULT_POSITION.SUBHEADING')
                }}
              </p>
            </div>
            <div>
              {{ $t(defaultHotspotName) }}
            </div>
          </li>
        </ul>
      </ConfigList>
      <button class="link-main icon-btn edit-btn" @click="openSellablesDialog">
        <Icon icon="pencil" /> {{ $t('PUBLISHING.DETAIL.PRODUCTS.EDIT_CTA') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ConfigList from 'src/app/components/publishing/ConfigList.vue';
import SprdImage from '@/image/SprdImage.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import compositionHelper from '@/composition/compositionHelper';
import RandomImage from 'src/app/components/randomImage/RandomImage.vue';
import PublishingProductSettingsDialog from './productSettings/PublishingProductSettingsDialog.vue';
import { HOTSPOTS } from '@/states/hotspots';

export default {
  name: 'PublishingProducts',
  components: {
    ConfigList,
    SprdImage,
    RandomImage,
  },
  props: {},
  data() {
    return {
      mpSelected: false,
      updateTimestamp: Date.now(),
    };
  },
  created() {},
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
    ...mapGetters({
      defaultAppearanceGroup: 'composition/defaultAppearanceGroup',
    }),
    sellableImages() {
      return compositionHelper.getAllActivatedSellableImages(this.composition);
    },
    defaultHotspotName() {
      return HOTSPOTS[this.composition.defaultValues.defaultHotspot]?.name;
    },
  },
  methods: {
    ...mapMutations({
      toggleBackgroundColor: 'composition/toggleBackgroundColor',
    }),
    ...mapActions({}),
    openSellablesDialog() {
      dialogService
        .openDialog(PublishingProductSettingsDialog, null, {
          fullSize: true,
        })
        .then(() => {
          // rerender sellable images to update them immediately with changes made
          this.updateTimestamp = Date.now();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.publishing-products {
  display: flex;

  .publishing-image-wrapper {
    flex-shrink: 0;
    width: 35%;
    min-width: 200px;
    margin-right: 24px;
  }

  .design-image-wrapper {
    display: none;
    padding: 16px;

    &.dark .bg-toggle {
      color: $white;
    }
  }

  .random-image-wrapper {
    padding: 8px;
    background-color: $grey5;
  }

  .design-image {
    display: flex;
  }

  .publishing-image {
    aspect-ratio: 1;
    flex: 1;
    width: 100%;
    margin: 0 24px 0 0;
    border: 1px solid $grey10;
    border-radius: $border-radius-medium;
    position: relative;
    overflow: hidden;

    &:hover {
      .design-image-wrapper {
        display: block;
      }

      .random-image-wrapper {
        display: none;
      }
    }

    :deep(img) {
      width: 100%;
      height: 100%;
    }

    .dark-background-toggle {
      position: absolute;
      left: 4px;
      bottom: 4px;
      padding: 0;

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    &.dark .dark-background-toggle {
      color: $grey5;
    }
  }

  .data {
    flex-grow: 1;
  }
}

.publishing-properties {
  width: 100%;

  & > div {
    padding: 0 0 16px 0;
    border-bottom: 1px solid $grey10;

    p {
      margin: 0;
    }
  }

  .edit-btn {
    margin: 16px 0 0 0;
  }
}

.sellables-link {
  font-weight: normal;
  color: $grey80;
  .icon {
    margin-left: 8px;
  }
}
</style>

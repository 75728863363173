<template>
  <div>
    <div class="loader" v-if="!initialized">
      <img src="/images/loader.svg" />
    </div>
    <div v-else class="page-wrapper idea-assortment-empty">
      <nav class="page-header navbar navbar-secondary navbar-default">
        <div class="menu">
          <h3>{{ $t('MP_REMOVAL.HEADING') }}</h3>
          <a href="#" class="link-main icon-link" @click.prevent="close">
            <Icon icon="close" />
            {{ $t('GENERAL.CLOSE') }}
          </a>
        </div>
      </nav>
      <div class="page-content">
        <div class="overflow-y">
          <div v-if="ideas.length">
            <div class="list">
              <div v-for="idea in ideas" :key="idea.id" class="idea-card">
                <div>
                  <div class="image-wrapper">
                    <IdeaPreviewImage
                      class="idea-image"
                      :idea="idea"
                      @click="onEdit(idea)"
                      enableOverlay
                      :checkedBackground="true"
                      :width="190"
                      :height="190"
                    />
                  </div>
                  <div class="text-xl idea-card-footer">
                    {{ getIdeaName(idea) }}
                  </div>
                </div>
              </div>
              <div
                v-for="index in [1, 2, 3, 4, 5, 6]"
                :key="index"
                class="idea-card idea-card-placeholder"
              />
            </div>
            <Pagination
              :total="totalIdeaCount"
              :pageSize="pageSize"
              :currentPage="currentPage"
              @pageChange="onPageChange"
            />
          </div>
          <div v-else class="empty-list">
            <img src="/images/chillout.svg" alt="empty-list" />
            <h2>{{ $t('EMPTY_ASSORTMENT.EMPTY_STATE.HEADING') }}</h2>
            <p>{{ $t('MP_REMOVAL.EMPTY_SUBHEADING') }}</p>
            <button class="btn btn-primary" @click="close">
              {{ $t('GENERAL.BACK_TO_ALL_DESIGNS') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/IdeaPreviewImage.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';
import { mapState, mapMutations, mapGetters } from 'vuex';
import Pagination from 'src/app/commons/pagination/Pagination.vue';
import productIdeaService from '@/api/productIdeaService/productIdeaService';

export default {
  name: 'IdeaMpRemoval',
  components: {
    IdeaPreviewImage,
    Pagination,
  },
  data() {
    return {
      initialized: false,
      pageSize: 48,
    };
  },
  computed: {
    ...mapGetters({
      totalIdeaCount: 'ideas/totalIdeaCount',
    }),
    ...mapState({
      ideas: (s) => s.ideas.list,
      currentPage: (s) => s.ideas.filter.page,
    }),
  },
  async created() {
    const restoreState = this.$route.query.restoreState && this.ideas.length;
    if (!restoreState) {
      this.setFilter({ page: 0 });
    }
    await this.fetchIdeas();
    if (!this.ideas.length) {
      this.setFilter({ page: 0 });
      await this.fetchIdeas();
    }

    this.initialized = true;
  },
  methods: {
    ...mapMutations({
      setIdeaList: 'ideas/setList',
      setFilter: 'ideas/setFilter',
    }),
    async fetchIdeas() {
      const data = await productIdeaService.getIdeasOfUser({
        limit: this.pageSize,
        offset: this.currentPage * this.pageSize,
        mpRemoval: true,
      });

      this.setIdeaList({
        ideas: data.list,
        meta: {
          count: data.count,
          limit: data.limit,
          offset: data.offset,
        },
      });
    },
    close() {
      this.$router.push({ name: 'partnerarea.ideas' });
    },
    onEdit(idea) {
      this.$router.push({
        name: 'partnerarea.idea',
        params: {
          ideaId: idea.id,
        },
        query: {
          backName: 'GENERAL.BACK',
        },
      });
    },
    getIdeaName(idea) {
      const translation = ideaHelper.getCurrentTranslation(idea);
      return translation ? translation.name : '';
    },
    onPageChange(page) {
      this.setFilter({ page });
      this.fetchIdeas();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/animations';
@import 'src/scss/utils';

.idea-assortment-empty {
  height: 100%;

  .menu {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    h3 {
      margin: 0;
    }
  }

  .overflow-y {
    width: 100%;

    & > div {
      padding: 24px;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }

  .idea-card {
    padding: 12px;
    flex: 1 1 200px;
    min-width: 200px;
    cursor: pointer;

    & > div {
      background-color: $white;
      border-radius: $border-radius-medium;
      overflow: hidden;
    }

    .idea-card-footer {
      @extend .text-truncate;
      padding: 16px 12px;
      font-weight: bold;
    }
  }

  .image-wrapper {
    position: relative;

    .idea-image {
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
  }

  .empty-list {
    text-align: center;

    img {
      width: 250px;
      margin-top: 96px;
    }

    h2 {
      margin: 24px auto 0 auto;
    }

    p {
      margin: 16px auto 0 auto;
      color: $grey60;
    }

    p,
    h2 {
      max-width: 550px;
    }

    button {
      margin: 24px 0 0 0;
    }
  }
}
</style>

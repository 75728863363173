<template>
  <div class="composition-list-header">
    <div class="level1">
      <p class="design-text">Designs</p>
      <MarketplaceLogo v-if="isMp" class="logo-small" />
      <SpreadshopLogo v-else class="logo-small" />
      <button class="btn btn-primary icon-btn" @click="create">
        <Icon icon="plus" />
        Create New
      </button>
    </div>
    <div v-if="isMp" class="level2">
      <DmsModule condensed />
    </div>
    <div class="level3">
      <div>
        <div class="header-input-label">Status</div>
        <PublishingPosStateDropdown
          @pos-state-changed="search"
        ></PublishingPosStateDropdown>
      </div>
      <div>
        <div class="header-input-label">Search</div>
        <div class="search-field">
          <input
            type="text"
            :value="query"
            :class="{ filled: query }"
            placeholder="Design name, ID, tags"
            @input="search"
            @keyup.enter="search"
          />
          <button
            class="icon-only-btn reset-link"
            @mousedown="preventBlur"
            @click="clearSearchTerm"
          >
            <Icon icon="close" />
          </button>
          <button class="icon-only-btn search-link">
            <Icon icon="lens" />
          </button>
        </div>
      </div>
      <button
        v-if="searched"
        class="link link-main icon-link clear-all-btn"
        @mousedown="preventBlur"
        @click="clearAllFilters"
      >
        <Icon icon="close" />
        <span class="clear-all-text">Clear all</span>
      </button>
      <div v-if="searched" class="search-result-pagination-text">
        {{ searchResultPaginationText }}
      </div>
    </div>
  </div>
</template>

<script setup>
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import analytics from '@/tracking/analytics';
import { useStore } from 'vuex';
import { computed } from 'vue';
import PublishingPosStateDropdown from './PublishingPosStateDropdown.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import DmsModule from '../../dashboard/DmsModule.vue';

const emits = defineEmits(['change', 'create']);
const store = useStore();

const searched = computed(() => store.getters['compositions/searched']);
const totalItems = computed(
  () => store.getters['compositions/totalCompositionCount'],
);
const query = computed(() => store.state.compositions.filter.query);
const currentPage = computed(() => store.state.compositions.filter.page);
const pageSize = computed(() => store.state.compositions.filter.pageSize);
const isMp = computed(() => store.state.publishing.currentScope?.key === 'MP');

const searchResultPaginationText = computed(() => {
  if (totalItems.value <= pageSize.value) {
    return `Showing 1-${totalItems.value} of ${totalItems.value}`;
  }

  const startNumber = currentPage.value * pageSize.value + 1;
  const lastPage = Math.ceil(totalItems.value / pageSize.value) - 1;
  const lastItemCount = totalItems.value % pageSize.value || pageSize.value;
  const endNumber =
    currentPage.value < lastPage
      ? startNumber + pageSize.value - 1
      : startNumber + lastItemCount - 1;

  const endNumberText = startNumber === endNumber ? '' : `-${endNumber}`;
  return `Showing ${startNumber}${endNumberText} of ${totalItems.value}`;
});

const create = () => emits('create');
const updateSearchParams = (params) => {
  store.commit('compositions/setFilter', { ...params, page: 0 });
};

const search = (event) => {
  if (event) {
    const trimmedValue = event.target.value.trim();
    const newQuery = trimmedValue ? trimmedValue : null;
    if (newQuery === query.value) return;

    analytics.events.searchCompositions(newQuery);
    updateSearchParams({ query: newQuery });
  }
  emits('change');
};

const clearSearchTerm = () => {
  updateSearchParams({ query: null });
  search();
};

const clearAllFilters = () => {
  store.commit('compositions/resetFilter');
  search();
};

const preventBlur = (e) => {
  e.preventDefault();
};
</script>

<style scoped lang="scss">
@import 'src/scss/constants';

.composition-list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .level1 {
    display: flex;
    align-items: center;
    margin: 24px 0 0 0;
    gap: 24px;
    width: 100%;

    .design-text {
      color: #191919;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 125% */
      letter-spacing: -0.32px;
      margin: 0;
    }

    & > button {
      margin-left: auto;
      align-self: stretch;
      padding: 12px 16px;
      border-radius: $border-radius-medium;

      .icon {
        width: 16px;
        height: 16px;
        fill: white;
        stroke: white;
      }
    }
  }

  .level2 {
    display: flex;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
  }

  .level3 {
    display: flex;
    align-items: center;
    margin: 24px 0 24px 0;
    gap: 24px;
    width: 100%;

    .search-field {
      border-radius: $border-radius-medium;
      overflow: hidden;

      & > input {
        border-radius: $border-radius-medium;
      }
    }
  }

  .clear-all-btn {
    margin-top: 1.5rem;
  }

  .search-result-pagination-text {
    margin-left: auto;
    margin-top: auto;
    padding: 0 5px 5px 0;
  }

  .header-input-label {
    margin-bottom: 3px;
  }

  .logo-small {
    width: 125px;
    height: 20px;
    flex-shrink: 0;
  }
}
</style>

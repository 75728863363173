<template>
  <div class="copy" :class="{ inline }">
    <div class="copy-content">
      <slot></slot>
      <div class="copy-overlay" v-if="overlay && displaySuccessIndicator">
        {{ $t('GENERAL.COPIED') }}
      </div>
    </div>
    <button
      :id="id + '-btn'"
      class="btn btn-primary copy-btn btn-block"
      v-if="!inline"
      v-tooltip="tooltipOptions"
    >
      <Icon icon="copy" /> {{ $t('GENERAL.COPY') }}
    </button>
    <a
      class="link-main"
      :id="id + '-btn'"
      v-if="inline"
      v-tooltip="tooltipOptions"
    >
      <Icon icon="copy" />
    </a>
  </div>
</template>

<script>
import Clipboard from 'clipboard';

export default {
  name: 'Copyable',
  props: {
    content: {
      type: [String, Number],
    },
    id: {
      type: String,
    },
    inline: {
      type: Boolean,
    },
    overlay: {
      type: Boolean,
    },
  },
  data() {
    return {
      displaySuccessIndicator: false,
    };
  },
  computed: {
    tooltipOptions() {
      return {
        content: this.$t('GENERAL.COPIED'),
        theme: 'tooltip-dark',
        shown: !this.overlay && this.displaySuccessIndicator,
        triggers: [],
      };
    },
  },
  created() {
    const clipboard = new Clipboard(`#${this.id}-btn`, {
      text: () => this.content,
    });
    clipboard.on('success', () => {
      setTimeout(() => {
        this.displaySuccessIndicator = true;
      });
      setTimeout(() => {
        this.displaySuccessIndicator = false;
      }, 1000);
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.copy {
  display: flex;
  flex-direction: column;

  &.inline {
    display: flex;
    flex-direction: row;
    align-items: center;

    .copy-btn {
      background: none;
    }

    span {
      display: none;
    }

    .link-main {
      .icon {
        margin-left: 8px;
        font-size: 1.7em;
      }
    }
  }

  .copy-content {
    position: relative;

    .copy-overlay {
      display: flex;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background-color: $pa-color-main;
      color: #fff;
      transition: opacity 0.2s linear;
      opacity: 1;
      border-radius: $border-radius-medium;
      padding: 2px;
    }
  }

  .copy-btn {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 1.6em;
      margin-right: 8px;
    }
  }
}
</style>

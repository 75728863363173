<template>
  <div class="design-list-view">
    <div class="upload-tile">
      <a
        href="#"
        id="upload-btn"
        class="upload-button"
        @click.prevent="$emit('upload')"
      >
        <div>
          <Icon icon="plus" />
          <h4>
            {{ $t('DESIGNS.LIST.UPLOAD_DESIGN') }}
            {{ $t('DESIGNS.UPLOAD.DROP_FILES') }}
          </h4>
        </div>
        <small
          class="text-xs"
          v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"
        ></small>
      </a>
    </div>

    <ul class="idea-list">
      <li class="idea-list-heading">
        <div class="checkbox-wrapper"></div>
        <div class="design">
          {{ $t('DESIGNS.LIST.TABLE.HEADER.DESIGN') }}
        </div>
        <div class="status">{{ $t('GENERAL.STATUS') }}</div>
      </li>
      <li v-for="(idea, index) in $store.state.ideas.list" :key="idea.id">
        <IdeaListEntry
          :idea="idea"
          :displayOnboarding="index === 0"
          @toggleSelection="toggleIdeaSelection(idea)"
          @edit="$emit('edit', idea)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import IdeaListEntry from './IdeaListEntry.vue';

export default {
  name: 'IdeaListView',
  components: {
    IdeaListEntry,
  },
  computed: {
    ...mapGetters({
      filterActive: 'ideas/filterActive',
      getSetting: 'settings/getSetting',
      ideaPublished: 'onboarding/ideaPublished',
    }),
  },
  methods: {
    ...mapActions({
      toggleIdeaSelection: 'ideas/toggleIdeaSelection',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

.design-list-view {
  padding: 0 24px;
  margin-top: 24px;

  .idea-list {
    padding: 0;
    margin: 0 0 20px 0;
    list-style: none;
    border-radius: $border-radius-medium;

    & > li {
      margin-bottom: 2px;

      &.idea-list-heading {
        display: flex;
        align-items: center;
        padding: 16px 0 16px 16px;
        border-radius: $border-radius-medium $border-radius-medium 0 0;
        background-color: $white;

        .checkbox-wrapper {
          width: 36px;
        }

        .design {
          width: calc(100px + 45% + 16px);
        }
      }

      &:not(.idea-list-heading) {
        background-color: $white;

        &:last-child,
        &:last-child div {
          border-radius: 0 0 $border-radius-medium $border-radius-medium;
        }
      }
    }
  }

  .idea-list-bulk-checkbox {
    margin-right: 8px;
  }

  .upload-tile {
    padding: 0;
    position: relative;
    background-color: $white;
    margin-bottom: 24px;

    .upload-button {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 16px;
      display: flex;
      border: 2px solid $pa-color-main;
      border-radius: $border-radius-medium;

      &:hover {
        border-color: $pa-color-main-dark;
      }

      & > div {
        display: flex;
        align-items: center;

        .icon {
          width: 24px;
          height: 24px;
          color: $pa-color-main;
        }
      }

      h4 {
        margin: 0;
      }
    }

    .icon {
      margin: 0 10px 0 0;
      font-size: 1.5em;
    }

    small {
      position: relative;
      width: 100%;
      padding: 10px 0 0 0;

      :deep(a) {
        @extend .link-main;
      }
    }
  }
}
</style>

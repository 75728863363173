<template>
  <div class="credit">
    <div class="credit-list" ref="creditOverview">
      <div class="credit-overview">
        <CreditModule />
        <VolumeCommissionModule />
      </div>
      <div class="credit-details">
        <StatisticsFilters
          heading="STATISTICS.CREDIT_DETAILS.HEADING"
          subHeading="STATISTICS.CREDIT_DETAILS.SUBHEADING"
          @filterUpdate="onFilterUpdate"
          displayCommissionTypeFilter
          displayPosFilter
          displayDateFromFilter
          displayDateToFilter
          flushBottom
        />
        <img
          class="sprd-loader loader"
          v-if="!creditDetails"
          src="/images/loader.svg"
        />
        <div class="list-content" v-if="creditDetails">
          <table class="credit-table" v-if="creditDetails.length">
            <thead>
              <tr>
                <th colspan="2">
                  {{ $t('STATISTICS.CREDIT_DETAILS.ENTRY') }}
                </th>
                <th>{{ $t('STATISTICS.ORDER_NUMBER') }}</th>
                <th>{{ $t('STATISTICS.CREDIT_DETAILS.DATE') }}</th>
                <th>{{ $t('STATISTICS.CREDIT_DETAILS.POS') }}</th>
                <th class="amount">
                  {{ $t('STATISTICS.CREDIT_DETAILS.AMOUNT') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="credit in creditDetails"
                :key="credit.id"
                @click.prevent="openCreditDetails(credit.id)"
                :class="{
                  positive: credit.payoutAmount >= 0,
                  cancel:
                    credit.payoutAmount < 0 &&
                    credit.commissionType !== commissionTypes.PAYOUT,
                  payout: credit.commissionType === commissionTypes.PAYOUT,
                }"
              >
                <td class="symbol">
                  <Icon :icon="credit.$data.icon"></Icon>
                </td>
                <td>{{ credit.$data.entry }}</td>
                <td>{{ credit.$data.orderId }}</td>
                <td>{{ credit.$data.entryDate }}</td>
                <td>{{ credit.$data.pointOfSale }}</td>
                <td class="amount">{{ credit.$data.credit }}</td>
              </tr>
              <tr class="pagination-row">
                <td colspan="6">
                  <div v-if="creditDetails.length" class="pagination-wrapper">
                    <div class="export" v-if="metaData.count > 0">
                      <a
                        :href="downloadLink"
                        class="link-main"
                        download="credit.csv"
                        target="_self"
                      >
                        <Icon icon="download-file"></Icon>
                        {{ $t('STATISTICS.DOWNLOAD_CSV') }}
                      </a>
                    </div>
                    <Pagination
                      v-if="metaData.count > pagination.pageSize"
                      :total="metaData.count"
                      :pageSize="pagination.pageSize"
                      :currentPage="pagination.currentPage"
                      addStateParam
                      @pageChange="onPageChange"
                    ></Pagination>
                    <div class="scroll" v-if="creditDetails.length > 10">
                      <a
                        href="#"
                        @click.prevent="scrollToTop"
                        class="link-main"
                      >
                        <Icon class="rotate-icon-270" icon="arrow-end"></Icon>
                        {{ $t('STATISTICS.TO_TOP') }}
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="empty-message" v-if="!creditDetails.length">
            <Icon icon="x"></Icon>
            <strong>{{ $t('STATISTICS.CREDIT_DETAILS.NO_RESULTS') }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import parseISO from 'date-fns/parseISO';

import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import date from 'src/app/commons/date/date';
import Pagination from 'src/app/commons/pagination/Pagination.vue';
import CreditModule from 'src/app/partnerarea/dashboard/CreditModule.vue';
import VolumeCommissionModule from 'src/app/partnerarea/dashboard/VolumeCommissionModule.vue';
import { pointOfSaleName } from '@/api/models/pointsOfSale/pointsOfSale';
import StatisticsFilters from '../StatisticsFilters.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import CreditDetailsDialog from 'src/app/partnerarea/statistics/credit/details/CreditDetailsDialog.vue';

export default {
  name: 'Credit',
  components: {
    Pagination,
    CreditModule,
    VolumeCommissionModule,
    StatisticsFilters,
  },
  data() {
    return {
      pagination: {
        pageSize: 20,
        currentPage: 0,
        maxSize: 5,
      },
      filters: null,
      metaData: {
        count: 0,
        limit: 0,
        offset: 0,
        listSize: 0,
      },
      creditDetails: null,
      downloadLink: null,
      commissionTypes: statisticsService.getCommissionTypes(),
    };
  },
  created() {
    if (this.$route.query.page && this.$route.query.page >= 1) {
      this.pagination.currentPage = this.$route.query.page - 1;
    }
  },
  methods: {
    async fetchCreditDetails() {
      this.creditDetails = null;

      const data = await statisticsService.getCreditDetails(
        this.getOptions({ pagination: true })
      );

      this.metaData = {
        count: data.count,
        limit: data.limit,
        offset: data.offset,
        listSize: data.list.length,
      };

      this.prepareCreditDetails(data);

      this.creditDetails = data.list;
      this.downloadLink = statisticsService.getCreditDetailsDownloadUrl(
        this.getOptions()
      );

      if (!this.creditDetails.length && this.pagination.currentPage > 0) {
        this.onPageChange(0);
      }
    },
    prepareCreditDetails(data) {
      data.list.forEach((credit) => {
        const entryKey = Object.keys(this.commissionTypes).find(
          (key) => this.commissionTypes[key] === credit.commissionType
        );
        const entry = this.$t(
          `STATISTICS.CREDIT_DETAILS.ENTRY_TYPE.${entryKey}`
        );
        credit.$data = {
          entry,
          orderId:
            credit.commissionType !== this.commissionTypes.VOLUME_COMMISSION
              ? credit.orderId
              : null,
          entryDate: date(credit.dateBooked, 'shortDate'),
          pointOfSale:
            credit.commissionType !== this.commissionTypes.VOLUME_COMMISSION
              ? pointOfSaleName(credit)
              : null,
          credit: `${credit.payoutAmount > 0 ? '+' : ''}${this.sprdCurrency(
            credit.commissionType === this.commissionTypes.PAYOUT
              ? Math.abs(credit.payoutAmount)
              : credit.payoutAmount,
            credit.currencyId
          )}`,
          icon: this.getIcon(credit),
        };
      });
    },
    getOptions(opts = {}) {
      const options = {
        dateFrom: this.filters.dateFrom
          ? startOfDay(parseISO(this.filters.dateFrom))
          : null,
        dateTo: this.filters.dateTo
          ? endOfDay(parseISO(this.filters.dateTo))
          : null,
        pos: this.filters.pointOfSale.value,
        commissionType: this.filters.commissionType.value,
      };

      if (opts.pagination) {
        options.limit = this.pagination.pageSize;
        options.offset = this.pagination.currentPage;
      }

      return options;
    },
    onFilterUpdate(filters, { initial }) {
      this.filters = filters;

      if (!initial) {
        this.pagination.currentPage = 0;
      }
      this.fetchCreditDetails();
    },
    onPageChange(newPage) {
      this.pagination.currentPage = newPage;
      this.fetchCreditDetails();
    },
    scrollToTop() {
      this.$refs.creditOverview.scrollIntoView({ behavior: 'smooth' });
    },
    openCreditDetails(id) {
      dialogService.openDialog(CreditDetailsDialog, { id });
    },
    getIcon(credit) {
      if (credit.payoutDate) {
        return 'pig';
      } else if (credit.payoutAmount >= 0) {
        return 'statsup';
      } else if (credit.payoutAmount < 0) {
        return 'statsdown';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.credit {
  width: 100%;
  margin: 0 auto;

  .credit-overview {
    display: flex;
    flex-wrap: wrap;
    margin-right: -24px;

    & > div {
      display: flex;
      width: auto;
      margin-right: 24px;
      flex-grow: 1;
      margin-bottom: 24px;
    }
  }

  .credit-list {
    max-width: 1400px;
  }

  .credit-details {
    .filters {
      .dropdown {
        min-width: 200px;
        margin-right: 10px;
      }
    }

    .list-heading {
      margin-bottom: 0;
      border-radius: $border-radius-medium $border-radius-medium 0 0;

      .heading-text {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .loader {
      width: 100%;
      height: 120px;
      margin-top: 48px;
    }

    .credit-table {
      width: 100%;
      background-color: white;

      th {
        border-bottom: 5px solid $grey5;
        background-color: #fff;
        padding: 0 15px 10px 15px;
        color: $sprd-color-dark-grey;
        cursor: auto;
      }

      td {
        padding: 0 15px;
        height: 70px;

        .icon {
          font-size: 2em;
        }

        &.amount {
          @extend .heading4;
          margin: 0;
        }
      }

      td,
      th {
        &.amount {
          text-align: right;
        }

        &.symbol {
          width: 60px;
        }
      }

      tr {
        &.positive {
          .amount,
          .icon {
            color: $pa-color-green;
          }
        }

        &.cancel {
          .amount,
          .icon {
            color: $pa-color-red;
          }
        }

        &.payout {
          color: $grey65;
        }

        &:nth-child(even) {
          background-color: #f8f8f8;
        }

        &:last-child td {
          border: none;
        }

        &.pagination-row {
          border-radius: 0 0 4px 4px;

          td {
            padding: 0;
          }
        }
      }

      tbody tr {
        &:not(.pagination-row) {
          cursor: pointer;

          &:hover {
            transform: scale(1);
            box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.24);
          }
        }
      }
    }
  }

  .pagination-wrapper {
    margin-top: 0;
    border: none;
  }
}
</style>

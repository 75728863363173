<template>
  <div class="publishing-meta-data">
    <ConfigList>
      <ul>
        <li>
          <div>
            {{
              $t('DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT')
            }}
          </div>
          <button
            class="link language-link"
            @click="openDefaultTranslationDialog"
          >
            {{ languageName }} <Icon icon="right" />
          </button>
        </li>
        <li>
          <div>
            {{
              $t(
                'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATED'
              )
            }}
          </div>
          <ToggleSwitch
            :model-value="composition.metaData.autoTranslate"
            @update:model-value="
              (autoTranslate) => setAutoTranslate({ autoTranslate })
            "
          />
        </li>
        <MpTransformerModeControl
          class="internal-feature"
          :mpTransformerMode="
            composition.publishingDetails.d2c.mpTransformerMode
          "
          @setMpTransformerMode="setMpTransformerMode"
          style="margin-left: auto"
        />
        <li>
          <div class="meta-data-content">
            <div>
              <LabelInput :class="{ 'has-error': nameError }">
                <label for="name">
                  {{ $t('PUBLISHING.DETAIL.META_DATA.NAME.LABEL') }}
                </label>
                <DebounceInput
                  type="text"
                  id="name"
                  :model-value="metaData.name.value"
                  @update:model-value="changeName"
                  @change="
                    (evt) => checkBlacklistForField('name', evt.target.value)
                  "
                  :maxlength="validatorOptions.name.max"
                />
                <div class="field-info">
                  <small class="error-info error-info-name" v-if="!validName">
                    {{
                      $t('DESIGNS.VALIDATION.NAME.LENGTH', {
                        min: validatorOptions.name.min,
                        max: validatorOptions.name.max,
                      })
                    }}
                  </small>
                  <small
                    class="error-info error-info-name"
                    v-else-if="blacklist.name.length"
                  >
                    {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
                    {{ blacklist.name.join(', ') }}
                  </small>
                  <small v-else class="info">
                    {{ $t('PUBLISHING.DETAIL.META_DATA.NAME.DESCRIPTION') }}
                  </small>

                  <small class="char-info design-description-chars-left"
                    >{{ metaData.name.value.length || 0 }} /
                    {{ validatorOptions.name.max }}</small
                  >
                </div>
              </LabelInput>
            </div>
            <div>
              <LabelInput
                :class="{
                  'has-error': descriptionError,
                }"
              >
                <label for="description">{{
                  $t('PUBLISHING.DETAIL.META_DATA.DESCRIPTION.LABEL')
                }}</label>
                <DebounceTextarea
                  name="description"
                  :maxlength="200"
                  :model-value="metaData.description.value"
                  @update:model-value="changeDescription"
                  @change="
                    (evt) =>
                      checkBlacklistForField('description', evt.target.value)
                  "
                  rows="3"
                  spellcheck="true"
                />
                <div class="field-info">
                  <small
                    class="error-info error-info-description"
                    v-if="blacklist.description.length"
                  >
                    {{ $t('DESIGNS.VALIDATION.BLACKLIST.TERMS') }}:
                    {{ blacklist.description.join(', ') }}
                  </small>
                  <small v-else class="info">
                    {{
                      $t('PUBLISHING.DETAIL.META_DATA.DESCRIPTION.DESCRIPTION')
                    }}
                  </small>
                  <small class="char-info design-description-chars-left"
                    >{{ metaData.description.value.length || 0 }} / 1500</small
                  >
                </div>
              </LabelInput>
            </div>
            <div>
              <CompositionTagInput
                :tags="metaData.tags.values"
                :composition="composition"
                @addTag="onAddTag"
                @removeTag="onRemoveTag"
                :displayTagsLeft="true"
                :displayRelatedTags="false"
                :displayErrorText="true"
                :language="currentLocale"
                :displayHint="true"
                :validate="validate"
                labelText="PUBLISHING.DETAIL.META_DATA.TAGS.LABEL"
                hintText="PUBLISHING.DETAIL.META_DATA.TAGS.DESCRIPTION"
                ref="tagInput"
              />
            </div>
          </div>
        </li>
      </ul>
    </ConfigList>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import ConfigList from 'src/app/components/publishing/ConfigList.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import compositionHelper from '@/composition/compositionHelper';
import CompositionTagInput from '@/tagInput/CompositionTagInput.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import PublishingDefaultTranslationDialog from './PublishingDefaultTranslationDialog.vue';
import MpTransformerModeControl from 'src/app/components/publishing/MpTransformerModeControl.vue';
import compositionValidator from '@/api/validators/composition/compositionValidator';
import { checkTerm } from '@/api/blacklistService/blacklistService';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';
import DebounceTextarea from 'src/app/components/input/DebounceTextarea.vue';

export default {
  name: 'PublishingMetaData',
  components: {
    ToggleSwitch,
    ConfigList,
    LabelInput,
    CompositionTagInput,
    MpTransformerModeControl,
    DebounceInput,
    DebounceTextarea,
  },
  props: {
    validate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validatorOptions: compositionValidator.getDefaultOptions(),
      validation: {
        errors: {},
      },
      blacklist: {
        name: [],
        description: [],
      },
    };
  },
  created() {
    this.checkBlacklistForField('name', this.metaData.name.value);
    this.checkBlacklistForField('description', this.metaData.description.value);
  },
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
    ...mapGetters({
      languageByIso: 'platform/languageByIso',
    }),
    metaData() {
      return compositionHelper.getDefaultMetaData(this.composition);
    },
    currentLocale() {
      return this.composition.metaData.originLanguageCode;
    },
    languageName() {
      return this.languageByIso(this.composition.metaData.originLanguageCode)
        ?.name;
    },
    validName() {
      if (!this.validate) {
        return true;
      }

      return compositionValidator.validateName(this.composition, {
        locale: this.currentLocale,
      });
    },
    nameError() {
      return !this.validName || this.blacklist.name.length;
    },
    validDescription() {
      if (!this.validate) {
        return true;
      }

      return compositionValidator.validateDescription(this.composition, {
        locale: this.currentLocale,
      });
    },
    descriptionError() {
      return !this.validDescription || this.blacklist.description.length;
    },
    hasError() {
      return (
        this.nameError || this.descriptionError || this.$refs.tagInput.hasError
      );
    },
  },
  methods: {
    ...mapMutations({
      setName: 'composition/setName',
      setDescription: 'composition/setDescription',
      addTag: 'composition/addTag',
      removeTag: 'composition/removeTag',
      setAutoTranslate: 'composition/setAutoTranslate',
      setMpTransformerMode: 'composition/setMpTransformerMode',
    }),
    ...mapActions({}),
    changeName(name) {
      this.setName({ name });
    },
    changeDescription(description) {
      this.setDescription({ description });
    },
    onAddTag(tag) {
      this.addTag({ tag });
    },
    onRemoveTag(tag) {
      this.removeTag({ tag });
    },
    openDefaultTranslationDialog() {
      dialogService.openDialog(PublishingDefaultTranslationDialog);
    },
    async checkBlacklistForField(field, terms) {
      const locale = this.currentLocale;

      try {
        await checkTerm({
          field,
          terms,
          locale,
        });
        this.blacklist[field] = [];
      } catch (error) {
        if (error?.data?.list) {
          this.blacklist[field] = error.data.list[0].blacklisted.reduce(
            (result, blacklist) => result.concat(blacklist.terms),
            []
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.meta-data-content {
  width: 100%;

  & > div {
    padding: 16px 0 8px 0;
  }
}

.language-link {
  font-weight: normal;
  color: $grey80;
  .icon {
    margin-left: 8px;
  }
}

.internal-feature {
  padding: 16px 0 0 0;
}
</style>

<template>
  <div class="image-switcher" v-if="images.length">
    <SaleItemImage
      class="image"
      :image="images[currentImage].url"
      :type="images[currentImage].type"
      :width="width"
      :height="width"
      :requestProps="requestProps"
      :designId="designId"
    />
    <div class="dot-wrapper" v-if="images.length > 1">
      <div
        class="dot"
        v-for="(image, index) in images"
        :key="index"
        @click="currentImage = index"
      >
        <span :class="{ selected: isSelected(index) }"></span>
      </div>
    </div>
  </div>
</template>

<script>
import SaleItemImage from 'src/app/partnerarea/statistics/sales/saleImage/SaleItemImage.vue';

export default {
  name: 'SaleImageSwitcher',
  components: {
    SaleItemImage,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
    },
    width: {
      type: Number,
    },
    requestProps: {
      type: Object,
    },
    designId: {
      type: Number,
    },
  },
  data() {
    return {
      currentImage: 0,
    };
  },
  methods: {
    isSelected(index) {
      return this.currentImage === index;
    },
    imageLoaded() {
      this.$emit('image-loaded');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.image-switcher {
  margin-right: 24px;

  .image {
    background-color: $grey5;
    border-radius: $border-radius-medium;
  }

  :deep(.design-checked-background) {
    border-radius: 0px;
  }
}

.dot-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dot {
  cursor: pointer;
  margin: 6px 3px;
  padding: 1px;

  span {
    height: 8px;
    width: 8px;
    background-color: $grey40;
    border-radius: $border-radius-medium;
    display: inline-block;
    transition: background-color 0.3s ease-out, width 0.3s ease;

    &.selected {
      width: 32px;
      background-color: $pa-color-main;
    }

    &:hover,
    &:focus {
      background-color: $pa-color-main-dark;
    }
  }
}
</style>

<template>
  <div class="shop-status-page">
    <ToggleButton
      :items="shopStates"
      :itemKey="(state) => state.value"
      :value="selectedShopState"
      @change="setShopState"
      id="shop-activation"
    />

    <button
      type="button"
      class="delete-shop-btn btn-block icon-btn link-red"
      @click="deleteShop"
    >
      <Icon class="delete-shop-icon" icon="bin" />
      <span>{{ $t('SA.shopStatus.deleteShop') }}</span>
    </button>
  </div>
</template>

<script>
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import shopService from 'src/app/commons/api/shopService/shopService';

import ToggleButton from 'src/app/commons/ToggleButton/ToggleButton.vue';
import { ActivationMixin } from '../activation/ActivationMixin';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { mapActions } from 'vuex';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'ShopStatus',
  mixins: [ActivationMixin, SettingsData],
  components: {
    ToggleButton,
  },
  created() {
    this.$store.commit('shop/setLivePreviewVisible', true);
  },
  data() {
    return {
      shopStates: [
        {
          text: 'POS.SHOP_SETTINGS.SHOPSTATUS.ACTIVE',
          value: true,
          icon: 'rocket',
        },
        {
          text: 'POS.SHOP_SETTINGS.SHOPSTATUS.DISABLED',
          value: false,
          icon: 'rocket-crashed',
        },
      ],
    };
  },
  computed: {
    isActive: {
      get() {
        return !this.settings.hidden;
      },
      set() {
        this.toggleShopStatus();
      },
    },
    selectedShopState() {
      return this.shopStates.find((state) => state.value === this.isActive);
    },
  },
  methods: {
    ...mapActions({
      fetchShops: 'user/fetchShops',
      fetchPointsOfSale: 'user/fetchPointsOfSale',
    }),
    deleteShop() {
      const shopId = this.$store.state.shop.currentShop.id;
      return shopAPI.getShopConfigs(this.$store, shopId).then((config) => {
        dialogService
          .openDialog(ConfirmDialog, {
            heading: 'SA.shopStatus.deleteDialog.title',
            description: this.$t('SA.shopStatus.deleteDialog.description', {
              shopId,
              shopName: config.shopUrlName || '',
            }),
            cancel: 'SA.action.cancel',
            confirm: 'SA.action.delete',
            danger: true,
          })
          .then(() => {
            shopService.delete(shopId).then(() => {
              this.$router.push({ name: 'partnerarea.dashboard' });
              this.fetchShops();
              this.fetchPointsOfSale();
            });
          });
      });
    },
    setShopState(state) {
      this.isActive = state.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';

.shop-status-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  div {
    :deep(.item) {
      margin: 0;
      padding: 12px;
    }

    :deep(.item + .item) {
      margin-left: 16px;
    }
    :deep(.icon) {
      height: 35px;
      width: 35px;
    }
  }
  .delete-shop-btn {
    margin-top: auto;
    padding: 10px 40px;
    border: 4px;
    border-radius: $border-radius-medium;
  }
  .delete-shop-icon {
    height: 20px;
    width: 17px;
  }
}
</style>

import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';

const userCoreService = {
  async getNewsletterData() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/user-core/users/${store.state.user.data.id}/newsletter`,
    });
    return data;
  },
  async updateNewsletterData(newsletterData) {
    const { data } = await apiClient.request({
      method: 'PUT',
      url: `/user-core/users/${store.state.user.data.id}/newsletter`,
      data: newsletterData,
    });
    return data;
  },
  async getPaymentInfo() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/user-core/users/${store.state.user.data.id}/payment-info`,
    });
    return data;
  },
  async updatePaymentInfo(paymentInfoData) {
    const { data } = await apiClient.request({
      method: 'PUT',
      url: `/user-core/users/${store.state.user.data.id}/payment-info`,
      data: paymentInfoData,
    });
    return data;
  },
  async getTaxationData() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/user-core/users/${store.state.user.data.id}/taxation`,
    });
    return data;
  },
  async updateTaxationData(taxationData) {
    const { data } = await apiClient.request({
      method: 'POST',
      url: `/user-core/users/${store.state.user.data.id}/taxation`,
      data: taxationData,
    });
    return data;
  },
  async getTrolleyIFrame() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/user-core/users/${store.state.user.data.id}/trolley/iframe-url`,
    });

    if (!data?.url) {
      return Promise.reject();
    }

    return data?.url;
  },
  async getTrolleyStatus() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/user-core/users/${store.state.user.data.id}/trolley/status`,
    });
    return data;
  },
  async sendTrolleyEvent({ module, event }) {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/trolley/${store.state.user.data.id}/modules/${module}/events/${event}`,
    });
    return data;
  },
};

export default userCoreService;

<template>
  <div class="model-images">
    <ToggleSwitch
      class="sprd-toggle-switch--first"
      label="POS.SHOP_SETTINGS.GENERAL.MODELIMGS.MODEL_IMAGES"
      v-model="settings.modelImagesEnabled"
      id="model-images"
    />

    <p class="models__title">
      {{ $t('POS.SHOP_SETTINGS.GENERAL.MODELIMGS.CHOOSE_MODEL') }}
    </p>
    <div class="models">
      <div
        v-for="preview in previews"
        :key="preview.personId"
        class="model"
        :class="{ 'model--checked': preview.activated }"
      >
        <img
          class="model__img"
          :alt="preview.personId"
          :src="preview.imageUrl"
          @click="onModelClick(preview.personId)"
        />
      </div>
      <div
        class="model__placeholder"
        v-for="placeholder in placeholders"
        :key="placeholder"
      ></div>
    </div>
  </div>
</template>

<script>
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';

export default {
  name: 'ModelImages',
  mixins: [SettingsData],
  components: {
    ToggleSwitch,
  },
  data() {
    return {
      previews: [],
    };
  },
  created() {
    this.$store.commit('shop/setLivePreview', { path: 'all' });
    shopAPI
      .getModelPersonPreview(this.$store)
      .then((payload) => {
        this.previews = payload.previews;
      })
      .catch((e) => console.error(e));
  },
  computed: {
    placeholders() {
      const lastRow = this.previews.length % 3;
      return Array.from(
        { length: lastRow ? 3 - lastRow : 0 },
        (_, index) => `ph${index}`
      );
    },
  },
  methods: {
    onModelClick(personId) {
      const model = this.previews.find((p) => p.personId === personId);
      model.activated = !model.activated;

      this.settings.modelPersonBlacklist = this.previews
        .filter((p) => !p.activated)
        .map((p) => p.personId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.models {
  display: flex;
  flex-wrap: wrap;
  line-height: 0;
  margin-left: -15px;
  padding-bottom: 5px;
}

.model,
.model__placeholder {
  flex: 1 1 0;
  min-width: 25%;
  margin: 0 0 15px 15px;
}

.model {
  position: relative;
  border-radius: $border-radius-medium;
  box-shadow: 0 0 0 1px $grey20;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.model__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.model--checked {
  box-shadow: 0 0 0 2px $pa-color-main;
}
</style>

<template>
  <div ref="rootRef" class="dropdown" :class="{ disabled: props.disabled }">
    <slot
      name="dropdown-button"
      :item="selectedItem"
      :toggleMenu="toggleMenu"
      :onKeyDown="onKeyDown"
      :isOpen="isOpen"
      :reset="reset"
      :showMenu="showMenu"
      :buttonId="buttonId"
    >
      <label
        v-if="props.label"
        class="dropdown-button-label"
        :class="{ filled: Boolean(selectedItem) }"
        >{{ props.label }}</label
      >
      <div
        class="dropdown-button"
        tabindex="0"
        :id="buttonId"
        @click.prevent="toggleMenu()"
        @keydown.prevent="onKeyDown"
        :aria-expanded="isOpen"
        role="button"
        aria-haspopup="true"
        :data-value="selectedItem ? props.itemKey(selectedItem) : ''"
      >
        <div
          v-if="selectedItem?.color"
          class="dropdown-item-icon"
          :style="{ color: selectedItem.color }"
        >
          <Icon :icon="selectedItem?.icon || 'dot'" />
        </div>
        <span class="selected-item-text">{{
          selectedItem
            ? selectedItemText(selectedItem)
            : props.placeholder || ''
        }}</span>
        <Icon
          icon="chevron"
          class="dropdown-chevron-icon"
          :class="{ open: isOpen }"
        ></Icon>
      </div>
    </slot>

    <slot
      name="items"
      :highlightedIndex="highlightedIndex"
      :selectItem="selectItem"
      :itemMove="onItemMouseMove"
      :isOpen="isOpen"
      :direction="props.direction"
      :reset="reset"
    >
      <ul
        class="dropdown-items no-scrollbar"
        :class="{ open: isOpen, up: props.direction === 'up' }"
        role="menu"
        :aria-labelledby="buttonId"
        v-if="props.items && props.items.length && props.items[0]"
      >
        <li
          v-for="(item, index) in visibleItems"
          class="item"
          role="menuitem"
          :aria-selected="isSelectedItem(item)"
          :class="{ active: highlightedIndex === index }"
          :key="index"
          @click="selectItem(item)"
          @mousemove="onItemMouseMove($event, index)"
        >
          <div
            v-if="item?.color"
            class="dropdown-item-icon"
            :style="{ color: item.color }"
          >
            <Icon :icon="item?.icon || 'dot'" />
          </div>
          {{ props.itemToString(item) }}
          <Icon
            class="item-checkmark"
            v-if="isSelectedItem(item)"
            icon="checkmark"
          />
        </li>
        <li
          v-if="$slots['bottom-content']"
          class="item bottom-content"
          role="menuitem"
        >
          <slot name="bottom-content"></slot>
        </li>
      </ul>
    </slot>
  </div>
</template>

<script setup>
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { genId, isEqual, isOrContainsNode } from 'src/app/commons/utils';
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
/** TODO:
  - focus trap?
**/

function scrollIntoView(node, rootNode) {
  if (node === null) {
    return;
  }

  scrollIntoViewIfNeeded(node, {
    boundary: rootNode,
    block: 'nearest',
    scrollMode: 'if-needed',
    behavior: 'instant',
  });
}

function normalizeArrowKey(event) {
  const { key, keyCode } = event;
  if (keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0) {
    return `Arrow${key}`;
  }
  return key;
}

const emits = defineEmits(['change']);

const props = defineProps({
  items: {
    type: Array,
    required: false,
    default: () => [],
  },
  itemToString: {
    type: Function,
    required: false,
    default: (item) => item,
  },
  selectedItemToString: {
    type: Function,
    required: false,
  },
  value: {
    required: false,
  },
  itemKey: {
    type: Function,
    required: false,
    default: (item) => item,
  },
  direction: {
    type: String,
    required: false,
    default: 'down',
    validator: function (value) {
      return ['up', 'down'].indexOf(value) !== -1;
    },
  },
  label: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
});

const rootRef = ref(null);
const isOpen = ref(null);
const selectedItem = ref(null);
const isMouseDown = ref(false);
const highlightedIndex = ref(null);
const buttonId = ref(genId('button'));
const searchValue = ref('');
const resetSearchTimeoutId = ref(null);

const visibleItems = computed(() => (isOpen.value ? props.items : []));

watch(
  () => props.value,
  (newValue) => {
    if (selectedItem.value !== newValue) {
      selectedItem.value = newValue;
    }
  }
);

onBeforeMount(() => {
  if (props.value) {
    selectedItem.value = props.value;
  }
  document.addEventListener('mousedown', mouseDown);
  document.addEventListener('mouseup', mouseUp);
});

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', mouseDown);
  document.removeEventListener('mouseup', mouseUp);
});

const toggleMenu = () => {
  if (isOpen.value) {
    reset();
  } else {
    showMenu();
  }
};
const selectedItemText = (item) => {
  return props.selectedItemToString
    ? props.selectedItemToString(item)
    : props.itemToString(item);
};
const showMenu = () => {
  if (selectedItem.value) {
    const index = props.items.indexOf(selectedItem);
    highlightedIndex.value = index >= 0 ? index : 0;
  } else {
    highlightedIndex.value = 0;
  }
  isOpen.value = true;
};
const selectItem = (item) => {
  selectedItem.value = item;
  emits('change', item);
  reset();
};
const isSelectedItem = (item) => {
  // Return null instead of false here for aria-selected
  return isEqual(item, selectedItem.value) || null;
};
const mouseDown = () => {
  isMouseDown.value = true;
};
const mouseUp = (event) => {
  isMouseDown.value = false;
  if (isOpen.value && !isOrContainsNode(rootRef.value, event.target)) {
    reset();
  }
};
const onItemMouseMove = (event, index) => {
  if (highlightedIndex.value === index) {
    return;
  }
  highlightedIndex.value = index;
};
const onKeyDown = (event) => {
  const key = normalizeArrowKey(event);
  switch (key) {
    case 'ArrowUp':
      moveHighlightedIndex(-1);
      break;
    case 'ArrowDown':
      if (isOpen.value) {
        moveHighlightedIndex(1);
      } else {
        showMenu();
      }
      break;
    case 'Enter':
      if (highlightedIndex.value >= 0 && isOpen) {
        const itemToSelect = props.items[highlightedIndex.value];
        selectItem(itemToSelect);
      }
      break;
    case 'Escape':
      if (isOpen.value) {
        reset();
      }
      break;
    default: {
      if (
        !/[a-zA-Z0-9]/.test(String.fromCharCode(event.keyCode)) ||
        !event.key
      ) {
        return;
      }

      resetSearchTimeout();

      resetSearchTimeoutId.value = setTimeout(() => {
        searchValue.value = '';
        resetSearchTimeoutId.value = null;
      }, 250);

      searchValue.value += event.key.toLowerCase();
      const index = props.items.findIndex((item) =>
        props.itemToString(item).toLowerCase().startsWith(searchValue.value)
      );

      if (index >= 0) {
        setHighlightedIndex(index);
      }
      break;
    }
  }
};
const moveHighlightedIndex = (moveAmount) => {
  const itemsLastIndex = props.items.length - 1;
  if (itemsLastIndex < 0) {
    return;
  }

  let baseIndex = highlightedIndex;
  if (baseIndex.value === null) {
    baseIndex.value = moveAmount > 0 ? -1 : itemsLastIndex + 1;
  }
  let newIndex = baseIndex.value + moveAmount;
  if (newIndex < 0) {
    newIndex = itemsLastIndex;
  } else if (newIndex > itemsLastIndex) {
    newIndex = 0;
  }

  setHighlightedIndex(newIndex);
};
const setHighlightedIndex = (index) => {
  highlightedIndex.value = index;
  setTimeout(() => {
    scrollIntoView(rootRef.value.querySelector('.active'), rootRef.value);
  }, 100);
};
const reset = () => {
  isOpen.value = null;
  highlightedIndex.value = null;
  resetSearchTimeout();
};
const resetSearchTimeout = () => {
  if (resetSearchTimeoutId.value) {
    clearTimeout(resetSearchTimeoutId);
    resetSearchTimeoutId.value = null;
  }
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/inputs';

.dropdown {
  position: relative;
  color: $sprd-color-darkest-grey;
}

.dropdown-item-icon {
  display: flex;
  align-items: center;
  margin-right: 7px;
  height: 10px;
  width: 10px;
}

.dropdown-button {
  @include input-states();

  display: flex;
  align-items: center;

  border: 1px solid $sprd-color-medium-grey;
  border-radius: $border-radius-medium;
  background-color: white;
  padding: 12px 16px;
  height: 40px;
  outline: none;

  cursor: pointer;

  .selected-item-text {
    margin-right: 8px;
  }

  .dropdown-chevron-icon {
    margin-left: auto;
    transform: rotate(90deg);

    &.open {
      transform: rotate(270deg);
    }
  }
}

.dropdown-button-label {
  margin: 0;
  position: absolute;
  top: 25%;
  left: 10px; //12px - 4px padding
  transition: top 0.2s linear;
  z-index: 2;
  color: $sprd-color-darkest-grey;
  pointer-events: none;
  padding: 0 4px;

  &:before {
    content: ' ';
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: -1;
    left: 0;
    top: 50%;
  }

  &.filled {
    top: -25%;
  }
}

.dropdown-items {
  display: none;
  list-style: none;
  z-index: 99;
  white-space: nowrap;

  border: 1px solid $sprd-color-light-grey;
  border-radius: $border-radius-medium;
  background-color: white;
  box-shadow: 1px 2px 6px 1px rgba(25, 25, 25, 0.25);

  padding: 6px 0;
  margin-top: 8px;
  position: absolute;
  min-width: 100%;
  width: auto;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;

  &.open {
    display: block;
  }

  &.up {
    left: 0;
    bottom: 100%;
    margin-bottom: auto;
    border: 1px solid $sprd-color-medium-grey;
    border-bottom: none;
  }

  .item {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    background-color: white;
    text-align: left;
    cursor: pointer;

    &.active {
      background-color: $sprd-color-lightest-grey;
    }

    .item-checkmark {
      margin-left: auto;
      padding-left: 8px;
      height: 24px;
      width: 24px;
    }

    &.bottom-content {
      border-top: 1px solid $sprd-color-light-grey;
    }
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dropdown.disabled {
  .dropdown-button-label {
    color: $grey20;
  }

  .dropdown-button {
    pointer-events: none;
    cursor: not-allowed;
    border-color: $grey20;
    color: $grey20;
  }
}
</style>

<template>
  <div class="config-section" :class="{ displayInline }">
    <div class="header">
      <h1>{{ $t(heading) }}</h1>
      <p v-if="subheading">{{ $t(subheading) }}</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ConfigSection',
  props: {
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
    },
    displayInline: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.config-section {
  .header {
    margin-bottom: 24px;

    h1 {
      margin: 0;
    }

    p {
      margin: 8px 0 0 0;
      color: $grey65;
    }
  }

  &:not(.displayInline) {
    background-color: $grey0;
    border: 1px solid $grey10;
    border-radius: $border-radius-medium;
    margin-bottom: 24px;
    padding: 24px;
    position: relative;
  }
}
</style>

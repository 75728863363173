<template>
  <div class="notification-groups">
    <div v-for="group in filteredGroups" :key="group.heading" class="group">
      <h3 key="heading">
        {{ $t(group.heading) }}
      </h3>
      <TransitionGroup name="fade" tag="ul">
        <li
          v-for="notification in group.list"
          :key="notification.notificationId"
        >
          <NotificationItem :notification="notification" enableHighlighting />
        </li>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import NotificationItem from './NotificationItem.vue';

export default {
  name: 'NotificationGroups',
  components: { NotificationItem },
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredGroups() {
      return this.groups.filter((e) => e.list && e.list.length);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';

.notification-groups {
  margin: 24px;

  .group + .group {
    margin-top: 8px;
  }
}

h3 {
  margin: 0 0 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: $border-radius-medium;
}
</style>

<template>
  <div class="checklist" :class="{ compact }">
    <ul v-if="initialized">
      <li
        v-for="(step, index) in steps"
        :key="step.key"
        @click="onStepClicked(step)"
        :class="{
          done: step.done,
          next: step === nextStep,
          animate: step.key === animateStep,
        }"
      >
        <div class="circle">
          <div class="checkmark">
            <Icon icon="checkmark" />
          </div>
        </div>
        <p class="step-number">{{ index + 1 }}.</p>
        <p>{{ $t(step.name) }}</p>
      </li>
    </ul>
    <div v-else class="loader">
      <img src="/images/loader.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'OnboardingChecklist',
  components: {},
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    animateStep: {
      type: String,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onStepClicked(step) {
      if (step.done) {
        return;
      }

      step.onClick();
    },
  },
  computed: {
    ...mapState({
      initialized: (s) => s.onboarding.initialized,
    }),
    ...mapGetters({
      steps: 'onboarding/relevantSteps',
    }),
    nextStep() {
      return this.steps.find((step) => !step.done);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';
@import 'src/scss/animations';

.checklist {
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border: 1px solid $grey20;
      border-radius: $border-radius-medium;
      padding: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        border-color: $grey40;
      }

      p {
        margin: 0;
      }

      .step-number {
        margin-right: 4px;
      }

      .circle {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 2px solid $grey20;
        margin-right: 16px;
        background-color: $white;
        position: relative;

        .checkmark {
          position: absolute;
          top: -2px;
          left: -2px;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          border: 2px solud $pa-color-main;
          align-items: center;
          justify-content: center;
          background-color: $pa-color-main;
          display: none;

          .icon {
            width: 18px;
            height: 18px;
            color: $white;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &.next {
        border-color: $pa-color-main;
        border-width: 2px;

        &:hover {
          border-color: $pa-color-main-dark;
        }

        p {
          font-weight: bold;
        }

        .circle {
          border-color: $pa-color-main;
        }
      }

      &.done {
        &:hover {
          border-color: $grey20;
        }

        .circle {
          .icon {
            display: block;
          }
        }

        .checkmark {
          display: flex;
        }

        &.animate .circle .checkmark {
          animation: fadein 0.5s linear both;
          animation-delay: 0.7s;
        }
      }
    }
  }

  &.compact {
    li {
      border: none;
      padding: 8px;
    }
  }
}
</style>

import parse from 'date-fns/parseISO';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import getISOWeek from 'date-fns/getISOWeek';
import getISOWeekYear from 'date-fns/getISOWeekYear';
import getYear from 'date-fns/getYear';
import { i18n } from '@/translate/i18n';
import date from '@/date/date';

const { t } = i18n.global;

export const TIME_FILTERS = {
  MONTH: {
    key: 'MONTH',
    from: new Date(
      getYear(subDays(Date.now(), 30)),
      getMonth(subDays(Date.now(), 30)),
      getDate(subDays(Date.now(), 30))
    ),
    metaLabel: (current) => {
      return date(parse(current.date));
    },
    metaGroupUnit: 'GENERAL.DAYS',
    label: (current) => ` ${getDate(parse(current.date))} `,
    group: (current) =>
      `${getMonth(parse(current.date))}-${getDate(parse(current.date))}`,
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.MONTH'),
  },
  QUARTER: {
    key: 'QUARTER',
    from: new Date(
      getYear(subMonths(Date.now(), 3)),
      getMonth(subMonths(Date.now(), 3)),
      getDate(subMonths(Date.now(), 3))
    ),
    metaLabel: (current) =>
      `${t('GENERAL.WEEK')} ${getISOWeek(parse(current.date))} ${getISOWeekYear(
        parse(current.date)
      )}`,
    metaGroupUnit: 'GENERAL.WEEK',
    label: (current) => `${getISOWeek(parse(current.date))}`,
    group: (current) =>
      `${getISOWeekYear(parse(current.date))}-${getISOWeek(
        parse(current.date)
      )}`,
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.QUARTER'),
  },
  HALF_YEAR: {
    key: 'HALF_YEAR',
    from: new Date(
      getYear(subMonths(Date.now(), 6)),
      getMonth(subMonths(Date.now(), 6)),
      getDate(subMonths(Date.now(), 6))
    ),
    metaLabel: (current) =>
      `${t('GENERAL.WEEK')} ${getISOWeek(parse(current.date))} ${getISOWeekYear(
        parse(current.date)
      )}`,
    metaGroupUnit: 'GENERAL.WEEK',
    label: (current) => `${getISOWeek(parse(current.date))}`,
    skipEverySecondLabel: true,
    group: (current) =>
      `${getISOWeekYear(parse(current.date))}-${getISOWeek(
        parse(current.date)
      )}`,
    translation: 'GENERAL.WEEK',
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.HALF_YEAR'),
  },
  YEAR: {
    key: 'YEAR',
    from: new Date(getYear(Date.now()), 0, 1),
    to: new Date(getYear(Date.now()), 11, 31, 23, 59, 59),
    metaLabel: (current) =>
      `${date(parse(current.date), 'MMMM')} ${getYear(parse(current.date))}`,
    metaGroupUnit: 'GENERAL.MONTH',
    label: (current) => `${date(parse(current.date), 'MMM')}`,
    group: (current) =>
      `${getYear(parse(current.date))}-${getMonth(parse(current.date))}`,
    dropdownLabel: () => `${getYear(Date.now())}`,
  },
};

export const getTwoYearTimeFiltersSince = (startYear) => {
  const timeFilters = {};
  const currentYear = getYear(Date.now());

  if (startYear >= currentYear) {
    return {};
  }

  for (let year = currentYear; year >= startYear + 1; year--) {
    timeFilters[`TWO_YEARS_${year - 1}_${year}`] = {
      key: `TWO_YEARS_${year - 1}_${year}`,
      from: new Date(year - 1, 0, 1),
      to: new Date(year, 11, 31, 23, 59, 59),
      metaLabel: (current) =>
        `${date(parse(current.date), 'MMMM')} ${getYear(parse(current.date))}`,
      metaGroupUnit: 'GENERAL.MONTH',
      label: (current) => `${date(parse(current.date), 'MMM')}`,
      group: (current) =>
        `${getYear(parse(current.date))}-${getMonth(parse(current.date))}`,
      dropdownLabel: () => `${year - 1} - ${year}`,
      isTwoYears: true,
      year1: year - 1,
      year2: year,
    };
  }

  return timeFilters;
};

export const getTimeFiltersSinceYear = (startYear) => {
  return Object.assign({}, TIME_FILTERS, getTwoYearTimeFiltersSince(startYear));
};

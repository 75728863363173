<template>
  <div class="dialog-size-md apply-template-dialog">
    <div class="modal-body">
      <div>
        <div class="load-template" v-if="currentMode === 'load'">
          <div class="template-header">
            <h1>
              {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.TEASER.HEADING') }}
            </h1>
            <p>
              {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.TEASER.TEXT1') }}
            </p>
          </div>
          <button
            class="btn btn-primary icon-btn save-template-button"
            @click="setCurrentMode('save')"
            :class="{ disabled: !enableTemplateSave }"
            v-tooltip="{
              content: enableTemplateSave
                ? null
                : $t(
                    'DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.SAVE.UNAVAILABLE'
                  ),
            }"
          >
            <Icon icon="savedisk" />
            {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.SAVE_CURRENT') }}
          </button>
          <h2>
            {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.LOAD_EXISTING') }}
          </h2>
          <TransitionGroup
            v-if="initialized"
            name="fade"
            class="template-list"
            tag="ul"
          >
            <li
              v-for="template in sortedTemplates"
              :key="template.phenotype.id"
              class="template-item"
            >
              <div class="name">
                <InlineEdit
                  v-model="template.phenotype.translation.name"
                  @onEditDone="editName(template)"
                  :label="$t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.NAME')"
                  :disabled="template.phenotype.global"
                  :maxLength="35"
                >
                  <div
                    class="input-counter"
                    :class="{
                      invalid: template.phenotype.translation.name.length > 35,
                    }"
                  >
                    {{ template.phenotype.translation.name.length }}/35
                  </div>
                </InlineEdit>
                <div class="origin" v-if="template.phenotype.global">
                  <div
                    class="recommended"
                    v-if="template.phenotype.recommended"
                  >
                    {{ $t('GENERAL.RECOMMENDED') }}
                  </div>
                  <MarketplaceLogo
                    class="logo"
                    v-if="
                      !template.phenotype.labels ||
                      (template.phenotype.labels &&
                        template.phenotype.labels.includes('MARKETPLACE') &&
                        hasMarketplace)
                    "
                  />
                  <SpreadshopLogo
                    class="logo"
                    v-if="
                      template.phenotype.labels &&
                      template.phenotype.labels.includes('SHOP') &&
                      hasShops
                    "
                  />
                  <img
                    class="logo"
                    src="/images/yt_logo.svg"
                    alt="Youtube Logo"
                    v-if="
                      template.phenotype.labels &&
                      template.phenotype.labels.includes('MERCHANT_YOUTUBE') &&
                      hasYoutubeShops
                    "
                  />
                </div>
                <div class="origin" v-else>
                  {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.BY_ME') }}
                </div>
                <div v-if="isAdmin" class="template-id">
                  ID: {{ template.phenotype.id }}
                </div>
              </div>
              <div class="actions">
                <DoubleConfirmButton
                  class="btn btn-default delete-btn btn-light btn-light-danger"
                  v-if="!template.phenotype.global"
                  @onConfirm="deleteTemplate(template.phenotype.id)"
                >
                  <template #default="{ confirm }">
                    <Icon icon="bin"></Icon>
                    <span v-if="confirm">{{ $t('GENERAL.DELETE') }}</span>
                  </template>
                </DoubleConfirmButton>
                <ProgressButton
                  class="btn btn-primary btn-light icon-btn"
                  :onClick="() => applyTemplate(template)"
                  :label="$t('GENERAL.APPLY')"
                  icon="accept"
                />
              </div>
            </li>
          </TransitionGroup>
        </div>
        <div class="save-template" v-if="currentMode === 'save'">
          <div class="template-header">
            <h1>
              {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.SAVE.HEADING') }}
            </h1>
            <p>
              {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.SAVE.TEXT1') }}
            </p>
          </div>
          <div class="limit-reached" v-if="limitReached">
            {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.LIMIT_REACHED') }}
          </div>
          <div class="new" v-else>
            <LabelInput
              class="field"
              :class="{ invalid: submitted && hasError }"
            >
              <label>{{
                $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.NAME')
              }}</label>
              <input type="text" v-model="name" />
              <div class="field-info">
                <span class="error-info" v-if="!name">{{
                  $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.NAME_REQUIRED')
                }}</span>
                <div class="char-info" :class="{ invalid: name.length > 35 }">
                  {{ name.length }}/35
                </div>
              </div>
            </LabelInput>
            <ProgressButton
              class="btn btn-primary icon-btn"
              :onClick="createNewTemplate"
              :label="$t('GENERAL.SAVE')"
              icon="accept"
            />
          </div>
          <div class="replace" v-if="initialized && userTemplates.length">
            <h2>
              {{
                $t(
                  'DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.OVERWRITE_EXISTING.HEADING'
                )
              }}
            </h2>
            <p>
              {{
                $t(
                  'DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.OVERWRITE_EXISTING.SUBHEADING'
                )
              }}
            </p>
            <div>
              <TransitionGroup name="fade" tag="ul" class="template-list">
                <li
                  v-for="template in userTemplates"
                  :key="template.phenotype.id"
                  class="template-item"
                >
                  <div class="name">
                    <InlineEdit
                      v-model="template.phenotype.translation.name"
                      @onEditDone="editName(template)"
                      :label="
                        $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.NAME')
                      "
                    >
                      <div class="info">
                        <div
                          class="input-counter"
                          :class="{
                            invalid:
                              template.phenotype.translation.name.length > 35,
                          }"
                        >
                          {{ template.phenotype.translation.name.length }}/35
                        </div>
                      </div>
                    </InlineEdit>
                  </div>
                  <DoubleConfirmButton
                    class="btn btn-default delete-btn btn-light btn-light-danger"
                    @onConfirm="deleteTemplate(template.phenotype.id)"
                  >
                    <template #default="{ confirm }">
                      <Icon icon="bin"></Icon>
                      <span v-if="confirm">{{ $t('GENERAL.DELETE') }}</span>
                    </template>
                  </DoubleConfirmButton>
                  <ProgressButton
                    class="btn btn-primary btn-light icon-btn"
                    :onClick="() => saveTemplateAs(template.phenotype.id)"
                    :label="
                      $t(
                        'DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.SAVE.REPLACE_CTA'
                      )
                    "
                    icon="replace"
                  />
                </li>
              </TransitionGroup>
            </div>
          </div>
        </div>

        <div class="loader" v-if="!initialized && !loadingError">
          <img src="/images/loader.svg" alt="loader" class="sprd-loader" />
        </div>
        <div class="error" v-if="!initialized && loadingError">
          <Icon icon="broken-file" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ideaTemplateService from 'src/app/commons/api/ideaTemplateService/ideaTemplateService';
import InlineEdit from 'src/app/commons/inlineEdit/InlineEdit.vue';
import DoubleConfirmButton from 'src/app/commons/doubleConfirmButton/DoubleConfirmButton.vue';
import ProgressButton from 'src/app/commons/btnProgress/ProgressButton.vue';
import analytics from '@/tracking/analytics';
import LabelInput from 'src/app/commons/labelInput/LabelInput.vue';
import productIdeaService from 'src/app/commons/api/productIdeaService/productIdeaService';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'TemplateDialog',
  props: ['modalInstance', 'data'],
  components: {
    InlineEdit,
    DoubleConfirmButton,
    ProgressButton,
    LabelInput,
    MarketplaceLogo,
    SpreadshopLogo,
  },
  data() {
    return {
      currentMode: 'load',
      templates: null,
      initialized: false,
      loadingError: false,
      name: '',
      limitReached: false,
      submitted: false,
      templateLimit: 20,
    };
  },
  async created() {
    try {
      await this.loadTemplates();
      this.initialized = true;
    } catch (error) {
      this.loadingError = true;
    }
  },
  methods: {
    async loadTemplates() {
      this.templates = await ideaTemplateService.getTemplatesForIdea(
        this.data.idea.id,
        this.relevantTemplateLabels
      );
    },
    applyTemplate(template) {
      analytics.logEvent('idea-template-apply', {
        global: template.phenotype.global,
      });

      return ideaTemplateService
        .applyTemplateForIdeas({
          templateId: template.phenotype.id,
          ideaIds: [this.data.idea.id],
        })
        .then(() => {
          return this.modalInstance.close({ action: this.currentMode });
        });
    },
    async deleteTemplate(templateId) {
      await ideaTemplateService.deleteTemplate(templateId);
      await this.loadTemplates();
    },
    async editName(template) {
      if (template.phenotype.translation.name.length > 35) {
        template.phenotype.translation.name =
          template.phenotype.translation.name.slice(0, 35);
      }

      await ideaTemplateService.updateTemplate({
        translation: template.phenotype.translation,
        phenotypeId: template.phenotype.id,
      });
      await this.loadTemplates();
    },
    async saveTemplateAs(templateId) {
      await productIdeaService.updateAssortment(this.data.idea);
      await ideaTemplateService.updateTemplate({
        ideaId: this.data.idea.id,
        phenotypeId: templateId,
      });
      setTimeout(() => {
        this.modalInstance.close({ action: this.currentMode });
      }, 1500);

      return Promise.resolve();
    },
    async createNewTemplate() {
      this.submitted = true;

      if (this.hasError) {
        return Promise.reject();
      }
      await productIdeaService.updateAssortment(this.data.idea);
      await ideaTemplateService.createTemplate({
        translation: {
          name: this.name,
          locale: this.userLocale,
        },
        ideaId: this.data.idea.id,
      });
      setTimeout(() => {
        this.modalInstance.close({ action: this.currentMode });
      }, 1500);

      return Promise.resolve();
    },
    setCurrentMode(mode) {
      if (mode === 'save' && !this.enableTemplateSave) {
        return;
      }

      this.currentMode = mode;
    },
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
      hasMarketplace: 'user/hasMarketplace',
      hasYoutubeShops: 'user/hasYoutubeShops',
      relevantTemplateLabels: 'idea/relevantTemplateLabels',
      isAdmin: 'account/isAdmin',
    }),
    enableTemplateSave() {
      return !assortmentHelper
        .getSelectedSellables(this.data.idea.assortment)
        .some(
          (product) =>
            product.included &&
            product.hints &&
            product.hints.includes('ONLY_FOR_POSTERS')
        );
    },
    ...mapState({
      userLocale: (s) => s.user.locale,
    }),
    sortedTemplates() {
      return [...this.globalTemplates, ...this.userTemplates];
    },
    userTemplates() {
      return this.templates.list.filter(
        (template) => !template.phenotype.global
      );
    },
    globalTemplates() {
      // partner should only see relevant global templates, recommended first
      return this.templates.list
        .filter((template) => template.phenotype.global)
        .filter(
          (template) =>
            !template.phenotype.labels ||
            (this.hasShops && template.phenotype.labels.includes('SHOP')) ||
            (this.hasYoutubeShops &&
              template.phenotype.labels.includes('MERCHANT_YOUTUBE')) ||
            (this.hasMarketplace &&
              template.phenotype.labels.includes('MARKETPLACE'))
        )
        .sort((a) => (a.phenotype.recommended ? -1 : 1));
    },
    hasError() {
      return (
        (this.templates && this.userTemplates.length >= this.templateLimit) ||
        !this.name ||
        this.name.length > 35
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/buttons';
@import 'src/scss/utils';

.apply-template-dialog {
  max-width: 620px;
  width: 70vw;
}

.template-header {
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0 0 12px 0;
  }

  p {
    margin: 0 0 16px 0;
    color: $grey60;
  }
}

.save-template-button {
  height: 40px;
}

.template-list {
  list-style: none;
  padding: 0;
  margin: 16px 0 0 0;

  .btn {
    border: none;
  }
}

.template-item {
  display: flex;
  padding: 16px;
  background-color: $grey5;
  border-radius: $border-radius-medium;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .name {
    flex-grow: 1;
    width: 60%;
    min-width: 30%;

    :deep(.inline-edit > p) {
      @extend .text-truncate;
    }
  }

  .origin {
    display: flex;
    align-items: center;
    margin-top: 5px;
    @extend .text-sm;

    .logo {
      height: 16px;

      &:not(:first-child) {
        margin-left: 12px;
      }
    }

    .recommended {
      padding: 5px 12px;
      background-color: $pa-color-main;
      color: $white;
      border-radius: 24px;
    }
  }

  .template-id {
    margin-top: 5px;
    @extend .text-sm;
  }

  .actions {
    display: flex;
    flex-shrink: 0;
  }

  button {
    height: 40px;
  }

  .delete-btn {
    margin-right: 10px;

    & > .icon {
      width: 24px;
      height: 24px;
    }

    &.confirm {
      @extend .btn-danger;
    }
  }

  :deep(.inline-edit) {
    margin-right: 16px;
    height: auto;

    p {
      @extend .heading4;
      font-weight: normal;
      margin: 0;
    }
  }
}

.save-template .template-item {
  :deep(.inline-edit) {
    height: 100%;
  }
}

.new {
  display: flex;
  position: relative;

  .field {
    flex-grow: 1;
  }

  .btn {
    white-space: nowrap;
    margin-left: 16px;
    height: 40px;
  }

  .char-info.invalid {
    color: $pa-color-red;
  }
}

.replace {
  h2 {
    margin-bottom: 8px;
  }

  p {
    margin: 0;
    color: $grey60;
  }
}

h2 {
  margin: 24px 0 16px 0;
}

.limit-reached {
  background-color: $pa-color-main;
  color: white;
  padding: 18px 48px 14px 48px;
  margin: 0 -20px;
  margin-bottom: 16px;
}

.error {
  display: flex;
  justify-content: center;
  padding: 28px 0;

  .icon {
    height: 48px;
    width: 48px;
  }
}
</style>

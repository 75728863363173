<script setup>
import { ref, onUnmounted, computed } from 'vue';
import analytics from '@/tracking/analytics';
import RadioButtonGroup from '@/RadioButtonGroup/RadioButtonGroup.vue';

const options = [
  {
    key: 'CREATOR',
    text: 'ORIGIN_SURVEY.CREATOR',
  },
  {
    key: 'REVIEW_PLATFORM',
    text: 'ORIGIN_SURVEY.REVIEW_PLATFORM',
  },
  {
    key: 'PODCAST',
    text: 'ORIGIN_SURVEY.PODCAST',
  },
  {
    key: 'FRIENDS',
    text: 'ORIGIN_SURVEY.FRIENDS',
  },
  {
    key: 'SEARCH_OR_SOCIAL',
    text: 'ORIGIN_SURVEY.SEARCH_OR_SOCIAL',
  },
  {
    key: 'CUSTOM',
    text: 'ORIGIN_SURVEY.CUSTOM',
  },
];

const selectedOption = ref();
const customOrigin = ref();

const displayCustomTextInput = computed(() => {
  return selectedOption.value?.key === 'CUSTOM';
});

const originResult = computed(() => {
  return displayCustomTextInput.value && customOrigin.value?.trim()
    ? customOrigin.value.trim()
    : selectedOption.value?.key;
});

function selectOption(option) {
  selectedOption.value = option;
}

onUnmounted(() => {
  if (originResult.value) {
    analytics.logEvent('partner_origin_survey_answer', {
      origin: originResult.value,
    });
  } else {
    analytics.logEvent('partner_origin_survey_skip');
  }
});
</script>

<template>
  <div class="originsurvey">
    <h2>{{ $t('ORIGIN_SURVEY.HEADING') }}</h2>
    <RadioButtonGroup
      class="options"
      :items="options"
      :model-value="selectedOption"
      :item-key="(option) => option.key"
      :item-to-string="(option) => $t(option.text)"
      @update:model-value="selectOption"
    >
    </RadioButtonGroup>
    <input
      v-if="displayCustomTextInput"
      type="text"
      v-model="customOrigin"
      maxLength="40"
      :placeholder="$t('ORIGIN_SURVEY.CUSTOM_INPUT_PLACEHOLDER')"
      class="custom-origin-input"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.originsurvey {
  border: 1px solid $pa-color-main;
  border-radius: $border-radius-medium;
  padding: 16px;
  margin-bottom: 24px;

  h2 {
    margin: 0;
  }
}

.options {
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 0 0;
}

.custom-origin-input {
  margin: 8px 0 0 0;
  max-width: 380px;
}
</style>

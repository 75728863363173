<template>
  <div>
    <h1 class="subheading">
      {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.PROGRESS_2') }}
    </h1>
    <div class="boxContainer">
      <div class="imgContainer">
        <canvas
          :width="500"
          :height="500"
          class="canvas"
          ref="preview"
        ></canvas>
      </div>
      <div class="settingsContainer">
        <h3>
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FONT_AND_TEXT_HEADING') }}
        </h3>
        <Dropdown
          v-if="!productImageUrl"
          class="promoDropdown"
          :label="$t('POS.SHOP_SETTINGS.NAVIGATION.PROMOTIONS.HEADING')"
          :items="promotions.promotions"
          :item-key="(promo) => promo.id"
          :itemToString="(promo) => promo.text"
          :value="canvasSettings.selectedPromo"
          @change="(promo) => (canvasSettings.selectedPromo = promo)"
        />
        <FontSelector
          :label="$t('POS.SHOP_SETTINGS.DESIGN.TYPOGRAPHY.FONT_HEADING')"
          :value="canvasSettings.selectedHeadlineFont"
          :padded="false"
          :options="settings.availableFontsHeadline"
          :isOpen="fontSelectorOpen"
          @toggle="fontSelectorOpen = !fontSelectorOpen"
          @change="selectHeadlineFont($event)"
        />
        <LegalLabelInput
          v-model="canvasSettings.customText"
          maxlength="70"
          :label="$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.CUSTOM_TEXT')"
        />
        <Color
          v-if="!productImageUrl"
          class="colorPicker sprd-shop-input"
          :label="$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.TEXT_COLOR')"
          :current-color="canvasSettings.textColor"
          :custom-color="customColor || canvasSettings.textColor"
          :suggested-colors="['#FFFFFF', '#000000']"
          :custom-color-enabled="true"
          @update:current="(col) => (canvasSettings.textColor = col)"
          @update:custom="
            (col) => {
              this.customColor = col;
              this.canvasSettings.textColor = col;
            }
          "
        />

        <h3>
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BRANDING_HEADING') }}
        </h3>
        <RadioButtonGroup
          class="sprd-shop-input branding"
          :class="{ productResetter: productImageUrl }"
          :items="canvasSettings.brandingOptions"
          :itemKey="(item) => item.type"
          :itemToString="(item) => item.trans"
          :itemDisabled="
            (item) => item.type === 'LOGO' && !canvasSettings.logoPossible
          "
          v-model="canvasSettings.selectedBrandingOption"
        />
        <Color
          v-if="
            productImageUrl &&
            canvasSettings.selectedBrandingOption.type === 'NAME'
          "
          class="colorPicker sprd-shop-input"
          :label="$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.TEXT_COLOR')"
          :current-color="canvasSettings.textColor"
          :custom-color="customColor || canvasSettings.textColor"
          :suggested-colors="['#FFFFFF', '#000000']"
          :custom-color-enabled="true"
          @update:current="(col) => (canvasSettings.textColor = col)"
          @update:custom="
            (col) => {
              this.customColor = col;
              this.canvasSettings.textColor = col;
            }
          "
        />

        <h3>
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.BACKGROUND_HEADING') }}
        </h3>
        <ImageSelector
          :images="stockImages"
          :selected-image-filename="canvasSettings.selectedStockImageName"
          :on-image-selected="
            (filename) => (canvasSettings.selectedStockImageName = filename)
          "
          :manyImagesLayout="true"
          :show-unsplash-hint="false"
        />

        <h3>
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_HEADING') }}
        </h3>
        <button
          @click="gotoNextStep"
          :disabled="!gotoNextStepPossible"
          class="btn btn-primary bigCta"
        >
          {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_BUTTON') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { PromotionsData } from 'src/app/partnerarea/pos/shop/shopSavable/PromotionsData';
import Dropdown from 'src/app/commons/Dropdown/Dropdown.vue';
import LegalLabelInput from 'src/app/partnerarea/pos/shop/common/LegalLabelInput.vue';
import Color from 'src/app/partnerarea/pos/shop/common/Color.vue';
import RadioButtonGroup from 'src/app/commons/RadioButtonGroup/RadioButtonGroup.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI';
import ImageSelector from 'src/app/partnerarea/pos/shop/common/ImageSelector.vue';
import { drawImage, loadFont, loadImage } from './socialMediaToolCore';
import FontSelector from 'src/app/partnerarea/pos/shop/design/shopStyles/FontSelector.vue';
import { getFontLocation, getSafeFontName } from './socialMediaToolFontData';

export default {
  name: 'SocialMediaToolCustomizer',
  mixins: [PromotionsData, SettingsData],
  components: {
    Color,
    Dropdown,
    LegalLabelInput,
    ImageSelector,
    RadioButtonGroup,
    FontSelector,
  },
  props: {
    canvasSettings: {
      type: Object,
      required: true,
    },
    productImageUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      stockImages: [],
      fontSelectorOpen: false,
      customColor: null,
    };
  },
  mounted() {
    this.$watch(
      () => [
        this.canvasSettings.selectedPromo,
        this.canvasSettings.textColor,
        this.canvasSettings.customText,
        this.canvasSettings.selectedBrandingOption,
        this.canvasSettings.selectedStockImageName,
        this.canvasSettings.selectedHeadlineFont,
      ],
      this.updateCanvas
    );
    this.updateCanvas();
  },
  async created() {
    this.stockImages = await shopAPI.getShopStockImages(
      this.$store,
      'social_media_tool'
    );
    const defaultImg = this.stockImages.find(
      (img) => img.filename === '11.jpg'
    );
    if (defaultImg && !this.canvasSettings.selectedStockImageName) {
      this.canvasSettings.selectedStockImageName = defaultImg.filename;
    }
    await this.updateCanvas();
  },
  methods: {
    async getCanvasData() {
      if (this.gotoNextStepPossible) {
        let backgroundImage = null;
        let shopName = null;
        let logoImage = null;
        let productImage = null;
        if (
          this.canvasSettings.selectedStockImageName &&
          this.stockImages.length > 0
        ) {
          backgroundImage = await loadImage(
            this.stockImages.find(
              (si) => si.filename === this.canvasSettings.selectedStockImageName
            ).url
          );
        }
        if (this.canvasSettings.selectedBrandingOption.type === 'NAME') {
          shopName = this.$store.state.shop.currentShop.name;
        } else if (
          this.canvasSettings.selectedBrandingOption.type === 'LOGO' &&
          this.settings.logoImage &&
          this.settings.logoImage
        ) {
          const fileArray = await shopAPI.getShopImages(this.$store, 'logo');
          const match = fileArray.find(
            (fileEntry) => fileEntry.filename === this.settings.logoImage
          );
          if (match) {
            logoImage = await loadImage(match.url);
          }
        }
        if (this.productImageUrl) {
          productImage = await loadImage(
            this.productImageUrl.replace('jpg', 'png')
          );
        }
        const fontHeadline = await loadFont(
          getSafeFontName(this.canvasSettings.selectedHeadlineFont),
          getFontLocation(this.canvasSettings.selectedHeadlineFont)
        );
        const fontText = await loadFont(
          getSafeFontName(this.settings.fontText),
          getFontLocation(this.settings.fontText)
        );
        return {
          backgroundImage,
          productImage,
          promoText:
            this.canvasSettings.selectedPromo && !this.productImageUrl
              ? this.canvasSettings.selectedPromo.text
              : null,
          customText: this.canvasSettings.customText,
          textColor: this.canvasSettings.textColor,
          shopName,
          logoImage,
          fontHeadline,
          fontText,
          isModel: Boolean(
            this.productImageUrl && this.productImageUrl.includes('modelId')
          ),
        };
      } else {
        return null;
      }
    },
    async updateCanvas() {
      if (
        this.canvasSettings.selectedPromo === null &&
        this.promotions.promotions &&
        this.promotions.promotions.length > 0
      ) {
        this.canvasSettings.selectedPromo = this.canvasSettings.defaultPromo
          ? this.promotions.promotions.find(
              (promotion) => promotion.id === this.canvasSettings.defaultPromo
            )
          : this.promotions.promotions[0];
      }
      const canvasData = await this.getCanvasData();
      if (canvasData) {
        drawImage(Object.assign(canvasData, { canvas: this.$refs.preview }));
      }
    },
    async gotoNextStep() {
      const canvasData = await this.getCanvasData();
      if (canvasData) {
        this.$emit('nextStep', canvasData);
      }
    },
    selectHeadlineFont(value) {
      this.fontSelectorOpen = false;
      this.canvasSettings.selectedHeadlineFont = value;
    },
  },
  computed: {
    gotoNextStepPossible() {
      return this.canvasSettings.selectedStockImageName || this.productImageUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.cntCont {
  background-color: $grey5;
}
.subheading {
  margin-bottom: 1.5rem;
}
.boxContainer {
  display: flex;
}
.imgContainer {
  width: 60%;
  max-width: 75vh; // avoid scollbar (heuristic)
  height: auto;
}
.canvas {
  width: 100%;
  background-color: $grey40;
}
.settingsContainer {
  background-color: white;
  margin: 0 0 0 30px;
  width: calc(40% - 30px);
  height: fit-content;
  padding: 13px 31px 31px 31px;
  border-radius: $border-radius-medium;
}
.promoDropdown {
  margin: 0;
}
.bigCta {
  height: 48px;
  width: 100%;
}
.colorPicker {
  width: calc(100% + 62px);
  margin-left: -31px;

  & > :deep(*) {
    padding: 5px 31px;
  }

  :deep(.color-slot__toggle) {
    background-color: $white !important;
  }

  :deep(.colors) {
    background: $white !important;
  }
}
.branding {
  flex-wrap: wrap;
  :deep(> label) {
    margin-bottom: 0.5em;
  }
}
.productResetter {
  margin-bottom: 0;
}
</style>

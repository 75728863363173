import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';
import { i18n } from '@/translate/i18n';
import formatISO from 'date-fns/formatISO';
import analytics from '@/tracking/analytics';
import colors from 'src/scss/styleguide/_exports.module.scss';

const { t } = i18n.global;

function optionsToUrlParams(options = {}) {
  return Object.entries(options)
    .map(([key, value]) => {
      return value
        ? `${key}=${
            typeof value === 'object'
              ? JSON.stringify(value).replace(/"/g, '')
              : value
          }`
        : '';
    })
    .filter(Boolean)
    .join('&');
}

let _cachedCreditOverview = {};

export default {
  getCreditOverview() {
    if (!_cachedCreditOverview[store.state.user.data.id]) {
      _cachedCreditOverview = {
        [store.state.user.data.id]: apiClient
          .request({
            method: 'GET',
            url: `api/v1/users/${store.state.user.data.id}/statistics/credits/overview`,
          })
          .then((res) => res.data),
      };
    }
    return _cachedCreditOverview[store.state.user.data.id];
  },
  getCreditDetails(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/credits`,
        params: {
          ...params,
          dateFrom: params.dateFrom
            ? formatISO(params.dateFrom)
            : formatISO(new Date('1970-01-01')),
          dateTo: params.dateTo ? formatISO(params.dateTo) : null,
        },
      })
      .then((res) => res.data);
  },
  getCreditDetail(id, params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/credits/${id}`,
        params,
      })
      .then((res) => res.data);
  },
  getCreditDetailsDownloadUrl(options = {}) {
    const params = optionsToUrlParams(options);
    const url = `api/v1/users/${store.state.user.data.id}/statistics/credits/export`;
    return params ? `${url}?${params}` : url;
  },
  getSalesOverview(params = {}) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/graph`,
        params: {
          ...params,
          dateFrom: params.dateFrom ? formatISO(params.dateFrom) : null,
          dateTo: params.dateTo ? formatISO(params.dateTo) : null,
        },
      })
      .then((res) => res.data);
  },
  getSales(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/sales`,
        params: {
          ...params,
          dateFrom: params.dateFrom
            ? formatISO(params.dateFrom)
            : formatISO(new Date('1970-01-01')),
          dateTo: params.dateTo ? formatISO(params.dateTo) : null,
        },
      })
      .then((res) => {
        // workaround for DEV-193123, can be removed if backend data is reliable again
        if (res.data.list?.length) {
          const hasSalesWithoutItems = res.data.list.some(
            (entry) => !entry.items,
          );
          if (hasSalesWithoutItems) {
            console.log('items null');
            analytics.logEvent('sales-without-items');
          }

          res.data.list = res.data.list.filter((entry) => entry.items?.length);
        }
        return res.data;
      });
  },
  getSalesSummary(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/sales/summary`,
        params: {
          ...params,
          dateFrom: params.dateFrom
            ? formatISO(params.dateFrom)
            : formatISO(new Date('1970-01-01')),
          dateTo: params.dateTo ? formatISO(params.dateTo) : null,
        },
      })
      .then((res) => res.data);
  },
  getSalesDetails(id, params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/sales/${id}`,
        params,
      })
      .then((res) => res.data);
  },
  getSalesDetailsDownloadUrl(options = {}) {
    const params = optionsToUrlParams(options);
    const url = `api/v1/users/${store.state.user.data.id}/statistics/sales/export`;
    return params ? `${url}?${params}` : url;
  },
  getBestsellerMapData(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/map`,
        params: {
          ...params,
          limit: 30,
        },
      })
      .then((res) => res.data);
  },
  getBestsellers(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/bestsellers`,
        params,
      })
      .then((res) => res.data);
  },
  getTopDesigns(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/top/DESIGNS`,
        params,
      })
      .then((res) => res.data);
  },
  getBestsellersDownloadUrl(options = {}) {
    const params = optionsToUrlParams(options);
    const url = `api/v1/users/${store.state.user.data.id}/statistics/bestsellers/export`;
    return params ? `${url}?${params}` : url;
  },
  getLifetimeStatistics(params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/lifetime`,
        params,
      })
      .then((res) => res.data);
  },
  getIdeaStatistics(ideaId, params) {
    return apiClient
      .request({
        method: 'GET',
        url: `api/v1/users/${store.state.user.data.id}/statistics/idea/${ideaId}`,
        params: {
          ...params,
          dateFrom: params.dateFrom ? formatISO(params.dateFrom) : null,
          dateTo: params.dateTo ? formatISO(params.dateTo) : null,
        },
      })
      .then((res) => res.data);
  },
  getCommissionTypes: function () {
    return {
      DESIGN_COMMISSION: 'Design Commission',
      MDS_COMMISSION: 'mds_commission',
      VOLUME_COMMISSION: 'Volume Commission',
      PAYOUT: 'Payout',
      ROLLBACK: 'Rollback',
      AFFILIATE_COMMISSION: 'Affiliate Commission',
      UNKNOWN: 'unclassified',
    };
  },
  getDiscountTypes: function () {
    return {
      volumeDiscount: {
        key: 'volumeDiscount',
        name: 'STATISTICS.SALE_DETAILS.DISCOUNT.VOLUME',
      },
      couponDiscount: {
        key: 'couponDiscount',
        name: 'STATISTICS.SALE_DETAILS.DISCOUNT.COUPON',
      },
    };
  },
  getPointOfSales: function () {
    return {
      OWN_SHOP: 'OWN_SHOP',
      FOREIGN_SHOP: 'FOREIGN_SHOP',
      CYO: 'CYO',
      MP: 'MP',
      EMP: 'EMP',
      UNKNOWN: 'UNKNOWN',
    };
  },
  getSaleStates: function () {
    return {
      NEW: {
        key: 'NEW',
        icon: 'star',
        name: 'STATISTICS.SALE_DETAILS.STATUS.NEW.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.NEW.DESCRIPTION',
        color: colors.paColorMain,
      },
      SENT_AND_PAID: {
        key: 'SENT_AND_PAID',
        icon: 'accept-big',
        name: 'STATISTICS.SALE_DETAILS.STATUS.SENT_AND_PAID.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.SENT_AND_PAID.DESCRIPTION',
        color: colors.paColorGreen,
      },
      SENT: {
        key: 'SENT',
        icon: 'truck_1',
        name: 'STATISTICS.SALE_DETAILS.STATUS.SENT.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.SENT.DESCRIPTION',
        color: colors.paColorGreen,
      },
      COMMISSION_PAID: {
        key: 'COMMISSION_PAID',
        icon: 'money',
        name: 'STATISTICS.SALE_DETAILS.STATUS.COMMISSION_PAID.NAME',
        description:
          'STATISTICS.SALE_DETAILS.STATUS.COMMISSION_PAID.DESCRIPTION',
        color: colors.paColorGreen,
      },
      PROGRESS: {
        key: 'PROGRESS',
        icon: 'sale-progress',
        name: 'STATISTICS.SALE_DETAILS.STATUS.PROGRESS.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.PROGRESS.DESCRIPTION',
        color: colors.grey60,
      },
      WAIT: {
        key: 'WAIT',
        icon: 'hourglass',
        name: 'STATISTICS.SALE_DETAILS.STATUS.WAIT.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.WAIT.DESCRIPTION',
        color: colors.grey60,
      },
      CANCEL: {
        key: 'CANCEL',
        icon: 'close',
        name: 'STATISTICS.SALE_DETAILS.STATUS.CANCEL.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.CANCEL.DESCRIPTION',
        color: colors.paColorRed,
      },
      RETURN: {
        key: 'RETURN',
        icon: 'sale-return',
        name: 'STATISTICS.SALE_DETAILS.STATUS.RETURN.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.RETURN.DESCRIPTION',
        color: colors.paColorRed,
      },
      CHARGED_BACK: {
        key: 'CHARGED_BACK',
        icon: 'charged-back',
        name: 'STATISTICS.SALE_DETAILS.STATUS.CHARGED_BACK.NAME',
        description: 'STATISTICS.SALE_DETAILS.STATUS.CHARGED_BACK.DESCRIPTION',
        color: colors.paColorRed,
      },
    };
  },
  getShopPosFilters() {
    const shops = store.getters['user/shops'];

    if (!shops.length) {
      return [];
    }

    return shops.map((shop) => ({
      key: shop.pointOfSale.name,
      value: shop.pointOfSale.target.id,
      name: shop.pointOfSale.name,
    }));
  },
  getPosFilters() {
    let pos = [
      {
        key: 'ALL',
        value: null,
        name: t('DASHBOARD.POS_FILTER.ALL'),
      },
    ];

    if (
      store.getters['settings/mpEnabled'] &&
      !store.getters['publishing/isShopScope']
    ) {
      pos = pos.concat([
        {
          key: 'MP',
          value: 'MP',
          name: t('DASHBOARD.POS_FILTER.SPREADSHIRT'),
        },
        {
          key: 'CYO',
          value: 'CYO',
          name: t('GENERAL.CYO'),
        },
        {
          key: 'EMP',
          value: 'EMP',
          name: t('DASHBOARD.POS_FILTER.EMP'),
        },
      ]);
    }

    const shops = store.getters['user/shops'];

    if (shops.length && !store.getters['publishing/isMpScope']) {
      if (shops.length <= 1 || store.getters['settings/mpEnabled']) {
        pos.push({
          key: 'SHOPS',
          value: 'OWN_SHOP',
          name: t('DASHBOARD.POS_FILTER.SHOPS'),
        });
      }
      if (shops.length > 1) {
        pos = pos.concat(this.getShopPosFilters());
      }
    }

    return pos;
  },
  getIdeaPosFilterFromPosFilter(posFilter) {
    const ideaPosTypeMapping = {
      OWN_SHOP: 'SHOP',
      MP: 'MARKETPLACE',
      CYO: 'MARKETPLACE',
      EMP: 'MARKETPLACE',
    };

    return ideaPosTypeMapping[posFilter] || posFilter;
  },
};

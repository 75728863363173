import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseISO from 'date-fns/parseISO';
import IDEA_STATES from '@/api/models/ideaStates/ideaStates';
import { isEqual } from '@/utils';

export default {
  getCurrentTranslation(idea) {
    return idea.translations
      ? idea.translations.find(
          (translation) => translation.locale === idea.language
        )
      : null;
  },
  getTranslation(idea, locale) {
    if (!locale) {
      return this.getCurrentTranslation(idea);
    }
    return idea.translations
      ? idea.translations.find((translation) => translation.locale === locale)
      : null;
  },
  getState(idea) {
    return (
      this.determineIdeaState(idea.processingState) || IDEA_STATES.UNPUBLISHED
    );
  },
  hasDarkBackground(idea) {
    return idea.backgroundColor;
  },
  isPublishedOnCYO(idea) {
    return !!(idea.publishingDetails || []).find(
      (detail) => detail.pointOfSale.type === 'CYO'
    );
  },
  isPublishedOnMarketplace(idea) {
    return !!(idea.publishingDetails || []).find(
      (detail) => detail.pointOfSale.type === 'MARKETPLACE'
    );
  },
  isPublishedOnShop(idea) {
    return !!(idea.publishingDetails || []).find(
      (detail) => detail.pointOfSale.type === 'SHOP'
    );
  },
  wasConfigured(idea) {
    return idea.properties && idea.properties.configuration;
  },
  getPublishingDetails(idea) {
    if (idea.publishingDetails) {
      return idea.publishingDetails
        .filter((detail) => detail.pointOfSale.type !== 'CYO')
        .sort((a, b) => {
          if (a.pointOfSale.type < b.pointOfSale.type) {
            return -1;
          }
          if (a.pointOfSale.type > b.pointOfSale.type) {
            return 1;
          }
          return 0;
        });
    }
    return null;
  },
  getPublishingDetailsByState(idea, state) {
    return this.getPublishingDetails(idea).filter((publishingDetail) =>
      this.determineIdeaState(publishingDetail.status).is(state)
    );
  },
  getRelevantPosTypesForScope(scope) {
    if (scope?.key === 'MP') {
      return ['MARKETPLACE', 'CYO'];
    } else {
      return ['MARKETPLACE', 'CYO', 'SHOP'];
    }
  },
  getPublishingDetailsSortedByState(idea, filterPosType) {
    if (idea.publishingDetails) {
      const states = [
        IDEA_STATES.PUBLISHED,
        IDEA_STATES.PENDING,
        IDEA_STATES.OFFLINE,
        IDEA_STATES.REJECTED,
      ]
        .map((state) => ({
          state,
          publishingDetails: this.getPublishingDetailsByState(
            idea,
            state
          ).filter((publishingDetail) =>
            filterPosType.includes(publishingDetail.pointOfSale.type)
          ),
        }))
        .filter((state) => state.publishingDetails.length);

      return states;
    }

    return null;
  },
  isSelectable(idea) {
    return this.getState(idea).isIn([
      IDEA_STATES.UNPUBLISHED,
      IDEA_STATES.PENDING,
      IDEA_STATES.PUBLISHED,
      IDEA_STATES.REJECTED,
    ]);
  },
  determineIdeaState(state) {
    if (state === 'ONLINE') {
      return IDEA_STATES.PUBLISHED;
    }
    return IDEA_STATES[
      Object.keys(IDEA_STATES).find((key) => IDEA_STATES[key].key === state)
    ];
  },
  getAllAssortmentGroupsFlat(group) {
    if (group && group.subFilters) {
      return [group].concat(
        Object.keys(group.subFilters).reduce((result, current) => {
          return result.concat(
            this.getAllAssortmentGroupsFlat(group.subFilters[current])
          );
        }, [])
      );
    } else {
      return [group];
    }
  },
  getAssortmentGroupById(assortment, groupId) {
    return this.getAllAssortmentGroupsFlat(assortment).find(
      (group) => group.filterId === groupId
    );
  },
  equals(idea1, idea2, options) {
    options = Object.assign(
      {},
      {
        ignoreAssortment: false,
        ignorePublishingDetails: false,
      },
      options
    );

    return (
      isEqual(idea1.translations, idea2.translations) &&
      idea1.intPubDetails.userRequestedAutoTranslations ===
        idea2.intPubDetails.userRequestedAutoTranslations &&
      (options.ignorePublishingDetails ||
        isEqual(idea1.publishingDetails, idea2.publishingDetails)) &&
      isEqual(idea1.properties, idea2.properties) &&
      isEqual(idea1.flags, idea2.flags) &&
      (options.ignoreAssortment || this.assortmentEquals(idea1, idea2)) &&
      idea1.backgroundColor === idea2.backgroundColor &&
      idea1.mpTransformerMode === idea2.mpTransformerMode &&
      idea1.language === idea2.language
    );
  },
  assortmentEquals(idea1, idea2) {
    if (!idea1.assortment && !idea2.assortment) {
      return true;
    }

    return isEqual(
      this.getAllAssortmentGroupsFlat(idea1.assortment).map((g) =>
        g.plain ? g.plain() : g
      ),
      this.getAllAssortmentGroupsFlat(idea2.assortment).map((g) =>
        g.plain ? g.plain() : g
      )
    );
  },
  isStuckInProcessing(idea) {
    return (
      this.determineIdeaState(idea.processingState).isProcessing() &&
      differenceInMinutes(new Date(), parseISO(idea.dateCreated)) > 15
    );
  },
  isEmbroideryIdea(idea) {
    return idea.flags.includes('EMBROIDERY');
  },
};

<template>
  <div
    class="image"
    :class="{ 'design-checked-background': isDesign, dark: darkBg }"
  >
    <div>
      <SprdImage
        :src="imageUrl"
        alt="Design Image"
        :imgStyle="{ width: `${width}px`, height: `${height}px` }"
      />
    </div>
    <a
      class="bg-toggle-link"
      :class="{ light: darkBg }"
      href
      v-if="isDesign"
      @click.prevent.stop="toggleBg"
    >
      <Icon icon="background-color"></Icon>
    </a>
  </div>
</template>

<script>
import designService from '@/api/designService/designService';
import SprdImage from 'src/app/commons/image/SprdImage.vue';

export default {
  name: 'SaleItemImage',
  components: {
    SprdImage,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
    },
    width: {
      type: Number,
    },
    requestProps: {
      type: Object,
    },
    designId: {
      type: Number,
    },
  },
  data() {
    return {
      darkBg: false,
    };
  },
  methods: {
    toggleBg() {
      this.darkBg = !this.darkBg;
    },
  },
  async created() {
    try {
      if (this.isDesign && this.designId) {
        const design = await designService.getDesignById(this.designId);
        if (design.backgroundColor) {
          this.darkBg = true;
        }
      }
    } catch (error) {
      console.error('failed to determine design background color');
    }
  },
  computed: {
    isDesign() {
      return this.type === 'DESIGN';
    },
    imageUrl() {
      const params = Object.keys(this.requestProps)
        .map((key) => `${key}=${this.requestProps[key]}`)
        .join('&');

      return `${this.image}${this.image.includes('?') ? '&' : '?'}${params}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.image {
  height: 100%;
  display: flex;
  align-items: center;
  width: auto;
  flex-grow: 1;
  position: relative;
  padding: 12px;

  .bg-toggle-link {
    position: absolute;
    bottom: 3px;
    right: 3px;

    .icon {
      width: 20px;
      height: 20px;
    }
    &.light {
      color: $white;
    }
  }
}
</style>

<template>
  <div class="orderinfo">
    <div class="order-header">
      <h3>{{ $t('STATISTICS.SALE_DETAILS.ORDER') }}</h3>
      <SpreadshopLogo v-if="posLogo === 'shop'" class="pos-logo" />
      <MarketplaceLogo v-else class="pos-logo" />
    </div>
    <div class="order-subheader">
      <div>
        <span
          >{{ shippingCountryName
          }}{{ shippingZip ? ` ${shippingZip}` : '' }},&nbsp;</span
        ><span>{{ date(saleDate) }}</span>
      </div>
      <div>
        <span>{{ $t('STATISTICS.ORDER_NUMBER') }}:</span>
        <span>&nbsp;{{ orderId }}</span>
      </div>
    </div>
    <div class="promo-data" v-if="knownDiscounts">
      <div class="order-data-labels">
        <div>{{ $t('STATISTICS.SALE_DETAILS.DISCOUNTS') }}</div>
      </div>
      <ul class="order-data-infos">
        <li v-for="discount in knownDiscounts" :key="discount">
          {{ $t(discount) }}
        </li>
      </ul>
    </div>
    <div class="order-data">
      <div v-if="displayLabels" class="order-data-labels">
        <div>{{ $t('GENERAL.STATUS') }}</div>
        <div>{{ $t('STATISTICS.SALE_DETAILS.PRICE') }}</div>
        <div v-if="profit">
          {{ $t('STATISTICS.SALE_DETAILS.COMMISSION') }}
        </div>
      </div>
      <div class="order-data-infos">
        <div
          v-if="status"
          class="order-status"
          :style="{ color: SALE_STATES[status].color }"
        >
          <Icon :icon="SALE_STATES[status].icon"></Icon>
          {{
            $t(`STATISTICS.SALE_DETAILS.STATUS.${SALE_STATES[status].key}.NAME`)
          }}
        </div>
        <div class="price">
          <div v-if="!displayLabels" class="label">
            {{ $t('STATISTICS.SALE_DETAILS.PRICE') }}
          </div>
          <div class="amount">
            {{ sprdCurrency(customerPrice.amount, customerPrice.currencyId) }}
          </div>
        </div>
        <div v-if="profit" class="amount">
          {{ sprdCurrency(profit.amount, profit.currencyId) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { pointsOfSaleConfig } from '@/api/models/pointsOfSale/pointsOfSale';
import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';

export default {
  name: 'OrderInfo',
  props: {
    pos: {
      type: String,
      required: true,
    },
    saleDate: {
      type: String,
      required: true,
    },
    shippingCountry: {
      type: String,
      required: true,
    },
    shippingZip: {
      type: String,
      default: '',
    },
    orderId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    customerPrice: {
      type: Object,
      required: true,
    },
    profit: {
      type: Object,
    },
    displayLabels: {
      type: Boolean,
    },
    discounts: {
      type: Array,
    },
  },
  components: {
    MarketplaceLogo,
    SpreadshopLogo,
  },
  data() {
    return {
      SALE_STATES: statisticsService.getSaleStates(),
      DISCOUNT_TYPES: statisticsService.getDiscountTypes(),
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      shippingCountryByIso: 'platform/shippingCountryByIso',
    }),
    shippingCountryName() {
      return this.shippingCountryByIso(this.shippingCountry);
    },
    posLogo() {
      return pointsOfSaleConfig(this.pos).logo;
    },
    knownDiscounts() {
      if (!this.discounts?.length) {
        return null;
      }

      return this.discounts
        .filter((discount) => this.DISCOUNT_TYPES[discount])
        .map((discount) => this.DISCOUNT_TYPES[discount].name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/mixins/media';

.orderinfo {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey20;
  border-radius: $border-radius-medium;
  padding: 12px;
  min-height: 160px;

  p {
    margin: 0;
  }
}

.order-header {
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0;
  }

  .pos-logo {
    height: 18px;

    @include small-desktop {
      height: 16px;
    }
  }
}

.order-subheader {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: auto;

  & > div {
    @extend .text-sm;
    @extend .text-grey;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      margin-right: 72px;
      text-align: left;
    }

    &:last-child {
      justify-content: flex-end;
    }

    & > span {
      white-space: nowrap;
    }
  }
}

.order-data,
.promo-data {
  margin-top: 24px;

  & > div {
    display: flex;
    justify-content: space-between;
  }

  .order-data-labels {
    margin-bottom: 4px;
    @extend .text-sm;
    @extend .text-grey;
  }

  .price {
    flex-direction: column;

    .label {
      margin-bottom: 4px;
      @extend .text-sm;
      @extend .text-grey;
      margin-left: auto;
    }

    .amount:not(:only-child) {
      margin-left: auto;
    }
  }

  .amount {
    font-weight: bold;
    @extend .text-lg;
  }

  .order-data-labels,
  .order-data-infos {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      flex-basis: 100%;
      min-width: 100px;

      &:first-child {
        margin-left: 0;
        justify-content: flex-start;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .order-status {
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: bold;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
}

.promo-data {
  .order-data-labels > div {
    justify-content: flex-start !important;
  }

  .order-data-infos {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-weight: bold;
    }
  }
}
</style>

<template>
  <div class="account-nav-bottom" v-if="userData && data">
    <div>
      <img class="profile-image" :src="profileImage" alt="profile image" />
      <strong class="username text-truncate">{{
        username || userData.id
      }}</strong>
      <p>{{ $t('DASHBOARD.USER_ID') }}: {{ userData.id }}</p>
      <button
        v-tooltip.top-start="{
          content: isAdmin ? 'not allowed as admin' : null,
          hideOnTargetClick: false,
        }"
        :disabled="isAdmin"
        @click="openEditDialog"
        class="link-main icon-link"
      >
        {{ $t('GENERAL.EDIT') }}
      </button>
    </div>
    <router-link
      to="/logout"
      class="link-main icon-link logout"
      v-trackClickEvent="{ name: 'logout' }"
    >
      <Icon icon="logout" /> Logout
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import dialogService from '@/dialogs/wrapper/dialogService';
import UserNameAndImageDialog from './UserNameAndImageDialog.vue';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';

export default {
  name: 'AccountNavBottom',
  components: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.query?.editProfile) {
      this.openEditDialog();
    }
  },
  computed: {
    ...mapState({
      userData: (s) => s.user.data,
      username: (s) => s.identity.username,
      data: (state) => state.account.current,
    }),
    ...mapGetters({
      isAdmin: 'account/isAdmin',
      profileImage: 'user/profileImage',
    }),
  },
  methods: {
    async openEditDialog() {
      const noUnsavedChanges = await unsavedChangesService.onRouteUpdate(
        {},
        {}
      );
      if (noUnsavedChanges) {
        try {
          await dialogService.openDialog(UserNameAndImageDialog);
        } catch (error) {
          this.$store.commit('account/revertChanges');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.account-nav-bottom {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100% !important;

  & > div {
    width: 100%;
    padding: 16px;
    border: 1px solid $grey10;
    border-radius: $border-radius-medium;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 12px;
      text-align: center;
    }

    .username {
      max-width: 100%;
    }
  }
}

.profile-image {
  width: 80px;
  height: 80px;
  border: 1px solid $grey10;
  border-radius: 40px;
  margin: 0 0 8px 0;
}

.logout {
  margin: 16px 0 0 0;
}
</style>

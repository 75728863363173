<template>
  <AiContainer scaleOnHover>
    <button
      class="btn btn-light icon-btn ai-generator-button"
      @click="handleClick"
    >
      <Icon icon="ai-feature"></Icon>
      <slot></slot>
    </button>
  </AiContainer>
</template>

<script setup>
import AiContainer from './AiContainer.vue';

const emits = defineEmits(['ai-button-clicked']);
const handleClick = () => emits('ai-button-clicked');
</script>

<style scoped lang="scss">
@import 'src/scss/styleguide/colors';

.ai-generator-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 12px;
  border: 1px solid $pa-color-main;
  color: #2a6ba5;
}
</style>

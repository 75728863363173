<template>
  <div class="spreadapp-module" v-if="display">
    <img :src="image" alt="spreadapp" />
    <div>
      <h3>
        {{ $t(heading) }}
      </h3>
      <router-link class="link-main" to="/account/deviceconnection">
        {{ $t('DASHBOARD.SPREADAPP.CTA') }}
      </router-link>
    </div>
    <button class="btn-primary" @click="remove" v-if="removable">
      <Icon icon="close" />
    </button>
  </div>
</template>

<script>
import deviceService from '@/api/deviceService/deviceService';

export default {
  name: 'SpreadAppModule',
  props: {
    removable: {
      type: Boolean,
    },
    statistics: {
      type: Boolean,
    },
  },
  data() {
    return {
      display: false,
    };
  },
  async created() {
    if (!this.removable) {
      this.display = true;
      return;
    }

    const deviceConnected = await this.checkIfDeviceIsConnected();
    if (!deviceConnected && !localStorage.getItem('removeAppPromotion')) {
      this.display = true;
    }
  },
  computed: {
    heading() {
      return this.statistics
        ? 'DASHBOARD.SPREADAPP.HEADING_STATISTICS_ONLY'
        : 'DASHBOARD.SPREADAPP.HEADING';
    },
    image() {
      return this.statistics
        ? '/images/spreadapp-mockup-stats.jpg'
        : '/images/spreadapp-mockup.jpg';
    },
  },
  methods: {
    remove() {
      localStorage.setItem('removeAppPromotion', true);
      this.display = false;
    },
    async checkIfDeviceIsConnected() {
      const connectedDevices = await deviceService.fetchConnectedDevices();
      return connectedDevices.some(
        (device) => !device.deviceInfo.includes('Spreadshirt Test Device')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/utils';

.spreadapp-module {
  padding: 0 !important;
  border-radius: $border-radius-medium;
  background-color: $white;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  & > div {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  h3 {
    margin: 0 0 13px 0;
  }

  img {
    min-width: 120px;
    width: 35%;
    object-fit: cover;
    height: 100%;
    min-height: 150px;
    filter: brightness(90%);
  }

  button {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 16px;
    right: 16px;

    .icon {
      height: 18px;
      width: 18px;
    }
  }
}
</style>

<template>
  <div>
    <div v-if="errorMessage" class="error-box">{{ errorMessage }}</div>
    <input
      :id="fileInputId"
      ref="fileInput"
      class="hidden-file-input"
      type="file"
      @change="onFileDrop"
      :disabled="disabled"
    />
    <div v-if="activeImageUrl && !loading" class="current-image">
      <div
        class="active-img design-checked-background"
        @click="$emit('file-activated')"
      >
        <img :src="activeImageUrl" />
        <div v-if="active" class="mermaid-overlay"></div>
      </div>
      <div class="restrictions-text-outside">
        {{ $t('POS.SHOP_SETTINGS.IMG_UPLOAD.RESTRICTION') }}:
        {{ uploadHintText }}
      </div>
      <div class="ud-row">
        <label
          :for="fileInputId"
          class="ud-btn replace-btn"
          :disabled="disabled"
        >
          <Icon icon="replace" />
          {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.SAVE.REPLACE_CTA') }}
        </label>
        <button
          class="ud-btn delete-btn"
          @click="onFileDeletion"
          :disabled="disabled"
        >
          <Icon icon="bin" />
        </button>
      </div>
    </div>
    <label :for="loading ? '' : fileInputId" v-else class="image-upload">
      <div
        @drop.prevent="onFileDrop"
        @dragover.prevent
        class="drop-area"
        :class="{
          'drop-area--active': !loading && !disabled,
          'drop-area--disabled': disabled,
        }"
      >
        <div class="dl-icon">
          <Icon icon="upload"></Icon>
        </div>
        <div class="drop-area-content">
          <p
            class="heading4 cta-text"
            :class="{
              'cta-text--not-loading': !loading,
              'cta-text--loading': loading,
            }"
          >
            {{ $t('POS.SHOP_SETTINGS.IMG_UPLOAD.CTA') }}
          </p>
          <div v-if="!loading" class="restrictions-text">
            {{ $t('POS.SHOP_SETTINGS.IMG_UPLOAD.RESTRICTION') }}:
            {{ uploadHintText }}
          </div>
          <div v-if="loading" class="progress-bar">
            <div :style="`width: ${loadingProgress}%`"></div>
          </div>
        </div>
      </div>
      <small
        class="legaltxt"
        v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"
      ></small>
    </label>
  </div>
</template>

<script>
import { shopImageUpload } from 'src/app/commons/api/shop/shopImageUpload';

import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import ImageCropDialog from 'src/app/components/imageCropDialog/ImageCropDialog.vue';

function isFileCropable(originalFile, width, height) {
  return new Promise((resolve, reject) => {
    if (originalFile.type === 'image/svg+xml') {
      reject();
    }

    // if image is already smaller than our suggestion we don't want to crop it any further
    const img = document.createElement('img');
    img.onload = () => {
      if (img.naturalWidth <= width && img.naturalHeight <= height) {
        reject();
      }

      resolve();
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(originalFile);
  });
}

export default {
  name: 'ImageUpload',
  props: {
    image: Object,
    active: Boolean,
    activeImageName: String,
    activeImageUrl: String,
    disabled: Boolean,
    fileInputId: {
      type: String,
      default: 'hiddenFileInput',
    },
  },
  data: function () {
    return {
      errorMessage: null,
      loading: false,
      loadingProgress: 0, // from 0 to 100 (integers)
      uploadHintText: `${this.image.hint}px & 400KB`,
    };
  },
  methods: {
    onFileDrop(e) {
      if (this.loading) {
        return;
      }

      let dataObject;
      if (e.dataTransfer) {
        dataObject = e.dataTransfer;
      } else if (e.srcElement) {
        // IE fallback
        dataObject = e.srcElement;
      }

      if (!dataObject || !dataObject.files || dataObject.files.length < 1) {
        // do nothing if we do not receive a file
        return;
      }

      const originalFile = dataObject.files[0];

      isFileCropable(originalFile, this.image.width, this.image.height)
        .then(() => this.cropFile(originalFile))
        .catch(() => this.uploadFile(originalFile));
    },
    cropFile(originalFile) {
      const imageUpload = this;
      this.$refs.fileInput.value = ''; // allows to upload another file with the same name
      dialogService
        .openDialog(
          ImageCropDialog,
          {
            file: originalFile,
            width: this.image.width,
            height: this.image.height,
          },
          {
            staticBackdrop: true,
          }
        )
        .then((croppedImageBlob) => {
          if (!(croppedImageBlob instanceof Blob)) {
            this.handleError(croppedImageBlob);
            return;
          }

          // now we need to fake a File by glueing some props to it :)
          croppedImageBlob.lastModifiedDate = new Date();
          croppedImageBlob.name = originalFile.name;
          imageUpload.uploadFile(croppedImageBlob);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    uploadFile(file) {
      this.loadingProgress = 0;
      this.loading = true;
      this.$refs.fileInput.value = ''; // allows to upload another file with the same name
      shopImageUpload
        .uploadImage(this.$store, file, this.image.useCase, (progress) => {
          this.loadingProgress = progress;
        })
        .then(
          (data) => this.$emit('file-uploaded', data),
          (err) => this.handleError(err)
        )
        .then(() => (this.loading = false));
    },
    onFileDeletion() {
      if (this.loading) {
        return;
      }
      dialogService
        .openDialog(ConfirmDialog, {
          description: 'SA.layout.image.deleteConfirmation',
          cancel: 'SA.action.cancel',
          confirm: 'SA.action.delete',
          danger: true,
        })
        .then(() => {
          this.loading = true;
          this.$emit('file-deletion-started');
          shopImageUpload
            .deleteImage(this.$store, this.activeImageName, this.image.useCase)
            .then(
              () => this.$emit('file-deleted'),
              (err) => this.handleError(err)
            )
            .then(() => {
              this.$emit('file-deletion-finished'); // emitted in success and error case
              this.loading = false;
            });
        });
    },
    handleError(err) {
      console.error(err);
      if (typeof err === 'string') {
        this.errorMessage = err;
      } else {
        this.errorMessage = this.$t(
          'SA.imageUpload.message.genericUploadError'
        );
      }
      setTimeout(() => {
        this.errorMessage = null;
      }, 10000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'imageUploadReusable';
@import '../startPage/startPageCommon';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';
@import 'src/scss/styleguide/type';
@import 'src/scss/constants';

.image-upload {
  margin-bottom: 2em;
}
.active-img {
  margin-bottom: 0.5em;
  display: flex;
}
.hidden-file-input {
  // hacky way of achieving display:none without side effects, see https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.drop-area {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
  padding: 0.25em;
  border-radius: $border-radius-medium;
  background-color: $grey5;
  text-align: center;

  &--active {
    cursor: pointer;
  }

  &--disabled {
    color: $grey20;
  }
}
.dl-icon {
  display: flex;
  align-items: center;

  > svg {
    height: 28px;
    width: 32px;
  }
}
.cta-text {
  font-weight: 700;
  margin: 16px 0;
  text-align: left;
  padding: 0 16px 4px;
  &--not-loading {
    margin-bottom: 0;
  }
  &--loading {
    margin-bottom: 4px;
  }
}
.progress-bar {
  height: 4px;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 8px;
  > div {
    height: 4px;
    background-color: $pa-color-main;
  }
}
.legaltxt {
  color: $grey40;
  margin-bottom: 0.5em;

  :deep(a) {
    @extend .link-main;
  }
}
.restrictions-text-outside {
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 4px;
  color: $grey40;
}

.restrictions-text {
  font-size: 12px;
  padding: 0 16px;
  margin-bottom: 16px;
  color: $grey60;
}
</style>
